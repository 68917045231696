// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import { Button, DatePicker } from 'antd';
import moment from 'moment';

import './style.css';

import closeIcon from '../../../../../../../img/icons/close.svg';
import starIcon from '../../../../../../../img/icons/star.png';

const gtconfig = require('../../../../../../../gtconfig');


function Sample(props) {

  const renderEmailPreview = (type) => {
    return (
      <div className="emailPreviewWrap">
      {/*<p>Email Preview</p>*/}
        {/*<div className="devicesBox">
          <span>Desktop</span>
          <span>Mobile</span>
        </div>*/}
        <div className="emailPreviewBox">
          <div className="emailBodyBox">
            <div className="headerBox">
              {
                gtconfig.IS_GT
                ? <img src="https://s3.amazonaws.com/guesttouch/assets/h/2/GuestTouchbluetextPNG.png" />
                : <>
                    {
                      gtconfig.APP_ID === 'gt-unified-prosperhotels' &&
                      <img src={gtconfig.APP_LOGO} />
                    }
                  </>
              }
              <p>Your Awesome Hotel</p>
            </div>
            <div className="middleBox">
              {
                  (type === 'smiley' || type === 'stars') &&
                  <p className={'primaryMessage'}>How was your stay with us ?</p>
              }
              {
                  (type === 'nps' || type === 'generic') &&
                  <p className={'primaryMessage'}>How likely are you to recommend us to a friend or colleague?</p>
              }
              {
                type === 'smiley' &&
                <div className="moodBox">
                  <img src={gtconfig.FEEDBACK_HAPPY} />
                  <img src={gtconfig.FEEDBACK_SAD} />
                </div>
              }
              {
                type === 'stars' &&
                <div className="starBox">
                  <div className="stars">
                    <img className={'starIcon'} src={starIcon}></img>
                    <img className={'starIcon'} src={starIcon}></img>
                    <img className={'starIcon'} src={starIcon}></img>
                    <img className={'starIcon'} src={starIcon}></img>
                    <img className={'starIcon'} src={starIcon}></img>
                  </div>
                </div>
              }
              {
                (type === 'nps' || type === 'generic') &&
                <div className="npsBox">
                  <div className="bottom">
                    <span>Not at all likely</span>
                    <span>Extremely likely</span>
                  </div>
                  <div className="top">
                    {
                      Array.from(Array(10).keys()).map((item) => {
                        return (
                          <div className={'npsNumber'}>{item+1}</div>
                        );
                      })
                    }
                  </div>
                </div>
              }
            </div>
            <div className="footerBox">
              {
                  (type === 'smiley' || type === 'stars' || type === 'generic') &&
                  <p className={'hotelMessage'}>we are here to help</p>
              }
              <p className="address">#1, Rodeo Drive, San Fracisco, California, USA</p>
              <p className="contact">
                <span className="link">+1-123-456-7890</span>
                <span className="link">https://yourawesomehotel.com</span>
              </p>
              <p className="unsubscribe">
                <span className="link">Unsubscribe</span> from future emails like this
              </p>
              {
                gtconfig.IS_GT &&
                <p className="poweredBy">
                  Powered by <span className="link">{gtconfig.APP_NAME_CONTACT}</span>
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
        <div className="innerBox">
          <div className="topBox">
            <div className="titleBox">
              <h4>Sample Email</h4>
              <span className="close" onClick={props.onCancelClick}><img src={closeIcon} />Close</span>
            </div>
          </div>
          <div className="contentBox">
            { renderEmailPreview(props.type) }
          </div>
        </div>
    );
  }


  return (
      <div className="gBoxWrap sampleEmailBoxWrap">
          { renderContent() }
      </div>
  );
}

export default Sample;
