// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import { Interweave } from 'interweave';
import ShortUniqueId from 'short-unique-id';

import { editDataAction, editDataClearAction } from '../../../../../../../redux/actions/editDataActions';

import ToolTipWrapper from '../../../../../../Misc/ToolTipWrapper';

import { STRS, getUserGAId } from '../../../../../../../helpers';

import './style.css';

import starIcon from '../../../../../../../img/icons/star.png';
import { use } from 'react';

const gtconfig = require('../../../../../../../gtconfig');
const suid = new ShortUniqueId({ length: 16 });

function GenericHotelDataSingle(props) {
  return (
    <div className={'gSettingsRow ' + (props.dataTypeCode || '') + (props.noBorder ? ' noBorder' : '')} onMouseEnter={props.onMEnter} onMouseLeave={props.onMLeave}>
      {
        props.title &&
        <div className="titleRow">
          <p>{props.title}</p>
        </div>
      }
      <div className="contentRow">
        <div className="contentBox">
          <label>{props.desc}</label>
          <div className="gIpt">
            {
              props.type === "textarea"
              ?
                <textarea
                  className="gInput"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
              :
                <input
                  className="gInput"
                  type="text"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
            }
          </div>
          {
            props.error &&
            <span className="gErr">{props.error}</span>
          }
        </div>
        <div className="contentBoxNA">
        </div>
      </div>
    </div>
  );
}


function Question(props) {
  const [collectTextInput, setCollectTextInput] = useState(props.collectTextInput);
  const [collectStarRating, setCollectStarRating] = useState(props.collectStarRating);
  const [inputError, setInputError] = useState(false);

  const [questionType, setQuestionType] = useState(props.type);
  const [questionTypeOptions, setQuestionTypeOptions] = useState([]);

  useEffect(() => {
    setQuestionTypeOptions([
      { label: 'Yes/No', value: 'yes-no' },
      { label: 'Single Select', value: 'single-select' },
      { label: 'Out of 5', value: 'out-of-5' },
      { label: 'Multiple Out of 5', value: 'multiple-out-of-5' },
      { label: 'Text', value: 'text' },
    ]);
  }, []);

  useEffect(() => {
    if (props.onError) props.onError(props._id, inputError);
  }, [inputError]);

  const onEnabledChange = (e) => {
    props.onEnabledChange({ questionId: props.questionId, enabled: e.target.checked });
  };
  const onMEnter = (e) => {
    if (props.onMEnter) props.onMEnter(props._id, e);
  };
  const onMLeave = (e) => {
    if (props.onMLeave) props.onMLeave(props._id, e);
  };
  
  
  const onQuestionChange = (e) => {
    props.onQuestionChange({ questionId: props.questionId, question: e.target.value });
  };

  const onQuestionTypeChange = (value) => {
    setQuestionType(value.value);
    props.onQuestionTypeChange({ questionId: props.questionId, type: value.value });
  };

  const onQuestionOrderChange = (questionId, direction) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionOrderChange: ', questionId, direction);
    }
    props.onQuestionOrderChange(questionId, direction);
  };

  const addOption = (questionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('addOption: ', questionId);
    }
    props.onAddOption(questionId);
  };
  const editOption = (questionId, option) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('editOption: ', questionId, option);
    }
    props.onEditOption(questionId, option);
  };
  const removeOption = (questionId, option) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('removeOption: ', questionId, option);
    }
    props.onRemoveOption(questionId, option);
  };
  const onCollectTextChange = (questionId, option, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCollectTextChange: ', e.target.name, e.target.checked);
    }
    props.onCollectTextChange(questionId, option, e.target.checked);
  };
  const onOptionLabelChange = (questionId, option, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onOptionLabelChange: ', questionId, option, e);
    }
    props.onOptionLabelChange(questionId, option, e.target.value);
  };
  const saveOption = (questionId, option) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('saveOption: ', questionId, option);
    }
    props.onSaveOption(questionId, option);
  };
  const onOptionOrderChange = (questionId, option, direction) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onOptionOrderChange: ', questionId, option, direction);
    }
    props.onOptionOrderChange(questionId, option, direction);
  };


  const addSubQuestion = (questionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('addSubQuestion: ', questionId);
    }
    props.onAddSubQuestion(questionId);
  };

  const onSubQuestionChange = (questionId, subQuestionId, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSubQuestionChange: ', questionId, subQuestionId, e);
    }
    props.onSubQuestionChange(questionId, subQuestionId, e.target.value);
  };

  const saveSubQuestion = (questionId, subQuestionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('saveSubQuestion: ', questionId, subQuestionId);
    }
    props.onSaveSubQuestion(questionId, subQuestionId);
  };

  const editSubQuestion = (questionId, subQuestionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('editSubQuestion: ', questionId, subQuestionId);
    }
    props.onEditSubQuestion(questionId, subQuestionId);
  };

  const removeSubQuestion = (questionId, subQuestionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('removeSubQuestion: ', questionId, subQuestionId);
    }
    props.onRemoveSubQuestion(questionId, subQuestionId);
  };

  const onSubQuestionOrderChange = (questionId, subQuestionId, direction) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSubQuestionOrderChange: ', questionId, subQuestionId, direction);
    }
    props.onSubQuestionOrderChange(questionId, subQuestionId, direction);
  };

  const onRemoveQuestion = (questionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRemoveQuestion: ', questionId);
    }
    props.onRemoveQuestion(questionId);
  };

  


  const renderSingleSelectQuestion = (selectOptions, questionValue, onQuestionAnswerChange, dataTypeCode = '') => {
    if (process.env.NODE_ENV !== 'production') {
        console.log('renderSingleSelectQuestion selectOptions: ', selectOptions, ' # questionValue: ', questionValue);
    }
    const { questionId } = props;
    return (
      <div className={'qestn single-select'}>
        <div className="titleRow">
          <p>Choose question type</p>
        </div>
        <div className="gIpt">
          {selectOptions.map((el, index) => (
              <div key={`${questionId}-opt-${el.value}`} className="opt">
                <input
                    type="radio"
                    id={`${questionId}-opt-${el.value}`}
                    name={`${questionId}-opt-${el.value}`}
                    value={el.value}
                    checked={questionValue === el.value}
                    onChange={() => onQuestionAnswerChange(el)}
                />
                <label htmlFor={`${questionId}-opt-${el.value}`}>{el.label}</label>
              </div>
          ))}
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Question props: ', props);
  }
  const { questionId } = props;
  const { question, type, enabled, options, subquestions, error } = props.questionData;

  return (
    <div className={'qestn ' + (props.type || '') + (props.noBorder ? ' noBorder' : '')} onMouseEnter={onMEnter} onMouseLeave={onMLeave}>
      <div className="titleRow">
        <p>{props.label}</p>
        <div className="right">
          <div className="odrBx">
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={"Move Up"}>
              <span onClick={() => onQuestionOrderChange(questionId, 'up')}><i className="fa fa-arrow-up"></i></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={"Move Down"}>
              <span onClick={() => onQuestionOrderChange(questionId, 'down')}><i className="fa fa-arrow-down"></i></span>
            </ToolTipWrapper>
          </div>
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={`qe_${questionId}`} checked={enabled} onChange={onEnabledChange}/><label htmlFor={`qe_${questionId}`}>Toggle</label>
            </div>
          </div>
          <span onClick={() => onRemoveQuestion(questionId)}><i className="fa fa-trash"></i></span>
        </div>
      </div>
      <div className="qTypeRow">
        { renderSingleSelectQuestion(questionTypeOptions, questionType, onQuestionTypeChange) }
      </div>
      <div className="contentRow">
        <div className="contentBox">
          <label>Question</label>
          <div className="gIpt">
          <textarea
            className="gInput"
            value={question}
            onChange={onQuestionChange}
            readOnly={props.readOnly}
          />
          </div>
        </div>
        {
          type === 'single-select' &&
          <div className="contentRow">
            <div className="ssOptionsBox">
              <p>
                Options
                <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
                  overlay={"Add Option"}
                >
                  <span className="addOption" onClick={() => addOption(questionId)}><i className="fa fa-plus"></i></span>
                </ToolTipWrapper>
              </p>
              {
                options &&Object.keys(options).sort((a, b) => options[a].order - options[b].order).map((option, index) => {
                  return (
                    <div key={`${questionId}-opt-${option}`} className="opt">
                      <div className="odrBx">
                        <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={"Move Up"}>
                          <span onClick={() => onOptionOrderChange(questionId, option, 'up')}><i className="fa fa-arrow-up"></i></span>
                        </ToolTipWrapper>
                        <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={"Move Down"}>
                          <span onClick={() => onOptionOrderChange(questionId, option, 'down')}><i className="fa fa-arrow-down"></i></span>
                        </ToolTipWrapper>
                      </div>
                      {
                        (options[option].add || options[option].edit)
                        ?
                        <div className="optLabel">
                          <input type="text" className="gInput" value={options[option].label} onChange={(e) => onOptionLabelChange(questionId, option, e)} placeholder="Option Label" />
                          <span onClick={() => saveOption(questionId, option)}><i className="fa fa-check"></i></span>
                        </div>
                        : 
                        <div className="optLabel">
                          <label htmlFor={`${questionId}-opt-${option}`}>{options[option].label}</label>
                          <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
                            overlay={options[option].collect_text ? "Specific answer will be collected, uncheck to avoid collecting" : "Specific answer will not be collected, check to collect"}
                          >
                            <input type="checkbox" name={`${questionId}-opt-text-${option}`} onChange={(e) => onCollectTextChange(questionId, option, e)} 
                              value={options[option].collect_text ? 'on' : 'off'} checked={options[option].collect_text} 
                            />
                          </ToolTipWrapper>
                          <span onClick={() => editOption(questionId, option)}><i className="fa fa-edit"></i></span>
                          <span onClick={() => removeOption(questionId, option)}><i className="fa fa-trash"></i></span>
                        </div>
                      }
                    </div>
                  )
                })
              }
              {
                error &&
                <span className="gErr">{error}</span>
              }
            </div>
          </div>
        }
        {
          type === 'multiple-out-of-5' &&
          <div className="contentRow">
            <div className="ssOptionsBox">
              <p>
                Sub Questions
                <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
                  overlay={"Add Sub Question"}
                >
                  <span className="addOption" onClick={() => addSubQuestion(questionId)}><i className="fa fa-plus"></i></span>
                </ToolTipWrapper>
              </p>
              {
                subquestions && Object.keys(subquestions).sort((a, b) => subquestions[a].order - subquestions[b].order).map((subqstn, index) => {
                  return (
                    <div key={`${questionId}-opt-${subqstn}`} className="opt">
                      <div className="odrBx">
                        <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={"Move Up"}>
                          <span onClick={() => onSubQuestionOrderChange(questionId, subqstn, 'up')}><i className="fa fa-arrow-up"></i></span>
                        </ToolTipWrapper>
                        <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={"Move Down"}>
                          <span onClick={() => onSubQuestionOrderChange(questionId, subqstn, 'down')}><i className="fa fa-arrow-down"></i></span>
                        </ToolTipWrapper>
                      </div>
                      {
                        (subquestions[subqstn].add || subquestions[subqstn].edit)
                        ?
                        <div className="optLabel">
                          <input className="gIpt" type="text" value={subquestions[subqstn].question} onChange={(e) => onSubQuestionChange(questionId, subqstn, e)} placeholder="Question" />
                          <span onClick={() => saveSubQuestion(questionId, subqstn)}><i className="fa fa-check"></i></span>
                        </div>
                        : 
                        <div className="optLabel">
                          <label htmlFor={`${questionId}-opt-${subqstn}`}>{subquestions[subqstn].question}</label>
                          <span onClick={() => editSubQuestion(questionId, subqstn)}><i className="fa fa-edit"></i></span>
                          <span onClick={() => removeSubQuestion(questionId, subqstn)}><i className="fa fa-trash"></i></span>
                        </div>
                      }
                    </div>
                  )
                })
              }
              {
                error &&
                <span className="gErr">{error}</span>
              }
            </div>
          </div>
        }
        {
          props.error &&
          <span className="inputErrorBox">{props.error}</span>
        }
        {/*
          inputError &&
          <div className="inputErrorBox">
            {inputError}
          </div>
        */}
      </div>
    </div>
  );
}

function FeedbackSection(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  const location = useLocation();

  const [sectionData, setSectionData] = useState({});

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [highlightTitle, setHighlightTitle] = useState(false);

  const [updatedValues, setUpdatedValues] = useState({});

  const [formChanged, setFormChanged] = useState(false);

  
  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    props.onSectionDataChange(props.sectionId, sectionData);
  }, [sectionData]);

  const setInitialValues = () => {
    setSectionData(props.sectionData);

    const { title, questions } = props.sectionData;

    setTitle(title || '');
    
    setFormChanged(false);
  };

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      title: value,
    }));
    setSectionData(sectionData => ({
      ...sectionData,
      title: value
    }));
    setFormChanged(true);
  };
  const onTitleMouseEnter = (e) => {
    setHighlightTitle(true);
  };
  const onTitleMouseLeave = (e) => {
    setHighlightTitle(false);
  };

  const onEnabledChange = (params) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEnabledChange: ', params);
    }
    const { questionId, enabled } = params;
    let qData = { ...sectionData.questions[questionId] };
    qData.enabled = enabled;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: { ...sectionData.questions, [questionId]: qData }
    }));
  }

  const onQuestionChange = (params) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionChange: ', params);
    }
    const { questionId, type, question } = params;
    let qData = { ...sectionData.questions[questionId] };
    if (type) qData.type = type;
    if ('question' in params) qData.question = question || '';
    setSectionData(sectionData => ({
      ...sectionData,
      questions: {
        ...sectionData.questions,
        [questionId]: qData
      }
    }));
  }

  const onQuestionTypeChange = (params) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionTypeChange: ', params);
    }
    const { questionId, type } = params;
    let qData = { ...sectionData.questions[questionId] };
    if (type) qData.type = type;
    if (type === 'multiple-out-of-5') {
      if (!qData.subquestions || Object.keys(qData.subquestions).length === 0) {
        qData.error = 'Atleast one sub-question is required';
      }
    }
    if (type === 'single-select') {
      if (!qData.options || Object.keys(qData.options).length < 2) {
        qData.error = 'Atleast two options are required';
      }
    }
    setSectionData(sectionData => ({
      ...sectionData,
      questions: { ...sectionData.questions, [questionId]: qData }
    }));
  }

  const onQuestionOrderChange = (questionId, direction) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionOrderChange:: ', questionId, direction);
    }
    let qData = { ...sectionData.questions[questionId] };
    let questions = Object.keys(sectionData.questions).sort((a, b) => sectionData.questions[a].order - sectionData.questions[b].order);
    let questionIndex = questions.indexOf(questionId);
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionOrderChange questionIndex: ', questionIndex);
    }
    if (direction === 'up') {
      if (questionIndex > 0) {
        questions[questionIndex] = questions[questionIndex - 1];
        questions[questionIndex - 1] = questionId;
      }
    } else if (direction === 'down') {
      if (questionIndex < questions.length - 1) {
        questions[questionIndex] = questions[questionIndex + 1];
        questions[questionIndex + 1] = questionId;
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionOrderChange questions: ', questions);
    }
    let allQuestions = { ...sectionData.questions };
    questions.forEach((question, index) => {
      allQuestions[question].order = index;
    });
    setSectionData(sectionData => ({
      ...sectionData,
      questions: allQuestions
    }));
  }

  const onQuestionAddOption = (questionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionAddOption: ', questionId);
    }
    let oData = { ...sectionData.questions[questionId].options };
    oData[suid.rnd()] = {
      label: 'New Option',
      add:  true,
    };
    let qData = { ...sectionData.questions[questionId] };
    qData.options = oData;
    qData.error = Object.keys(oData).length < 2 ? 'Atleast two options are required' : '';
    setSectionData(sectionData => ({
      ...sectionData,
      questions: {
        ...sectionData.questions,
        [questionId]: qData
      }
    }));
  }

  const onQuestionEditOption = (questionId, option) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionEditOption: ', questionId, option);
    }
    let oData = { ...sectionData.questions[questionId].options };
    oData[option].edit = true;
    let qData = { ...sectionData.questions[questionId] };
    qData.options = oData;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: {
        ...sectionData.questions,
        [questionId]: qData
      }
    }));
  }

  const onQuestionRemoveOption = (questionId, option) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionRemoveOption: ', questionId, option);
    }
    let oData = { ...sectionData.questions[questionId].options };
    delete oData[option];
    let qData = { ...sectionData.questions[questionId] };
    qData.options = oData;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: {
        ...sectionData.questions,
        [questionId]: qData
      }
    }));
  }

  const onQuestionOptionLabelChange = (questionId, option, label) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionOptionLabelChange: ', questionId, option, label);
    }
    let oData = { ...sectionData.questions[questionId].options };
    oData[option].label = label;
    let qData = { ...sectionData.questions[questionId] };
    qData.options = oData;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: {
        ...sectionData.questions,
        [questionId]: qData
      }
    }));
  }

  const onCollectTextChange = (questionId, option, checked) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCollectTextChange: ', questionId, option, checked);
    }
    let oData = { ...sectionData.questions[questionId].options };
    oData[option].collect_text = checked;
    let qData = { ...sectionData.questions[questionId] };
    qData.options = oData;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: { ...sectionData.questions, [questionId]: qData }
    }));
  }

  const onQuestionSaveOption = (questionId, option) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionSaveOption: ', questionId, option);
    }
    let oData = { ...sectionData.questions[questionId].options };
    oData[option].edit = false;
    oData[option].add = false;
    let qData = { ...sectionData.questions[questionId] };
    qData.options = oData;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: {
        ...sectionData.questions,
        [questionId]: qData
      }
    }));
  };

  const onQuestionOptionOrderChange = (questionId, option, direction) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionOptionOrderChange: ', questionId, option, direction);
    }
    let oData = { ...sectionData.questions[questionId].options };
    let options = Object.keys(oData).sort((a, b) => oData[a].order - oData[b].order);
    let optionIndex = options.indexOf(option);
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionOptionOrderChange optionIndex: ', optionIndex);
    }
    if (direction === 'up') {
      if (optionIndex > 0) {
        options[optionIndex] = options[optionIndex - 1];
        options[optionIndex - 1] = option;
      }
    } else if (direction === 'down') {
      if (optionIndex < options.length - 1) {
        options[optionIndex] = options[optionIndex + 1];
        options[optionIndex + 1] = option;
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionOptionOrderChange options: ', options);
    }
    options.forEach((option, index) => {
      oData[option].order = index;
    });
    let qData = { ...sectionData.questions[questionId] };
    qData.options = oData;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: {
        ...sectionData.questions,
        [questionId]: qData
      }
    }));
  };


  const onQuestionAddSubQuestion = (questionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onQuestionAddSubQuestion: ', questionId);
    }
    let sData = { ...sectionData.questions[questionId].subquestions };
    sData[suid.rnd()] = {
      question: 'New Sub Question',
      add: true,
    };
    let qData = { ...sectionData.questions[questionId] };
    qData.subquestions = sData;
    qData.error = Object.keys(sData).length === 0 ? 'Atleast one sub-question is required' : '';
    setSectionData(sectionData => ({
      ...sectionData,
      questions: {
        ...sectionData.questions,
        [questionId]: qData
      }
    }));
  };

  const onSubQuestionChange = (questionId, subQuestionId, value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSubQuestionChange: ', questionId, subQuestionId, value);
    }
    let sData = { ...sectionData.questions[questionId].subquestions };
    sData[subQuestionId].question = value;
    let qData = { ...sectionData.questions[questionId] };
    qData.subquestions = sData;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: {
        ...sectionData.questions,
        [questionId]: qData
      }
    }));
  };

  const onSaveSubQuestion = (questionId, subQuestionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSaveSubQuestion: ', questionId, subQuestionId);
    }
    let sData = { ...sectionData.questions[questionId].subquestions };
    sData[subQuestionId].add = false;
    sData[subQuestionId].edit = false;
    let qData = { ...sectionData.questions[questionId] };
    qData.subquestions = sData;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: { ...sectionData.questions, [questionId]: qData }
    }));
  };

  const onEditSubQuestion = (questionId, subQuestionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEditSubQuestion: ', questionId, subQuestionId);
    }
    let sData = { ...sectionData.questions[questionId].subquestions };
    sData[subQuestionId].edit = true;
    let qData = { ...sectionData.questions[questionId] };
    qData.subquestions = sData;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: { ...sectionData.questions, [questionId]: qData }
    }));
  }

  const onRemoveSubQuestion = (questionId, subQuestionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRemoveSubQuestion: ', questionId, subQuestionId);
    }
    let sData = { ...sectionData.questions[questionId].subquestions };
    delete sData[subQuestionId];
    let qData = { ...sectionData.questions[questionId] };
    qData.subquestions = sData;
    if (Object.keys(sData).length === 0) {
      qData.error = 'Atleast one sub-question is required';
    }
    setSectionData(sectionData => ({
      ...sectionData,
      questions: { ...sectionData.questions, [questionId]: qData }
    }));
  }

  const onSubQuestionOrderChange = (questionId, subQuestionId, direction) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSubQuestionOrderChange: ', questionId, subQuestionId, direction);
    }
    let sData = { ...sectionData.questions[questionId].subquestions };
    let subQuestions = Object.keys(sData).sort((a, b) => sData[a].order - sData[b].order);
    let subQuestionIndex = subQuestions.indexOf(subQuestionId);
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSubQuestionOrderChange subQuestionIndex: ', subQuestionIndex);
    }
    if (direction === 'up') {
      if (subQuestionIndex > 0) {
        subQuestions[subQuestionIndex] = subQuestions[subQuestionIndex - 1];
        subQuestions[subQuestionIndex - 1] = subQuestionId;
      }
    } else if (direction === 'down') {
      if (subQuestionIndex < subQuestions.length - 1) {
        subQuestions[subQuestionIndex] = subQuestions[subQuestionIndex + 1];
        subQuestions[subQuestionIndex + 1] = subQuestionId;
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSubQuestionOrderChange subQuestions: ', subQuestions);
    }
    subQuestions.forEach((subQuestion, index) => {
      sData[subQuestion].order = index;
    });
    let qData = { ...sectionData.questions[questionId] };
    qData.subquestions = sData;
    setSectionData(sectionData => ({
      ...sectionData,
      questions: { ...sectionData.questions, [questionId]: qData }
    }));
  };

  const onSectionOrderChange = (sectionId, direction) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSectionOrderChange: ', sectionId, direction);
    }
    props.onSectionOrderChange(sectionId, direction);
  }

  const onAddQuestion = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAddQuestion: ');
    }
    setSectionData(sectionData => ({
      ...sectionData,
      questions: {
        ...sectionData.questions,
        [suid.rnd()]: { question: 'New Question', type: 'yes-no', add: true }
      }
    }));
  }

  const onRemoveSection = (sectionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRemoveSection: ', sectionId);
    }
    props.onRemoveSection(sectionId);
  }

  const onRemoveQuestion = (questionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRemoveQuestion: ', questionId);
    }
    let qData = { ...sectionData.questions };
    delete qData[questionId];
    setSectionData(sectionData => ({
      ...sectionData,
      questions: qData,
    }));
  }

  const onSectionEnabledChange = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSectionEnabledChange: ', e.target.checked);
    }
    setSectionData(sectionData => ({
      ...sectionData,
      enabled: e.target.checked,
    }));
    props.onSectionEnabledChange(props.sectionId, e.target.checked);
  }

  const onSectionMouseEnter = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSectionMouseEnter: ', props.sectionId);
    }
    props.onSectionMouseEnter(props.sectionId);
  }

  const onSectionMouseLeave = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSectionMouseLeave: ', props.sectionId);
    }
    props.onSectionMouseLeave(props.sectionId);
  }

  // const renderYesNoQuestion = (questionId, questionData, sectionId) => {
  //   if (process.env.NODE_ENV !== 'production') {
  //       console.log('renderYesNoQuestion questionData: ', questionData);
  //   }
  //   const { question, type } = questionData
  //   if (!type || !question) return null;
    
    

  //   return (
  //       <div className={'qestn ' + (type)}>
  //           <h5>{question}</h5>
  //           <div className="rtgCntr">
  //               <div className="rtgOptns">
  //                   <div key={`yes-${questionId}`} className="opt">
  //                       <input
  //                           type="radio"
  //                           id={`yes-${questionId}`}
  //                           name={`yes-${questionId}`}
  //                           value="yes"
  //                           checked={questionAnswers[sectionId]?.[questionId]?.value === "yes"}
  //                           onChange={() => onQuestionAnswerChange(sectionId, questionId, "yes", "Yes", question)}
  //                       />
  //                       <label htmlFor={`yes-${questionId}`}>Yes</label>
  //                   </div>
  //                   <div key={`no-${questionId}`} className="opt">
  //                       <input
  //                           type="radio"
  //                           id={`no-${questionId}`}
  //                           name={`no-${questionId}`}
  //                           value="no"
  //                           checked={questionAnswers[sectionId]?.[questionId]?.value === "no"}
  //                           onChange={() => onQuestionAnswerChange(sectionId, questionId, "no", "No", question)}
  //                       />
  //                       <label htmlFor={`no-${questionId}`}>No</label>
  //                   </div>
  //               </div>
  //           </div>
  //       </div>
  //   );
  // };

  const renderTitle = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="title"
        title="Section Title"
        desc=""
        type="textarea"
        value={title}
        onChange={onTitleChange}
        error={titleError}
        onMEnter={onTitleMouseEnter}
        onMLeave={onTitleMouseLeave}
      />
    );
  };

  const renderQuestions = () => {
    if (!sectionData) return null;
    return (
      <div className="qestns">
        {
          sectionData.questions && Object.keys(sectionData.questions).sort((a, b) => sectionData.questions[a].order - sectionData.questions[b].order).map((item, index) => {
            return (
              <Question
                label={`Question #${index+1}`}
                type={sectionData.questions[item].type}
                key={`q_${item}`}
                questionId={item}
                questionData={sectionData.questions[item]}
                onQuestionChange={onQuestionChange}
                onQuestionTypeChange={onQuestionTypeChange}
                onQuestionOrderChange={onQuestionOrderChange}
                onAddOption={onQuestionAddOption}
                onRemoveOption={onQuestionRemoveOption}
                onEditOption={onQuestionEditOption}
                onOptionLabelChange={onQuestionOptionLabelChange}
                onCollectTextChange={onCollectTextChange}
                onSaveOption={onQuestionSaveOption}
                onOptionOrderChange={onQuestionOptionOrderChange}

                onAddSubQuestion={onQuestionAddSubQuestion}
                onSubQuestionChange={onSubQuestionChange}
                onSaveSubQuestion={onSaveSubQuestion}
                onEditSubQuestion={onEditSubQuestion}
                onRemoveSubQuestion={onRemoveSubQuestion}
                onSubQuestionOrderChange={onSubQuestionOrderChange}

                onRemoveQuestion={onRemoveQuestion}
                onEnabledChange={onEnabledChange}

                // onError={onAdditionalQuestionError}
                // error={additionalQuestionsError[item._id] ? additionalQuestionsError[item._id].error : ''}
                // onMEnter={onAdditionalQuestionMouseEnter}
                // onMLeave={onAdditionalQuestionMouseLeave}
              />
            );
          })
        }
        <div className="addQstnBtn">
          <span onClick={() => onAddQuestion()}>Add question<i className="fa fa-plus"></i></span>
        </div>
      </div>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('FeedbackSection props: ', props);
    console.log('FeedbackSection sectionData: ', sectionData);
    console.log('FeedbackSection updatedValues: ', updatedValues);
  }
  
  return (
    <div className="sctn" onMouseEnter={onSectionMouseEnter} onMouseLeave={onSectionMouseLeave}>
      <div className="sctnhdr">
        <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={"Move Up"}>
          <span onClick={() => onSectionOrderChange(props.sectionId, 'up')}><i className="fa fa-arrow-up"></i></span>
        </ToolTipWrapper>
        <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'} overlay={"Move Down"}>
          <span onClick={() => onSectionOrderChange(props.sectionId, 'down')}><i className="fa fa-arrow-down"></i></span>
        </ToolTipWrapper>
        <div className="gToggleWrap">
          <div className="gToggle">
            <input type="checkbox" id={`sc_${props.sectionId}`} checked={sectionData.enabled} onChange={onSectionEnabledChange}/>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
              overlay={sectionData.enabled ? "Disable Section" : "Enable Section"}
            >
              <label htmlFor={`sc_${props.sectionId}`}>Toggle</label>
            </ToolTipWrapper>
          </div>
        </div>
        <span onClick={() => onRemoveSection(props.sectionId)}><i className="fa fa-trash"></i></span>
      </div>
      { renderTitle() }
      { renderQuestions() }
    </div>
  );

}

export default FeedbackSection;
