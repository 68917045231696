// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import { Button, DatePicker } from 'antd';
import moment from 'moment';

import './style.css';

import closeIcon from '../../../../../../img/icons/close.svg';

const gtconfig = require('../../../../../../gtconfig');


function NpsInfo(props) {

  const renderContent = () => {
    return (
        <div className="innerBox npsInfoBox">
          <div className="topBox">
            <div className="titleBox">
            

              <h4>NPS Explainer</h4>
              <span className="close" onClick={props.onCancelClick}><img src={closeIcon} />Close</span>
            </div>
          </div>
          <div className="contentBox">
          <p><b>-100 to 0</b>Indicates more detractors than promoters, suggesting a negative customer experience.</p>
            <p><b>0-30</b>A good range, but with room for improvement.</p>
            <p><b>30-50</b>A solid range, indicating a company is generally delivering a good customer experience.</p>
            <p><b>50-70</b>A company in this range is doubling down on customer experience and has a larger than average group of promoters.</p>
            <p><b>70+</b>Considered world-class, indicating a very high level of customer loyalty and satisfaction.</p>
          </div>
        </div>
    );
  }


  return (
      <div className="gBoxWrap sampleEmailBoxWrap">
          { renderContent() }
      </div>
  );
}

export default NpsInfo;
