import axios from 'axios';

import { auth } from '../../firebase';
import { sendEmailAlert } from '../../helpers';

const gtconfig = require('../../gtconfig');

const opMap = {
  'get_user_info': 'GET_USER_INFO',
  'get_unread_count': 'GET_UNREAD_COUNT',
  'get_unread_count_for_user': 'GET_UNREAD_COUNT_FOR_USER',
  'get_guest_list': 'GET_GUEST_LIST',
  'get_guest': 'GET_GUEST',
  'get_location_settings': 'GET_SETTINGS',
  'get_guest_search_result': 'GET_GUEST_SEARCH_RESULT',
  'get_new_message_search_result': 'GET_NEW_MESSAGE_SEARCH_RESULT',
  'get_activity_log': 'GET_ACTIVITY_LOG',
  'get_sender_email_verification_status': 'GET_SENDER_EMAIL_VERIFICATION_STATUS',
  'get_webchat_settings': 'GET_WEBCHAT_SETTINGS',
  'get_webchat_helpguide': 'GET_WEBCHAT_HELPGUIDE',
  'get_webchat_rules': 'GET_WEBCHAT_RULES',
  'get_digital_guide_settings': 'GET_DIGITAL_GUIDE_SETTINGS',
  'get_gbm_settings': 'GET_GBM_SETTINGS',
  'get_ai_model_data': 'GET_AI_MODEL_DATA',
  'get_ai_model_result': 'GET_AI_MODEL_RESULT',
  'get_users_for_location': 'GET_USERS_FOR_LOCATION',
  'get_engage_usage': 'GET_ENGAGE_USAGE',

  'get_broadcasts': 'GET_BROADCASTS',
  'get_broadcasts_history': 'GET_BROADCASTS_HISTORY',
  'get_broadcast_run_detail': 'GET_BROADCAST_RUN_DETAIL',

  'get_email_marketing_campaigns': 'GET_EMAIL_MARKETING_CAMPAIGNS',
  'get_email_marketing_campaign_data': 'GET_EMAIL_MARKETING_CAMPAIGN_DATA',
  'get_email_marketing_campaign': 'GET_EMAIL_MARKETING_CAMPAIGN',
  'get_email_marketing_segments': 'GET_EMAIL_MARKETING_SEGMENTS',
  

  'get_transaction_email_stats': 'GET_TRANSACTION_EMAIL_STATS',
  'get_transaction_emails': 'GET_TRANSACTION_EMAILS',
  'get_transaction_emails_history': 'GET_TRANSACTION_EMAILS_HISTORY',
  'get_transaction_emails_reservations': 'GET_TRANSACTION_EMAILS_RESERVATIONS',

  'get_response_generator_response': 'GET_RESPONSE_GENERATOR_RESPONSE',
  'get_response_generator_locations': 'GET_RESPONSE_GENERATOR_LOCATIONS',
  'get_response_generator_overview': 'GET_RESPONSE_GENERATOR_OVERVIEW',
  'get_response_generator_history': 'GET_RESPONSE_GENERATOR_HISTORY',
  'get_users_for_rg': 'GET_USERS_FOR_RG',

  'get_groups': 'GET_GROUPS',
  'get_groups_summary_data': 'GET_GROUPS_SUMMARY_DATA',
  'get_group_dashboard_data': 'GET_GROUP_DASHBOARD_DATA',
  'get_group_reviews_data': 'GET_GROUP_REVIEWS_DATA',
  'get_group_sentiments_data': 'GET_GROUP_SENTIMENTS_DATA',
  'get_dashboard_data': 'GET_DASHBOARD_DATA',
  'get_reviews_data': 'GET_REVIEWS_DATA',
  'get_reviews_dump': 'GET_REVIEWS_DUMP',
  'get_comp_data': 'GET_COMP_DATA',
  'get_sentiments_data': 'GET_SENTIMENTS_DATA',
  'get_trend_data': 'GET_TREND_DATA',
  'get_long_term_data': 'GET_LONG_TERM_DATA',
  'get_feedback_overview_data': 'GET_FEEDBACK_OVERVIEW_DATA',
  'get_feedback_overview_dump': 'GET_FEEDBACK_OVERVIEW_DUMP',
  'get_feedback_sent_data': 'GET_FEEDBACK_SENT_DATA',
  'get_feedback_sent_dump': 'GET_FEEDBACK_SENT_DUMP',
  'get_feedback_upload_history_data': 'GET_FEEDBACK_UPLOAD_HISTORY_DATA',
  'get_guestlist_overview_data': 'GET_GUESTLIST_OVERVIEW_DATA',
  'get_guestlist_dump': 'GET_GUESTLIST_DUMP',
  'get_instant_invite_dashboard_data': 'GET_INSTANT_INVITE_DASHBOARD_DATA',

  'get_reviews_widget_settings': 'GET_REVIEWS_WIDGET_SETTINGS',
  'get_reviews_widget_rules': 'GET_REVIEWS_WIDGET_RULES',

  'get_intg_configure_data': 'GET_INTG_CONFIGURE_DATA',
};

var lastOpTimeStamp = new Date().getTime();

/* ********************** Start - Get Data *********************************** */

export function getDataClearAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: `${opMap[payload.op]}_CLEAR`,
      payload,
    });
  };
}
export function getDataResetAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: `${opMap[payload.op]}_RESET`,
      payload,
    });
  };
}

export function getDataAction(payload) {
  return function somename(dispatch, getState) {

    if (process.env.NODE_ENV !== 'production') {
      console.log(`getDataAction ${payload.op} payload: ${JSON.stringify(payload)}`);
      console.log(`getDataAction lastOpTimeStamp: ${lastOpTimeStamp}`);
    }

    dispatch({
      type: opMap[payload.op],
      payload,
    });

    const { op, params, clear } = payload;

    if (clear) {
      dispatch({
        type: `${opMap[payload.op]}_CLEAR`,
        payload,
      });
    }

    let tDiff = new Date().getTime() - lastOpTimeStamp
    if (process.env.NODE_ENV !== 'production') {
      console.log(`getDataAction tDiff: ${tDiff}`);
    }
    let getToken = false;
    if (tDiff > 30 * 1000) getToken = true;
    if (process.env.NODE_ENV !== 'production') {
      console.log(`getDataAction tDiff getToken: ${getToken}`);
    }

    auth.currentUser
      .getIdToken(getToken)
      .then((idToken) => {
        lastOpTimeStamp = new Date().getTime();
        const url = gtconfig.DATA_BACKEND_URL;
        if (process.env.NODE_ENV !== 'production') {
          console.log(`getDataAction ${payload.op} url: `, url);
        }
        const opdata = {};
        opdata.params = {
          ...params,
          relogin_identifier: window.gtCId,
        };
        const postData = {
          appId: gtconfig.APP_ID,
          idToken,
          op, // 'get_guest_list',
          opdata,
        };
        // try {
        //   const t1 = localStorage.getItem('nktest');
        //   if (process.env.NODE_ENV !== 'production') {
        //     console.log(`getDataAction t1: `, t1);
        //   }
        //   if (t1) {
        //     postData.c1 = t1;
        //   }
        // } catch (e) {
        //   if (process.env.NODE_ENV !== 'production') {
        //     console.log(`getDataAction localStorage e: `, e);
        //   }
        // }
        if (process.env.NODE_ENV !== 'production') {
          console.log(`getDataAction ${payload.op} postData: `, postData);
        }

        const headers = {
          'Content-type': 'application/json',
          'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
        };
        // const axiosData = {
        //   gtp: CryptoJS.AES.encrypt(JSON.stringify(postData), gtconfig.DATA_BACKEND_URL_GT_BASIC_KEY).toString(),
        // };
        const axiosData = {
          gtps: postData,
          M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
        };

        let timeout = 30000;
        if (op === 'get_groups' ||
          op === 'get_groups_summary_data' ||
          op === 'get_group_dashboard_data' ||
          op === 'get_group_sentiments_data'
        ) {
          timeout = 60000;
        }

        axios
          .post(url, axiosData, { headers, timeout })
          .then((response) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getDataAction ${payload.op} response: `, response.data);
            }
            const { status } = response.data;
            const { data } = response.data;
            // if (process.env.NODE_ENV !== 'production') {
            //   console.log(`getDataAction origin: `, window.location.origin);
            // }
            // if (!data || !data.app_info || data.app_info.origin !== window.location.origin) {
            //   console.log('No way!');
            // }
            // if (status === 'success' && data) {
            //   dispatch({
            //     type: 'SET_VERSION',
            //     payload: { data, params },
            //   });
            // }
            if (status === 'success' && data) {
              if (data.app_info && data.app_info.origin === window.location.origin) {
                dispatch({
                  type: 'SET_APP_INFO',
                  payload: { data, params },
                });
                const id = (new Date()).getTime();
                let msg = payload.alert_msg || 'Updated successfully';
                if (payload.show_alert) {
                  dispatch({
                    type: 'RECEIVED_NEW_SNACK',
                    payload: {
                      snack: {
                        id,
                        msg,
                      },
                    },
                  });
                  setTimeout(() => {
                    dispatch({ type: 'REMOVE_SNACK', payload: { id } });
                  }, 2000);
                }
                dispatch({
                  type: `${opMap[payload.op]}_SUCCESS`,
                  payload: {
                    data,
                    params,
                  },
                });
              } else { // logout
                auth.signOut().then(
                  () => {
                    if (process.env.NODE_ENV !== 'production') {
                      console.log('App Info Signed Out'); // comment
                    }
                    dispatch({
                      type: 'USER_LOGOUT',
                      payload,
                    });
                  },
                  (error) => {
                    if (process.env.NODE_ENV !== 'production') {
                      console.error('App Info Sign Out Error', error); // comment
                    }
                  },
                );
              }
              if (data.updated_user_profile) {
                dispatch({
                  type: 'SET_UPDATED_USER_PROFILE',
                  payload: { data, params },
                });
              }
            } else {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`getDataAction ${payload.op} error: `, response);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getDataAction(${payload.op || ''}) Error - ${auth.currentUser.uid}`,
                message: JSON.stringify({ postData, responseData: response.data }),
                error: JSON.stringify(response.data),
                // store: JSON.stringify(getState()),
              });
              dispatch({
                type: `${opMap[payload.op]}_ERROR`,
                payload: {
                  data,
                  params,
                },
              });
              if (payload.show_error_alert) {
                const id = (new Date()).getTime();
                dispatch({
                  type: 'RECEIVED_NEW_SNACK',
                  payload: {
                    snack: {
                      id,
                      msg: payload.error_alert_msg || 'Error in updating!',
                    },
                  },
                });
              }
            }
          })
          .catch((err) => {
            // axios error
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getDataAction ${payload.op} err--------------------: `, err);
              console.log(`getDataAction ${payload.op} err.response--------------------: `, err.response);
            }
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getDataAction(${payload.op || ''}) Error - axios - ${auth.currentUser.uid}`,
              message: JSON.stringify({ url, postData }),
              error: JSON.stringify({ err , config: err.config }),
              // store: JSON.stringify(getState()),
            });
            dispatch({
              type: `${opMap[payload.op]}_ERROR`,
              payload: {
                params,
                error: err,
                errorMessage: err.toString(),
              },
            });
          })
          .catch((err) => {
          // firebase error
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getDataAction ${payload.op} firebase err--------------------: ${err}`);
            }
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - getDataAction(${payload.op || ''}) Error - firebase`,
              message: 'Firebase error',
              error: err.toString(),
              // store: JSON.stringify(getState()),
            });
            dispatch({
              type: `${opMap[payload.op]}_ERROR`,
              payload: {
                ...payload,
                errorMessage: err.toString(),
              },
            });
          });
      });
  };
}

/* ***************************  End - Get Data  ****************************** */
