import { genericItemTypeToKeyName } from '../../helpers';


/* Get Email Marketing Campaigns */

export const getEmailMarketingCampaigns = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            campaigns: state.locations[location__id].marketing.emailMarketing.campaigns
            ?
              {
                ...state.locations[location__id].marketing.emailMarketing.campaigns,
                getInProgress: true, getStatus: {},
              }
            : {
                getInProgress: true, getStatus: {},
              },
          },
        },
      },
    },
  };
};

export const getEmailMarketingCampaignsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { email_marketing_campaigns, email_templates, email_marketing_segments } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            campaigns: {
              ...state.locations[location__id].marketing.emailMarketing.campaigns,
              ...email_marketing_campaigns,
              getInProgress: false, getStatus: {
                success: true,
              },
            },
            email_templates,
            segments: state.locations[location__id].marketing.emailMarketing.segments
            ? {
                ...state.locations[location__id].marketing.emailMarketing.segments,
                ...email_marketing_segments,
              }
            : email_marketing_segments,
          },
        },
      },
    },
  };
};


export const getEmailMarketingCampaignsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            campaigns: {
              ...state.locations[location__id].marketing.emailMarketing.campaigns,
              getInProgress: false, getStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};



/* Get Email Marketing Campaign Data */

export const getEmailMarketingCampaignData = (state, action) => {
  const { params: { location__id, em_campaign_id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            campaigns: {
              ...state.locations[location__id].marketing.emailMarketing.campaigns,
              keyValue: {
                ...state.locations[location__id].marketing.emailMarketing.campaigns.keyValue,
                [em_campaign_id]: {
                  ...state.locations[location__id].marketing.emailMarketing.campaigns.keyValue[em_campaign_id],
                  getDataInProgress: true, getDataStatus: {},
                },
              },
            },
          },
        },
      },
    },
  };
};

export const getEmailMarketingCampaignDataSuccess = (state, action) => {
  const { data, params: { location__id, em_campaign_id } } = action.payload;
  if (!location__id) return state;
  let { em_tasks } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            campaigns: {
              ...state.locations[location__id].marketing.emailMarketing.campaigns,
              keyValue: {
                ...state.locations[location__id].marketing.emailMarketing.campaigns.keyValue,
                [em_campaign_id]: {
                  ...state.locations[location__id].marketing.emailMarketing.campaigns.keyValue[em_campaign_id],
                  em_tasks,
                  getDataInProgress: false, getDataStatus: {
                    success: true,
                  },
                },
              },
            },
          },
        },
      },
    },
  };
};


export const getEmailMarketingCampaignDataError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, em_campaign_id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            campaigns: {
              ...state.locations[location__id].marketing.emailMarketing.campaigns,
              keyValue: {
                ...state.locations[location__id].marketing.emailMarketing.campaigns.keyValue,
                [em_campaign_id]: {
                  ...state.locations[location__id].marketing.emailMarketing.campaigns.keyValue[em_campaign_id],
                  getDataInProgress: false, getDataStatus: {
                    error: true,
                  },
                  error,
                  errorMessage,
                },
              },
            },
          },
        },
      },
    },
  };
};



/* Get Email Marketing Segments */

export const getEmailMarketingSegments = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            segments: state.locations[location__id].marketing.emailMarketing.segments
            ?
              {
                ...state.locations[location__id].marketing.emailMarketing.segments,
                getInProgress: true, getStatus: {},
              }
            : {
                getInProgress: true, getStatus: {},
              },
          },
        },
      },
    },
  };
};

export const getEmailMarketingSegmentsSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { email_marketing_segments } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            segments: {
              ...state.locations[location__id].marketing.emailMarketing.segments,
              ...email_marketing_segments,
              getInProgress: false, getStatus: {
                success: true,
              },
            },
          },
        },
      },
    },
  };
};


export const getEmailMarketingSegmentsError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            segments: {
              ...state.locations[location__id].marketing.emailMarketing.segments,
              getInProgress: false, getStatus: {
                error: true,
              },
              error,
              errorMessage,
            },
          },
        },
      },
    },
  };
};


/***************** Add Email Marketing Campaign **********************/

export const addEmailMarketingCampaignClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;


  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            campaigns: {
              ...state.locations[location__id].marketing.emailMarketing.campaigns,
              addInProgress: false, addStatus: {},
            },
          },
        },
      },
    },
  };
}
 
export const addEmailMarketingCampaign = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;


  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            campaigns: {
              ...state.locations[location__id].marketing.emailMarketing.campaigns,
              addInProgress: true, addStatus: {},
            },
          },
        },
      },
    },
  };
}


export const addEmailMarketingCampaignSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;

  const { email_marketing_campaign_data } = data;
  if (!email_marketing_campaign_data || !email_marketing_campaign_data._id) return state;

  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            campaigns: {
              ...state.locations[location__id].marketing.emailMarketing.campaigns,
              list: state.locations[location__id].marketing.emailMarketing.campaigns.list
                ? [email_marketing_campaign_data._id, ...state.locations[location__id].marketing.emailMarketing.campaigns.list]
                : [email_marketing_campaign_data._id],
              keyValue: state.locations[location__id].marketing.emailMarketing.campaigns.keyValue
              ? {
                  ...state.locations[location__id].marketing.emailMarketing.campaigns.keyValue,
                  [email_marketing_campaign_data._id]: {
                    ...email_marketing_campaign_data,
                  },
                }
              : {
                  [email_marketing_campaign_data._id]: {
                    ...email_marketing_campaign_data,
                  },
                },
              addInProgress: false, addStatus: { success: true },
            },
          },
        },
      },
    },
  };
}

export const addEmailMarketingCampaignError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id } = params;

  
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        marketing: {
          ...state.locations[location__id].marketing,
          emailMarketing: {
            ...state.locations[location__id].marketing.emailMarketing,
            campaigns: {
              ...state.locations[location__id].marketing.emailMarketing.campaigns,
              addInProgress: false, addStatus: { error: true },
            },
          },
        },
      },
    },
  };
}

/*************************** End of Add Email Marketing Campaign *******************************/



/***************** Generic Email Marketing List Item **********************/

export const editGenericEMListItemClear = (state, action, itemType) => {
  const { params: { location__id, mode, email_template_id, em_campaign_id, segment_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // campaigns, email_templates
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'em_campaign') {
      itemId = em_campaign_id;
    }
    if (itemType === 'em_segment') {
      itemId = segment_id;
    }
    if (itemType === 'email_template') {
      itemId = email_template_id;
    }
    if (!itemId) return state;

    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: false, editStatus: {} }; }
    if (mode === 'remove') { newStatus = { removeInProgress: false, removeStatus: {} }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            emailMarketing: {
              ...state.locations[location__id].marketing.emailMarketing,
              [keyName]: {
                ...state.locations[location__id].marketing.emailMarketing[keyName],
                keyValue: {
                  ...state.locations[location__id].marketing.emailMarketing[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].marketing.emailMarketing[keyName].keyValue[itemId],
                    ...newStatus,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    if (mode === 'add') { newStatus = { addInProgress: false, addStatus: {} }; }
    if (mode === 'copy') { newStatus = { copyInProgress: false, copyStatus: {} }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            emailMarketing: {
              ...state.locations[location__id].marketing.emailMarketing,
              [keyName]: {
                ...state.locations[location__id].marketing.emailMarketing[keyName],
                ...newStatus,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericEMListItem = (state, action, itemType) => {
  const { params: { location__id, mode, email_template_id, em_campaign_id, segment_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'em_campaign') {
      itemId = em_campaign_id;
    }
    if (itemType === 'email_template') {
      itemId = email_template_id;
    }
    if (itemType === 'em_segment') {
      itemId = segment_id;
    }
    if (!itemId) return state;

    if (process.env.NODE_ENV !== 'production') {
      console.log(`editGenericEMListItem itemId: `, itemId);
    }

    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: true, editStatus: {} }; }
    if (mode === 'remove') { newStatus = { removeInProgress: true, removeStatus: {} }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            emailMarketing: {
              ...state.locations[location__id].marketing.emailMarketing,
              [keyName]: {
                ...state.locations[location__id].marketing.emailMarketing[keyName],
                keyValue: {
                  ...state.locations[location__id].marketing.emailMarketing[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].marketing.emailMarketing[keyName].keyValue[itemId],
                    ...newStatus,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    if (mode === 'add') { newStatus = { addInProgress: true, addStatus: {} }; }
    if (mode === 'copy') { newStatus = { copyInProgress: true, copyStatus: {} }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            emailMarketing: {
              ...state.locations[location__id].marketing.emailMarketing,
              [keyName]: {
                ...state.locations[location__id].marketing.emailMarketing[keyName],
                ...newStatus,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericEMListItemSuccess = (state, action, itemType) => {
  const { data, params: { location__id, mode, em_campaign_id, email_template_id, segment_id } } = action.payload;
  if (!location__id) return state;
  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  const { email_template_data, email_marketing_campaign_data, email_marketing_segment_data } = data;
  if (mode === 'edit' || mode === 'remove') {
    let return_data = null;
    if (itemType === 'em_campaign') {
      itemId = em_campaign_id;
      return_data = email_marketing_campaign_data;
    }
    if (itemType === 'email_template') {
      itemId = email_template_id;
      return_data = email_template_data;
    }
    if (itemType === 'em_segment') {
      itemId = segment_id;
      return_data = email_marketing_segment_data;
    }
    if (!itemId) return state;

    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: false, editStatus: { success : true } }; }
    if (mode === 'remove') { newStatus = { removeInProgress: false, removeStatus: { success: true } }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            emailMarketing: {
              ...state.locations[location__id].marketing.emailMarketing,
              [keyName]: {
                ...state.locations[location__id].marketing.emailMarketing[keyName],
                list: mode === 'remove'
                ? state.locations[location__id].marketing.emailMarketing[keyName].list.filter(el => el !== itemId)
                : [ ...state.locations[location__id].marketing.emailMarketing[keyName].list ],
                keyValue: {
                  ...state.locations[location__id].marketing.emailMarketing[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].marketing.emailMarketing[keyName].keyValue[itemId],
                    ...return_data,
                    ...newStatus,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    let return_data = null;
    if (itemType === 'em_campaign') {
      return_data = email_marketing_campaign_data;
    }
    if (itemType === 'email_template') {
      return_data = email_template_data;
    }
    if (itemType === 'em_segment') {
      return_data = email_marketing_segment_data;
    }
    if (!return_data || !return_data._id) return state;
    if (mode === 'add') { newStatus = { addInProgress: false, addStatus: { success: true, data: { ...return_data } } }; }
    if (mode === 'copy') { newStatus = { copyInProgress: false, copyStatus: { success: true, data: { ...return_data } } }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            emailMarketing: {
              ...state.locations[location__id].marketing.emailMarketing,
              [keyName]: {
                ...state.locations[location__id].marketing.emailMarketing[keyName],
                list: state.locations[location__id].marketing.emailMarketing[keyName].list
                ? [return_data._id, ...state.locations[location__id].marketing.emailMarketing[keyName].list]
                : [return_data._id],
                keyValue: state.locations[location__id].marketing.emailMarketing[keyName].keyValue
                ? {
                    ...state.locations[location__id].marketing.emailMarketing[keyName].keyValue,
                    [return_data._id]: {
                      ...return_data,
                    },
                  }
                : {
                    [return_data._id]: {
                      ...return_data,
                    },
                  },
                ...newStatus,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const editGenericEMListItemError = (state, action, itemType) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, mode, em_campaign_id, email_template_id, segment_id } = params;

  let keyName = genericItemTypeToKeyName[itemType]; // message_templates, labels
  if (!keyName) return state;
  let itemId = '';

  if (mode === 'edit' || mode === 'remove') {
    if (itemType === 'em_campaign') {
      itemId = em_campaign_id;
    }
    if (itemType === 'email_template') {
      itemId = email_template_id;
    }
    if (itemType === 'em_segment') {
      itemId = segment_id;
    }
    if (!itemId) return state;

    let newStatus = {};
    if (mode === 'edit') { newStatus = { editInProgress: false, editStatus: { error : true } }; }
    if (mode === 'remove') { newStatus = { removeInProgress: false, removeStatus: { error: true } }; }

    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            emailMarketing: {
              ...state.locations[location__id].marketing.emailMarketing,
              [keyName]: {
                ...state.locations[location__id].marketing.emailMarketing[keyName],
                keyValue: {
                  ...state.locations[location__id].marketing.emailMarketing[keyName].keyValue,
                  [itemId]: {
                    ...state.locations[location__id].marketing.emailMarketing[keyName].keyValue[itemId],
                    ...newStatus,
                    error,
                    errorMessage,
                  },
                },
              },
            },
          },
        },
      },
    };
  }
  if (mode === 'add' || mode === 'copy') {
    let newStatus = {};
    if (mode === 'add') { newStatus = { addInProgress: false, addStatus: { error: true } }; }
    if (mode === 'copy') { newStatus = { copyInProgress: false, copyStatus: { error: true } }; }
    return {
      ...state,
      locations: {
        ...state.locations,
        [location__id]: {
          ...state.locations[location__id],
          marketing: {
            ...state.locations[location__id].marketing,
            emailMarketing: {
              ...state.locations[location__id].marketing.emailMarketing,
              [keyName]: {
                ...state.locations[location__id].marketing.emailMarketing[keyName],
                ...newStatus,
                error,
                errorMessage,
              },
            },
          },
        },
      },
    };
  }
  return state;
};

/*************************** End of Generic List Item *******************************/
