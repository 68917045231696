import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import CampaignData from './CampaignData';
import GenericAlertModal from '../../../Misc/GenericAlertModal';
import { getDataAction } from '../../../../redux/actions/getDataActions';
import { editDataAction } from '../../../../redux/actions/editDataActions';
import { formatDate, STRS, getUserGAId } from '../../../../helpers';

import editIcon from '../../../../img/icons/edit.svg';
import deleteIcon from '../../../../img/icons/delete.svg';

import './style.css';

const gtconfig = require('../../../../gtconfig');

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function CampaignCard(props) {
    let navigate = useNavigate();
    let urlParams = useParams();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const { _id, name, segment_id, segment_name, segment_data_count, email_subject, when, when_schedule_date, when_schedule_time, date_sent_tz } = props.campaign;
    const onEditClick = () => {
        navigate(`/marketing/locations/${urlParams.locationidentifier}/email-marketing/campaigns/${_id}`);
    }
    const onShowDataClick = () => {
        props.onShowDataClick(props.campaign);
    }
    const onDeleteClick = (e) => {
        setShowDeleteModal(true);
    };
    const onShowDeleteModal = (e) => {
        setShowDeleteModal(false);
    }
    const onDeleteConfirmClick = (e) => {
        props.onDeleteClick(props.campaign);
    }
    const curStatus = date_sent_tz ? 'Sent' : when === 'later' ? 'Scheduled' : 'Draft';
    return (
        <div className="gCrd emCmgnCrd" key={_id}>
            <div className="lt">
                <div className="title">{name}</div>
                { segment_name && <p><span>Segment:</span> {segment_name}</p> }
                { segment_id && <p><span>Number of Guests:</span> {segment_data_count}</p> }
                { email_subject && <p><span>Subject:</span> {email_subject}</p> }
                {
                    when === 'later' &&
                    <p className="sentOn"><span>Scheduled for:</span> {formatDate(when_schedule_date)} {moment(when_schedule_time, 'k:mm').format('h:mm a')}</p>
                }
                {
                    when === 'now' && !date_sent_tz &&
                    <p className="sentOn"><span>Scheduled for:</span> NOW</p>
                }
                {
                    date_sent_tz &&
                    <p className="sentOn"><span>Sent on:</span> {date_sent_tz}</p>
                }
            </div>
            <div className="rt">
                <div className="cm">
                    <div className="curSts">
                        <span className={curStatus.toLowerCase()}>{curStatus}</span>
                    </div>
                    <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"Show campaign interactions"}>
                        <div className="edit" onClick={onShowDataClick}>
                            <i className="fa-solid fa-table-list"></i>
                        </div>
                    </ToolTipWrapper>
                    <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={date_sent_tz ? "View campaign" : "Edit campaign"}>
                        <div className="edit" onClick={onEditClick}>
                            { date_sent_tz ? <i className="fa-solid fa-circle-info"></i> : <img src={editIcon} /> }
                        </div>
                    </ToolTipWrapper>
                    {
                    !props.isReadOnly &&
                    <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"Delete campaign"}>
                        <div className="delete" onClick={onDeleteClick}>
                            <img src={deleteIcon} />
                        </div>
                    </ToolTipWrapper>
                    }
                </div>
            </div>
            {
                showDeleteModal &&
                <GenericAlertModal
                modalType="deleteBroadcast"
                bodyRowOne={`Do you want to delete "${name}" campaign ?`}
                setModalVisible={onShowDeleteModal}
                onAffirmativeClick={onDeleteConfirmClick}
                inProgress={props.campaign.removeInProgress}
                showCancel={true}
                />
            }
        </div>
    )
}

function Campaigns(props) {
    const windowState = useSelector((state) => state.windowReducer);
    const dispatch = useDispatch();
    let urlParams = useParams();

    const [selectedCampaignId, setSelectedCampaignId] = useState(null);
    const [showCampaignData, setShowCampaignData] = useState(false);
        
    useEffect(() => {
        dispatch(getDataAction({
          op: 'get_email_marketing_campaigns',
          params: {
            location__id: urlParams.locationidentifier,
            getEmailTemplates: true,
            getSegmentsCount: true,
            // getRateCodes: true,
            // getBookingSources: true,
            // getSenderNames: true,
            // getSenderEmails: true,
            // getGuestLangs: true,
          },
        }));
    }, [urlParams.locationidentifier]); // urlParams.locationidentifier

    const onAddClick = () => {
        dispatch(editDataAction({
            op: 'add_email_marketing_campaign',
            params: {
              location__id: urlParams.locationidentifier,
            },
        }));
    }

    const onDeleteClick = (campaign) => {
        dispatch(editDataAction({
            op: 'edit_email_marketing_campaign',
            params: {
                location__id: urlParams.locationidentifier,
                mode: 'remove',
                em_campaign_id: campaign._id,
            },
            show_alert: true,
            alert_msg: 'Deleted successfully',
        }));
    }

    const onShowDataClick = (campaign) => {
        setSelectedCampaignId(campaign._id);
        setShowCampaignData(true);
    }
    const onCampaignDataCloseClick = (e) => {
        setSelectedCampaignId(null);
        setShowCampaignData(false);
    }

    const renderCampaignDataModal = () => {
        return (
          <Modal>
              <div id="cModelId" className="sdMdl cmpgnDataMdl">
                <div className="ctnt">
                  <CampaignData
                    onCloseClick={onCampaignDataCloseClick}
                    campaignId={selectedCampaignId}
                    settings={props.settings}
                    windowState={windowState}
                    isReadOnly={props.isReadOnly}
                    campaigns={props.marketing.emailMarketing.campaigns || {}}
                    segments={props.marketing.emailMarketing.segments || {}}
                  />
                </div>
              </div>
            </Modal>
        );
      };


    if (process.env.NODE_ENV !== 'production') {
        console.log('Campaigns urlParams: ', urlParams);
        console.log('Campaigns props: ', props);
    }
    
    const { emailMarketing } = props.marketing;
    if (!emailMarketing || !emailMarketing.campaigns) return null;
    const { campaigns } = emailMarketing;

    return (
        <>
        {/*
            reviews.getInProgress &&
            <div className="gPnlLdng">
            <i className="fa fa-spinner fa-spin" />
            </div>
        */}
        {
            (campaigns && (campaigns.getInProgress || campaigns.addInProgress)) &&
            <div className="gPnlSplLdng">
            <div className="innerWrap">
                <i className="fa fa-spinner fa-spin" />
                <img src={gtconfig.REPUTATION_LOADER_URL} />
            </div>
            </div>
        }
        <div className="gPScrlWrp flHt mk">
            <div className="gPg cmpgsPg">
                <h4 className="gPageWrapOuterTitle title">
                Email Marketing Campaigns{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
                {
                    !props.isReadOnly &&
                    <span className="add" onClick={onAddClick}><i className="fa fa-plus-circle" /></span>
                }
                </h4>
                {
                    campaigns.list && campaigns.list.length > 0 && campaigns.keyValue &&
                    campaigns.list.map((campaignId) => {
                        if (!campaigns.keyValue[campaignId]) return null;
                        return <CampaignCard campaign={campaigns.keyValue[campaignId]} 
                            onDeleteClick={onDeleteClick}
                            onShowDataClick={onShowDataClick}
                            isReadOnly={props.isReadOnly} />;
                    })
                }
            </div>
        </div>
        { showCampaignData && renderCampaignDataModal() }
        </>
    );
}
export default Campaigns;
