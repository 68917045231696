import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import CustomTimePicker from '../../../../Misc/CustomTimePicker';
// import Configure from './Configure';
import EMEmailTemplate from '../../../../../components/Marketing/EmailBuilder/EMEmailTemplate';

import { getDataAction } from '../../../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';

import emojiIcon from '../../../../../img/icons/emoji.svg';
import requiredIcon from '../../../../../img/icons/required.svg';

import './style.css';

const gtconfig = require('../../../../../gtconfig');

const targetOptions = [];
targetOptions.push({ label: 'Yet to arrive', value: 'upcoming' });
targetOptions.push({ label: 'In-House', value: 'checked-in' });
targetOptions.push({ label: 'Departing Soon', value: 'departing-soon' });
targetOptions.push({ label: 'Staff', value: 'staff' });

const personalizeOptionsG = [];
personalizeOptionsG.push({ label: 'Personalize', value: 'personalize' });
personalizeOptionsG.push({ label: 'First Name', value: '{{first_name}}' });
personalizeOptionsG.push({ label: 'Last Name', value: '{{last_name}}' });
// personalizeOptionsG.push({ label: 'Room Type', value: '{{room_type}}' });
// personalizeOptionsG.push({ label: 'Hotel Name', value: '{{hotel_name}}' });

const modalRoot = document.getElementById('modal-email-template');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}


function SingleCampaign(props) {
    const userState = useSelector((state) => state.userReducer);
    const dispatch = useDispatch();
    let urlParams = useParams();

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    const [segmentOptions, setSegmentOptions] = useState([]);

    const [targetValue, setTargetValue] = useState();
    const [targetError, setTargetError] = useState('');

    const [doNotSendToFutureGuests, setDoNotSendToFutureGuests] = useState(false);

    const [subject, setSubject] = useState('');
    const [subjectError, setSubjectError] = useState('');

    const [previewText, setPreviewText] = useState('');
    const [previewTextError, setPreviewTextError] = useState('');

    const [personalizeOptions, setPersonalizeOptions] = useState([]);
    const [personalizeValue, setPersonalizeValue] = useState([
        { label: 'Personalize', value: 'personalize' }
    ]);
    const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
    const [emojiSelectorPreviewTextOpen, setEmojiSelectorPreviewTextOpen] = useState(false);

    const [when, setWhen] = useState('');
    const [scheduleDate, setScheduleDate] = useState(new Date());
    const [scheduleTime, setScheduleTime] = useState(new Date(moment('10:00 AM', 'h:mm A')));
    const [whenError, setWhenError] = useState('');
    const [computedWhen, setComputedWhen] = useState('');

    const [emailTemplateId, setEmailTemplateId] = useState('');
    const [emailTemplateError, setEmailTemplateError] = useState('');

    const [showEmailTemplateModal, setShowEmailTemplateModal] = useState(false);
    const emailTemplateModalRef = useRef();

    const subjectEl = useRef(null);
    const previewTextEl = useRef(null);

    const [buttonLabel, setButtonLabel] = useState('Save Changes');

    const [someChange, setSomeChange] = useState(false);
        
    
    useEffect(() => {
        dispatch(getDataAction({
          op: 'get_email_marketing_campaign',
          params: {
            location__id: urlParams.locationidentifier,
            campaign__id: urlParams.campaignId,
            // getEmailTemplates: true,
            // getRooms: true,
            // getRateCodes: true,
            // getBookingSources: true,
            // getSenderNames: true,
            // getSenderEmails: true,
            // getGuestLangs: true,
          },
        }));

        const campaign = props.marketing.emailMarketing && props.marketing.emailMarketing.campaigns && 
            props.marketing.emailMarketing.campaigns.keyValue && props.marketing.emailMarketing.campaigns.keyValue[urlParams.campaignId];
        if (campaign) {
            setName(campaign.name || '');
            setDoNotSendToFutureGuests(campaign.do_not_send_to_future_guests || false);
            setSubject(campaign.email_subject || '');
            setPreviewText(campaign.email_preview_text || '');
            // if (campaign.segment_id && campaign.segment_name) {
            //     setTargetValue({ label: campaign.segment_name, value: campaign.segment_id });
            // }
            if (campaign.segments && campaign.segments.length > 0) {
              setTargetValue(campaign.segments);
            }
            if (campaign.when) {
                setWhen(campaign.when);
            }
            if (campaign.when_schedule_date && campaign.when_schedule_time) {
                setScheduleDate(new Date(campaign.when_schedule_date));
                setScheduleTime(new Date(moment(campaign.when_schedule_time, 'k:mm')));
            }
        }

        const tOptions = [];
        if (props.marketing.emailMarketing.segments && props.marketing.emailMarketing.segments.list &&
          props.marketing.emailMarketing.segments.list.length > 0 && props.marketing.emailMarketing.segments.keyValue
        ) {
          props.marketing.emailMarketing.segments.list.forEach((segmentId) => {
            if (props.marketing.emailMarketing.segments.keyValue[segmentId]) {
              tOptions.push({ label: props.marketing.emailMarketing.segments.keyValue[segmentId].name, value: segmentId });
            }
          });
        }
        setSegmentOptions(tOptions);

        setPersonalizeOptions([
          ...personalizeOptionsG,
          // ...newOptions,
        ]);

    }, [urlParams.locationidentifier, urlParams.campaignId]); // urlParams.locationidentifier

    const onEmailTemplateEditChange = (e) => {
        setShowEmailTemplateModal(true);
    };
    const onEmailPreviewModalCloseClick = (e) => {
        setShowEmailTemplateModal(false);
    };
    const onEmailTemplateSaveClick = (data) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('onEmailTemplateSaveClick: ', data);
        }
        setEmailTemplateId(data._id);
        setSomeChange(true);
        setEmailTemplateError(data._id ? '' : 'error');
    };

    /* Campaign Name */
    const onNameChange = (e) => {
        setName(e.target.value);
        setSomeChange(true);
        setNameError(e.target.value ? '' : 'error');
    };

    const onTargetChange = (value) => {
        setTargetValue(value);
        // if (value.value === 'upcoming' || value.value === 'departing-soon') {
        //   if (broadcastType === 'recurring') setWhen('later');
        //   if (broadcastType === 'one-time') setWhen('');
        // }
        // if (value.value === 'checked-in' || value.value === 'staff') {
        //   setWhen('later');
        // }
        // let newOptions = [];
        // if (value.value === 'upcoming') {
        //   newOptions.push({ label: 'Self Check In Link', value: '{{selfcheckinlink}}' });
        // }
        // if (value.value === 'checked-in' || value.value === 'departing-soon') {
        //   newOptions.push({ label: 'Self Check Out Link', value: '{{selfcheckoutlink}}' });
        // }
        // setPersonalizeOptions([
        //   ...personalizeOptionsG,
        //   ...newOptions,
        // ]);
        setTargetError('');
        setSomeChange(true);
      };

  const onDoNotSendToFutueGuestsChange = (e) => {
    setDoNotSendToFutureGuests(e.target.checked);
    setSomeChange(true);
  };

  const onDoNotSendToFutueGuestsLabelClick = (e) => {
    setDoNotSendToFutureGuests(doNotSendToFutureGuests => !doNotSendToFutureGuests);
    setSomeChange(true);
  };

    /* Email Subject */

  const onPersonalizeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('subjectEl: ', subjectEl.current.selectionStart);
    }
    let newSubject = '';
    if (value && value.value !== 'personalize') {
      if (subject) {
        // setMessage(message => `${message} ${values[0].label}`);
        newSubject = `${subject.substring(0,subjectEl.current.selectionStart)}${value.value}${subject.substring(subjectEl.current.selectionStart)}`;
        setSubject(newSubject);
      } else {
        newSubject = `${value.value}`;
        setSubject(newSubject);
        // props.onSubjectChange(`${value.value}`);
      }
    }
    setSomeChange(true);
    setSubjectError(value && value.value ? '' : 'error');
  };
  const onSubjectChange = (e) => {
    setSubject(e.target.value);
    // props.onSubjectChange(e.target.value);
    setSomeChange(true);
    setSubjectError(e.target.value ? '' : 'error');
  };
  const onOpenEmojiSelectorClick = (e) => {
    setEmojiSelectorOpen(emojiSelectorOpen => !emojiSelectorOpen);
  };
  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    let newSubject = '';
    if (emoji && emoji.native) {
      // setMessage(message => `${message} ${emoji.native}`);
      if (subject) {
        newSubject = `${subject.substring(0,subjectEl.current.selectionStart)}${emoji.native}${subject.substring(subjectEl.current.selectionStart)}`;
        setSubject(newSubject);
        // props.onSubjectChange(`${subject.substring(0,messageEl.current.selectionStart)}${emoji.native}${subject.substring(messageEl.current.selectionStart)}`);
      } else {
        newSubject = `${emoji.native}`;
        setSubject(newSubject);
        // props.onSubjectChange(`${emoji.native}`);
      }
    }
    setSomeChange(true);
    setSubjectError(emoji && emoji.native ? '' : 'error');
  };


  /* Preview Text */

  const onPersonalizePreviewTextChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPersonalizePreviewTextChange previewTextEl: ', previewTextEl.current.selectionStart);
    }
    let newPreviewText = '';
    if (value && value.value !== 'personalize') {
      if (previewText) {
        newPreviewText = `${previewText.substring(0,previewTextEl.current.selectionStart)}${value.value}${previewText.substring(previewTextEl.current.selectionStart)}`;
        setPreviewText(newPreviewText);
      } else {
        newPreviewText = `${value.value}`;
        setPreviewText(newPreviewText);
      }
    }
    setSomeChange(true);
    setPreviewTextError(value && value.value ? '' : 'error');
  };
  const onPreviewTextChange = (e) => {
    setPreviewText(e.target.value);
    setSomeChange(true);
    setPreviewTextError(e.target.value ? '' : 'error');
  };
  const onOpenEmojiSelectorPreviewTextClick = (e) => {
    setEmojiSelectorPreviewTextOpen(emojiSelectorPreviewTextOpen => !emojiSelectorPreviewTextOpen);
  };
  const onEmojiPreviewTextClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiPreviewTextClick: ', emoji, ' # ', e);
    }
    let newPreviewText = '';
    if (emoji && emoji.native) {
      // setMessage(message => `${message} ${emoji.native}`);
      if (previewText) {
        newPreviewText = `${previewText.substring(0,previewTextEl.current.selectionStart)}${emoji.native}${previewText.substring(previewTextEl.current.selectionStart)}`;
        setPreviewText(newPreviewText);
      } else {
        newPreviewText = `${emoji.native}`;
        setPreviewText(newPreviewText);
      }
    }
    setSomeChange(true);
    setPreviewTextError(emoji && emoji.native ? '' : 'error');
  };

  const onWhenNowClick = (e) => {
    if (campaign.date_sent) return;
    const tWhen = when === 'now' ? '' : 'now';
    setWhen(tWhen);
    setWhenError('');
    setSomeChange(true);

    if (name && subject && previewText && targetValue && targetValue.value && campaign.template_data && campaign.template_data.json && tWhen) {
      setButtonLabel('Send Campaign Now');
    } else {
      setButtonLabel('Save Changes');
    }
  }
  const onWhenLaterClick = (e) => {
    if (campaign.date_sent) return;
    const tWhen = when === 'later' ? '' : 'later';
    setWhen(tWhen);
    setWhenError('');
    setSomeChange(true);
    // setScheduleTime(new Date(moment('11:00 AM', 'h:mm A')));
    setScheduleTime(new Date(moment().add(10, 'minutes')));

    if (name && subject && previewText && targetValue && targetValue.value && campaign.template_data && campaign.template_data.json && tWhen) {
      setButtonLabel('Schedule Campaign for Later');
    } else {
      setButtonLabel('Save Changes');
    }
  }
  const onScheduleDateChange = (selectedDate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onScheduleDateChange selectedDate; ', selectedDate);
    }
    setScheduleDate(selectedDate ? new Date(selectedDate) : null);
    let tError = selectedDate ? '' : 'When to send is required';
    let fullDate = moment(selectedDate).format('YYYY-MM-DD') + ' ' + moment(scheduleTime).format('k:mm');
    if (process.env.NODE_ENV !== 'production') {
      console.log('onScheduleDateChange fullDate; ', fullDate);
    }
    if (!tError && moment(fullDate, 'YYYY-MM-DD k:mm') < moment()) {
      tError = 'Schedule date and time cannot be in the past';
    }
    // if (!tError && moment(selectedDate).startOf('day') < moment().startOf('day')) {
    //   tError = 'Schedule date cannot be in the past';
    // }
    if (fullDate === moment().format('YYYY-MM-DD k:mm')) {
      setButtonLabel('Send Campaign Now');
      setComputedWhen('now');
    } else { setButtonLabel('Schedule Campaign for Later'); setComputedWhen('later'); }
    setWhenError(tError);
    setSomeChange(true);
  };
  const onScheduleTimeChange = (selectedTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onScheduleTimeChange selectedTime; ', selectedTime);
    }
    // new Date(moment('10:00 AM', 'h:mm A')
    // setScheduleTime(selectedTime ? new Date(selectedTime) : null);
    setScheduleTime(selectedTime ? new Date(moment(selectedTime, 'h:mm A')) : null);
    let tError = selectedTime ? '' : 'When to send is required';
    let fullDate = moment(scheduleDate).format('YYYY-MM-DD') + ' ' + moment(new Date(moment(selectedTime, 'h:mm A'))).format('k:mm');
    if (!tError && moment(fullDate, 'YYYY-MM-DD k:mm') < moment().startOf('minute')) {
      tError = 'Schedule date and time cannot be in the past';
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onScheduleTimeChange startOf minute; ',  moment(fullDate, 'YYYY-MM-DD k:mm').diff(moment().startOf('minute'), 'minutes'));
    }
    if (fullDate === moment().format('YYYY-MM-DD k:mm')) {
      setButtonLabel('Send Campaign Now');
      setComputedWhen('now')
    } else { setButtonLabel('Schedule Campaign for Later'); setComputedWhen('later'); }
    // if (!tError && new Date(moment(selectedTime, 'h:mm A')) < new Date()) {
    //   tError = 'Schedule time cannot be in the past';
    // }
    setWhenError(tError);
    setSomeChange(true);
    
  };


  const onSaveClick = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SingleTransactionEmail onSaveClick: ', emailTemplateId);
      console.log('onSaveClick someChange: ', someChange);
      console.log('onSaveClick doNotSendToFutureGuests: ', doNotSendToFutureGuests);
      console.log('onSaveClick subject: ', subject);
      console.log('onSaveClick previewText: ', previewText);
      console.log('onSaveClick scheduleDate: ', scheduleDate);
      console.log('onSaveClick scheduleTime: ', scheduleTime);
      console.log('onSaveClick computedWhen: ', computedWhen);
      console.log('onSaveClick editInProgress: ', props.editInProgress);
    }

    const { emailMarketing } = props.marketing;
    if (!emailMarketing || !emailMarketing.campaigns) return null;
    const campaign = emailMarketing.campaigns.keyValue && emailMarketing.campaigns.keyValue[urlParams.campaignId];
    if (!campaign) return null;
    if (campaign.date_sent) return;

    if (campaign.editInProgress) return;
    // if (!someChange && !isEnabledChanged) return;
    // setEmailTemplateError(emailTemplateId ? '' : 'error');
    // setSubjectError(subject ? '' : 'error');
    // setPreviewTextError(previewTextError ? '' : 'error');
    if (!name) return;
    const params = {
      location__id: props.locationInfo._id,
      em_campaign_id: urlParams.campaignId,
      mode: 'edit',
      name,
    };
    // params.mode = props.mode; // 'add', 'edit'
    if (someChange) {
      // params.email_template_id = emailTemplateId;
      // if (targetValue && targetValue.value) params.segment_id = targetValue.value;
      // if (targetValue && targetValue.label) params.segment_name = targetValue.label;
      if (targetValue && targetValue.length > 0) {
        params.segments = targetValue;
      }
      params.do_not_send_to_future_guests = doNotSendToFutureGuests;
      params.email_subject = subject;
      params.email_preview_text = previewText || '';

      params.when = when;
      if (computedWhen) params.computed_when = computedWhen;
      if (when === 'later') {
        if (!scheduleDate || !scheduleTime) {
          setWhenError('When to send is required');
          return;
        }
        params.when_schedule_date = moment(scheduleDate).format('YYYY-MM-DD');
        params.when_schedule_time = moment(scheduleTime).format('k:mm');
        let fullDate = moment(scheduleDate).format('YYYY-MM-DD') + ' ' + moment(scheduleTime).format('k:mm');
        if (moment(fullDate, 'YYYY-MM-DD k:mm') < moment().startOf('minute')) {
          setWhenError('Schedule date and time cannot be in the past');
          return;
        }
        // if (scheduleTime < new Date()) {
        //   setWhenError('Schedule time cannot be in the past');
        //   return;
        // }
      }
    }
    // if (isEnabledChanged) {
    //   params.is_enabled = isEnabled;
    // }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSaveClick params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_email_marketing_campaign',
      params,
      show_alert: true,
      alert_msg: 'Updated successfully',
    }));
    setSomeChange(false);
  };

    const renderEmailTemplateModal = (customTitle) => {
        return (
            <Modal>
            <div id="cModelId" className="cModal etModal">
                <div className="content" ref={emailTemplateModalRef}>
                <EMEmailTemplate
                    onCancelClick={onEmailPreviewModalCloseClick}
                    onSaveClick={onEmailTemplateSaveClick}
                    title={`${customTitle} - ${props.emailSelectorTitle}`}
                    emCampaignId={urlParams.campaignId}
                    campaignData={props.marketing.emailMarketing.campaigns.keyValue[urlParams.campaignId]}
                    emailTemplateId={emailTemplateId}
                    type={props.type}
                    userState={userState}
                    locationInfo={props.locationInfo}
                    emailMarketing={props.marketing.emailMarketing}
                    email_templates={props.marketing.emailMarketing.email_templates}
                    settings={props.settings}
                    isReadOnly={campaign.date_sent}
                />
                </div>
            </div>
            </Modal>
        );
    };

    const renderTarget = (segments) => {
        return (
          <>
            <div className="teRw trgtRw">
                <div className="lt">
                    <label className="gLbl">Select Segment</label>
                    <p className="info">Choose segment of guests to whom message will be sent.</p>
                </div>
                <div className="targetRow">
                    <div className="targetBox">
                        <Select
                        options={segmentOptions}
                        onChange={onTargetChange}
                        value={targetValue}
                        isMulti={true}
                        isDisabled={campaign.date_sent}
                        />
                    </div>
                </div>
                {
                  targetValue && targetValue.value &&
                  <div className="numGusttBx">
                    <p><span>Number of Guests:</span> { segments && segments.keyValue && segments.keyValue[targetValue.value] && segments.keyValue[targetValue.value].data ? segments.keyValue[targetValue.value].data.count : 0}</p>
                  </div>
                }
                {
                    targetError &&
                    <div className="errorRow">
                        <div className="gError">
                        <img src={requiredIcon} /> Audience is required
                        </div>
                    </div>
                }
            </div>
            <div className="teRw doNotSendRw">
              <div className="lt">
                <div className="chkBxWrp">
                  <input type="checkbox" name="doNotSendToFutureGuests" onChange={onDoNotSendToFutueGuestsChange} value={doNotSendToFutureGuests ? 'on' : 'off'} checked={doNotSendToFutureGuests} />
                  <label className="gLbl" onClick={onDoNotSendToFutueGuestsLabelClick}>Do Not Send to Future Guests</label>
                </div>
                <p className="gHelpMsg">Emails will not be sent to guests who have a reservation in future.</p>
              </div>
            </div>
          </>
        );
      }

    const renderWhen = () => {
        return (
            <div className="teRw whenRw">
                <div className="lt">
                    <label className="gLbl">When to send message</label>
                    <p className="info">Choose the time at which message will be sent to guests.</p>
                    <div className="tabRow whenRow">
                    <div className={'itemBox ' + (when === 'now' ? 'selected' : '')} onClick={onWhenNowClick}>
                        Now
                    </div>
                    <div className={'itemBox ' + (when === 'later' ? 'selected' : '')} onClick={onWhenLaterClick}>
                        Later
                    </div>
                    </div>
                    {
                    when === 'later' &&
                    <div className="scheduleRow">
                        <div className="itemBox scheduleDateBox">
                        <div className="gLabel">
                            Date
                        </div>
                        <div className="gInput">
                            {/*<DateTime
                            value={scheduleDate}
                            dateFormat="DD MMM YYYY"
                            timeFormat=""
                            onChange={onScheduleDateChange}
                            isValidDate={inValidScheduleDate}
                            closeOnSelect={true}
                            />*/}
                            <div className="gIpt">
                            <DatePicker selected={scheduleDate} onChange={onScheduleDateChange}
                                dateFormat="dd MMM yyyy"
                                // minDate={new Date()}
                            />
                            </div>
                        </div>
                        </div>
                        <div className="itemBox">
                        <div className="gLabel">
                            Time
                        </div>
                        <div className="gInput">
                        <CustomTimePicker
                            value={moment(scheduleTime).format('h:mm A')}
                            onChange={onScheduleTimeChange}
                        />
                        </div>
                        </div>
                    </div>
                    }
                    {
                    whenError &&
                    <div className="errorRow">
                        <div className="gError">
                        <img src={requiredIcon} /> {whenError}
                        </div>
                    </div>
                    }
                </div>
          </div>
        );
      };


    if (process.env.NODE_ENV !== 'production') {
        console.log('SingleCampaign urlParams: ', urlParams);
        console.log('SingleCampaign props: ', props);
        console.log('SingleCampaign targetValue: ', targetValue);
    }
    
    const { emailMarketing } = props.marketing;
    if (!emailMarketing || !emailMarketing.campaigns) return null;
    const campaign = emailMarketing.campaigns.keyValue && emailMarketing.campaigns.keyValue[urlParams.campaignId];
    if (!campaign) return null;

    const { segments } = emailMarketing;

    let emailTemplateName = '';
    if (emailTemplateId && emailMarketing.email_templates && emailMarketing.email_templates.keyValue &&
        emailMarketing.email_templates.keyValue[emailTemplateId]
    ) {
        emailTemplateName = emailMarketing.email_templates.keyValue[emailTemplateId].name || '';
    }

    let customTitle = 'Default';

    return (
        <>
        {
            campaign.getInProgress &&
            <div className="gPnlSplLdng">
            <div className="innerWrap">
                <i className="fa fa-spinner fa-spin" />
                <img src={gtconfig.REPUTATION_LOADER_URL} />
            </div>
            </div>
        }
        <div className="gPScrlWrp flHt mk">
            <div className="gPg cmpgnPg">
                <h3><Link to={`/marketing/locations/${urlParams.locationidentifier}/email-marketing/campaigns`}>Back</Link></h3>
                <div className="cmpgnSngl">
                <div className="teRw emValRw">
                        <div className="lt">
                            <label className="gLbl">Campaign Name</label>
                            <div className="gIpt">
                            <input
                                className={'gInput ' + (!name ? 'error' : '')}
                                onChange={onNameChange}
                                placeholder="Campaign Name..."
                                value={name}
                                readOnly={campaign.date_sent}
                            />
                            </div>
                            {
                            nameError &&
                            <span className="gErr">Required</span>
                            }
                        </div>
                    </div>
                    { renderTarget(segments) }
                    <div className="teRw tplNmRw">
                        <div className="lt">
                            <label className="gLbl">Email Template</label>
                            <div className="title">
                            {
                                campaign.template_data && campaign.template_data.json
                                ? <span className="etEdit" onClick={onEmailTemplateEditChange}>View/Edit</span>
                                : <span className="etEdit" onClick={onEmailTemplateEditChange}>Compose</span>
                            }
                            </div>
                            {
                            emailTemplateError &&
                            <span className="gErr">Required</span>
                            }
                        </div>
                    </div>

                    <div className="teRw emValRw">
                        <div className="lt">
                            <label className="gLbl">Email Subject
                            <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"Subject of your email. It's a good idea to include a relevant subject as it captures your audience's attention and generally leads to higher open rates."}>
                                <span className="tltp">
                                <i className="fas fa-info-circle"></i>
                                </span>
                            </ToolTipWrapper>
                            </label>
                            <div className="gIpt">
                            <input
                                className={'gInput ' + (!subject ? 'error' : '')}
                                onChange={onSubjectChange}
                                placeholder="Subject..."
                                value={subject}
                                rows="4"
                                ref={subjectEl}
                                readOnly={campaign.date_sent}
                            />
                            </div>
                            {
                            subjectError &&
                            <span className="gErr">Required</span>
                            }
                            <div className={'emojiIconBox' + (subjectError ? ' adjTp' : '')}>
                              { 
                                !campaign.date_sent &&
                                <div className="emojiIcon" onClick={onOpenEmojiSelectorClick}>
                                    {
                                    emojiSelectorOpen
                                    ? <i className="fa fa-times-circle" />
                                    : <img src={emojiIcon} />
                                    }

                                </div>
                              }
                            </div>
                            { emojiSelectorOpen &&
                            <div className="emojiBox">
                                <Picker data={emojiData}
                                className="em"
                                onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
                                />
                            </div>
                            }
                        </div>
                    </div>
                        <div className="teRw emPrsnlRw">
                        <div className="lt">
                            <label className="gSLbl">Choose a dynamic label to personalize the email subject line</label>
                            <Select
                            className="emPrsnlSel"
                            options={personalizeOptions}
                            value={personalizeValue}
                            onChange={onPersonalizeChange}
                            isDisabled={campaign.date_sent}
                            />
                            <div className="gHelpMsg">
                                {props.dynamicLabel}
                            </div>
                        </div>
                        </div>

                        <div className="teRw emValRw">
                        <div className="lt">
                            <label className="gLbl">Preview Text
                            <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"A brief description of what's in your email, shown as a preview in the inbox next to the subject line. It's a good idea to include preview text as it captures your audience's attention and generally leads to higher open rates."}>
                                <span className="tltp">
                                <i className="fas fa-info-circle"></i>
                                </span>
                            </ToolTipWrapper>
                            </label>
                            <div className="gIpt">
                            <input
                                className={'gInput ' + (!subject ? 'error' : '')}
                                onChange={onPreviewTextChange}
                                placeholder="Subject..."
                                value={previewText}
                                rows="4"
                                ref={previewTextEl}
                                readOnly={campaign.date_sent}
                            />
                            </div>
                            {/*
                            previewTextError &&
                            <span className="gErr">Required</span>
                            */}
                            <div className={'emojiIconBox' + (subjectError ? ' adjTp' : '')}>
                            { 
                                !campaign.date_sent &&
                                <div className="emojiIcon" onClick={onOpenEmojiSelectorPreviewTextClick}>
                                    {
                                    emojiSelectorPreviewTextOpen
                                    ? <i className="fa fa-times-circle" />
                                    : <img src={emojiIcon} />
                                    }
                                </div>
                            }
                            </div>
                            { emojiSelectorPreviewTextOpen &&
                            <div className="emojiBox">
                                <Picker data={emojiData}
                                className="em"
                                onEmojiSelect={(emoji, e) => onEmojiPreviewTextClick(emoji, e)}
                                />
                            </div>
                            }
                        </div>
                        </div>
                    <div className="teRw emPrsnlRw">
                        <div className="lt">
                            <label className="gSLbl">Choose dynamic label to personalize preview text</label>
                            <Select
                            className="emPrsnlSel"
                            options={personalizeOptions}
                            value={personalizeValue}
                            onChange={onPersonalizePreviewTextChange}
                            isDisabled={campaign.date_sent}
                            />
                            <div className="gHelpMsg">
                                {props.dynamicLabel}
                            </div>
                        </div>
                    </div>
                    { renderWhen() }
                    {
                        !props.isReadOnly && !campaign.date_sent &&
                        <div className="teRw btnRw">
                            <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onSaveClick} >
                                { buttonLabel }
                                {
                                campaign.editInProgress && <i className="fa fa-spinner fa-spin" />
                                }
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
        { (showEmailTemplateModal) && renderEmailTemplateModal(customTitle) }
        </>
    );
}
export default SingleCampaign;
