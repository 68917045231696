// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import { Interweave } from 'interweave';
import ShortUniqueId from 'short-unique-id';

import FeedbackSection from './FeedbackSection';
import ToolTipWrapper from '../../../../../Misc/ToolTipWrapper';
import { editDataAction, editDataClearAction } from '../../../../../../redux/actions/editDataActions';

import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import GenericAlertModal from '../../../../../Misc/GenericAlertModal';

import { validateUrl, renderOTALogo, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP} from '../../../../../../helpers';
import { STRS, getUserGAId } from '../../../../../../helpers';

import './style.css';

import starIcon from '../../../../../../img/icons/star.png';

const gtconfig = require('../../../../../../gtconfig');
const suid = new ShortUniqueId({ length: 16 });

function GenericHotelDataSingle(props) {
  return (
    <div className={'gSettingsRow ' + (props.dataTypeCode || '') + (props.noBorder ? ' noBorder' : '')} onMouseEnter={props.onMEnter} onMouseLeave={props.onMLeave}>
      {
        props.title &&
        <div className="titleRow">
          <p>{props.title}</p>
        </div>
      }
      <div className="contentRow">
        <div className="contentBox">
          <label>{props.desc}</label>
          <div className="gIpt">
            {
              props.type === "textarea"
              ?
                <textarea
                  className="gInput"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
              :
                <input
                  className="gInput"
                  type="text"
                  value={props.value}
                  onChange={props.onChange}
                  readOnly={props.readOnly}
                />
            }
          </div>
          {
            props.error &&
            <span className="gErr">{props.error}</span>
          }
        </div>
        <div className="contentBoxNA">
        </div>
      </div>
    </div>
  );
}


function FeedbackGeneric(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let urlParams = useParams();
  const location = useLocation();

  const [generic, setGeneric] = useState({});

  const [aQEnabled, setAQEnabled] = useState(false);
  const [aQEnabledChanged, setAQEnabledChanged] = useState(false);

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [highlightTitle, setHighlightTitle] = useState(false);

  const [primaryMessage, setPrimaryMessage] = useState('');
  const [primaryMessageError, setPrimaryMessageError] = useState('');
  const [highlightPrimaryMessage, setHighlightPrimaryMessage] = useState(false);

  const [secondaryMessage, setSecondaryMessage] = useState('');
  const [secondaryMessageError, setSecondaryMessageError] = useState('');
  const [highlightSecondaryMessage, setHighlightSecondaryMessage] = useState(false);

  const [postSubmissionThankYouMessage, setPostSubmissionThankYouMessage] = useState('');
  const [postSubmissionThankYouMessageError, setPostSubmissionThankYouMessageError] = useState('');
  const [highlightPostSubmissionThankYouMessage, setHighlightPostSubmissionThankYouMessage] = useState(false);

  const [primaryQuestionType, setPrimaryQuestionType] = useState('');
  const [primaryQuestionOptions, setPrimaryQuestionOptions] = useState([]);

  const [primaryQuestion, setPrimaryQuestion] = useState('');
  const [primaryQuestionError, setPrimaryQuestionError] = useState('');
  const [highlightPrimaryQuestion, setHighlightPrimaryQuestion] = useState(false);


  const [hotelMessage, setHotelMessage] = useState('');
  const [hotelMessageError, setHotelMessageError] = useState('');
  const [highlightHotelMessage, setHighlightHotelMessage] = useState(false);

  const [highlightSections, setHighlightSections] = useState({});

  const [additionalQuestions, setAdditionalQuestions] = useState([]);
  const [additionalQuestionsChanged, setAdditionalQuestionsChanged] = useState(false);

  const [additionalQuestionsError, setadditionalQuestionsError] = useState({});

  const [additionalQuestionsInputError, setAdditionalQuestionsInputError] = useState('');

  const [showAddQuestion, setShowAddQuestion] = useState(false);

  const [updatedValues, setUpdatedValues] = useState({});

  const [formChanged, setFormChanged] = useState(false);

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Feedbacks - Customize - Feedback Generic - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const clearAction = () => {
    const params = {};
    params.location__id = urlParams.locationidentifier;
    dispatch(editDataClearAction({
      op: 'edit_feedback_settings',
      params,
    }));
  };

  useEffect(() => {
    clearAction();
  }, []);

  useEffect(() => {
    const { reputation } = props.settings;
    if (reputation && reputation.feedback_settings) {
      const { feedback_settings } = reputation;
      if (feedback_settings) {
        if (!feedback_settings.editInProgress && feedback_settings.editStatus && feedback_settings.editStatus.success) {
          setUpdatedValues(updatedValues => ({
          }));
          setAdditionalQuestionsChanged(false);
          clearAction();
        }
      }
    }
  }, [props.settings])

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    const { reputation } = props.settings;
    if (!reputation || !reputation.feedback_settings) return null;
    const genericData = reputation.feedback_settings.generic || {};
    if (genericData.sections) {
      genericData.sectionsKeys = Object.keys(genericData.sections).sort((a, b) => genericData.sections[a].order - genericData.sections[b].order);
      genericData.sectionsKeys.forEach((sectionId, index) => {
        genericData.sections[sectionId].order = index+1;
      });
    }
    setGeneric(genericData);
    // setAQEnabled(feedback_settings[`${props.type}_questions_enabled`] || false);
    setTitle(genericData.title || '');
    setPrimaryMessage(genericData.primary_msg || '');
    setSecondaryMessage(genericData.secondary_msg || '');
    setPostSubmissionThankYouMessage(genericData.post_submission_thank_you_msg || '');
    setPrimaryQuestionType(genericData.primary_question ? genericData.primary_question.type || '' : '');
    let tOptions = [];
    tOptions.push({ value: 'nps_rating', label: 'NPS Rating' });
    tOptions.push({ value: 'smiley', label: 'Smiley' });
    tOptions.push({ value: 'stars', label: 'Stars' });
    setPrimaryQuestionOptions(tOptions);
    setPrimaryQuestion(genericData.primary_question ? genericData.primary_question.question || '' : '');
    // setHotelMessage(feedback_settings[`${props.type}_questions_hotel_message`] || '');
    // setAdditionalQuestions(feedback_settings[`${props.type}_questions`] || []);

    setFormChanged(false);
  };

  const onTitleChange = (e) => {
    const { name, value } = e.target;
    setTitle(value);
    setGeneric(generic => ({
      ...generic,
      title: value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      title: value,
    }));
    setFormChanged(true);
  };
  const onTitleMouseEnter = (e) => {
    setHighlightTitle(true);
  };
  const onTitleMouseLeave = (e) => {
    setHighlightTitle(false);
  };

  const onPrimaryMessageChange = (e) => {
    const { name, value } = e.target;
    setPrimaryMessage(value);
    setGeneric(generic => ({
      ...generic,
      primary_msg: value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      primary_msg: value,
    }));
    setFormChanged(true);
  };
  const onPrimaryMessageMouseEnter = (e) => {
    setHighlightPrimaryMessage(true);
  };
  const onPrimaryMessageMouseLeave = (e) => {
    setHighlightPrimaryMessage(false);
  };

  const onSecondaryMessageChange = (e) => {
    const { name, value } = e.target;
    setSecondaryMessage(value);
    setGeneric(generic => ({
      ...generic,
      secondary_msg: value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      secondary_msg: value,
    }));
    setFormChanged(true);
  };
  const onSecondaryMessageMouseEnter = (e) => {
    setHighlightSecondaryMessage(true);
  };
  const onSecondaryMessageMouseLeave = (e) => {
    setHighlightSecondaryMessage(false);
  };


  const onPostSubmissionThankYouMessageChange = (e) => {
    const { name, value } = e.target;
    setPostSubmissionThankYouMessage(value);
    setGeneric(generic => ({
      ...generic,
      post_submission_thank_you_msg: value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      post_submission_thank_you_msg: value,
    }));
    setFormChanged(true);
  };
  const onPostSubmissionThankYouMessageMouseEnter = (e) => {
    setHighlightPostSubmissionThankYouMessage(true);
  };
  const onPostSubmissionThankYouMessageMouseLeave = (e) => {
    setHighlightPostSubmissionThankYouMessage(false);
  };


  const onPrimaryQuestionTypeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
        console.log('onPrimaryQuestionTypeChange: ', value);
    }
    setPrimaryQuestionType(value.value);
    setGeneric(generic => ({
      ...generic,
      primary_question: {
        ...generic.primary_question,
        type: value.value,
      },
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      primary_question_type: value.value,
    }));
    setFormChanged(true);
  };

  const onPrimaryQuestionChange = (e) => {
    const { name, value } = e.target;
    setPrimaryQuestion(value);
    setGeneric(generic => ({
      ...generic,
      primary_question: {
        ...generic.primary_question,
        question: value,
      },
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      primary_question_question: value,
    }));
    setPrimaryQuestionError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onPrimaryQuestionMouseEnter = (e) => {
    setHighlightPrimaryQuestion(true);
  };
  const onPrimaryQuestionMouseLeave = (e) => {
    setHighlightPrimaryQuestion(false);
  };


  const onHotelMessageChange = (e) => {
    const { name, value } = e.target;
    setHotelMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      [`${props.type}_questions_hotel_message`]: value,
      // additional_hotel_message: value,
    }));
    // setHotelMessageError(value ? '' : 'Required');
    setFormChanged(true);
  };
  const onHotelMessageMouseEnter = (e) => {
    setHighlightHotelMessage(true);
  };
  const onHotelMessageMouseLeave = (e) => {
    setHighlightHotelMessage(false);
  };



  const onSectionOrderChange = (sectionId, direction) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSectionOrderChange: ', sectionId, direction);
    }
    let sections = Object.keys(generic.sections).sort((a, b) => generic.sections[a].order - generic.sections[b].order);
    let sectionIndex = sections.indexOf(sectionId);
    if (direction === 'up') {
      if (sectionIndex > 0) {
        sections[sectionIndex] = sections[sectionIndex - 1];
        sections[sectionIndex - 1] = sectionId;
      }
    } else if (direction === 'down') {
      if (sectionIndex < sections.length - 1) {
        sections[sectionIndex] = sections[sectionIndex + 1];
        sections[sectionIndex + 1] = sectionId;
      }
    }
    let sData = {};
    sections.forEach((section, index) => {
      sData[section] = { ...generic.sections[section], order: index };
    });
    setGeneric(generic => ({
      ...generic,
      sections: sData
    }));
    setFormChanged(true);
  };

  const onAddSection = () => {
    if (props.isReadOnly) return;

    let tempId = suid.rnd();
    setGeneric(generic => ({
      ...generic,
      sections: {
        ...generic.sections,
        [tempId]: {
          order: generic.sections ? Object.keys(generic.sections).length : 0,
          title: 'New Section',
          questions: {},
          add: true,
        }
      }
    }));
    setFormChanged(true);
  };

  const onRemoveSection = (sectionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onRemoveSection: ', sectionId);
    }
    let sData = { ...generic.sections };
    delete sData[sectionId];
    setGeneric(generic => ({
      ...generic,
      sections: sData
    }));
    setFormChanged(true);
  }

  const onSectionEnabledChange = (sectionId, enabled) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSectionEnabledChange: ', sectionId, enabled);
    }
    let sData = { ...generic.sections };
    sData[sectionId].enabled = enabled;
    setGeneric(generic => ({
      ...generic,
      sections: sData
    }));
    setFormChanged(true);
  }

  const onSectionDataChange = (sectionId, data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSectionDataChange: ', sectionId, data);
    }
    let sData = { ...generic.sections };
    sData[sectionId] = data;
    setGeneric(generic => ({
      ...generic,
      sections: sData
    }));
    setFormChanged(true);
  }

  const onSectionMouseEnter = (sectionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSectionMouseEnter: ', sectionId);
    }
    setHighlightSections(highlightSections => ({
      ...highlightSections,
      [sectionId]: true,
    }));
  }

  const onSectionMouseLeave = (sectionId) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSectionMouseLeave: ', sectionId);
    }
    setHighlightSections(highlightSections => ({
      ...highlightSections,
      [sectionId]: false,
    }));
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;

    // setTitleError(title ? '' : 'Required');
    // if (!title) hasError = true;

    if (hasError) return;

    const params = {};
    params.location__id = urlParams.locationidentifier;
    params.generic = generic;
    // if ('title' in updatedValues) params[`${props.type}_questions_title`] = updatedValues.title;
    // if (`${props.type}_questions_primary_message` in updatedValues) params[`${props.type}_questions_primary_message`] = updatedValues[`${props.type}_questions_primary_message`];
    // if (`${props.type}_questions_hotel_message` in updatedValues) params[`${props.type}_questions_hotel_message`] = updatedValues[`${props.type}_questions_hotel_message`];

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    dispatch(editDataAction({
      op: 'edit_feedback_settings',
      params,
      show_alert: true,
      alert_msg: 'Updated successfully',
      show_alert_timeout: 2000,
      show_error_alert: true,
      error_alert_msg: 'Error in updating',
    }));
    setFormChanged(false);
  };

  
  const renderTitle = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="title"
        title="Title"
        desc=""
        type="textarea"
        value={title}
        onChange={onTitleChange}
        error={titleError}
        onMEnter={onTitleMouseEnter}
        onMLeave={onTitleMouseLeave}
      />
    );
  };

  const renderPrimaryMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="primaryMessage"
        title="Thank You Message"
        desc=""
        type="textarea"
        value={primaryMessage}
        onChange={onPrimaryMessageChange}
        error={primaryMessageError}
        onMEnter={onPrimaryMessageMouseEnter}
        onMLeave={onPrimaryMessageMouseLeave}
      />
    );
  };

  const renderSecondaryMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="secondaryMessage"
        title="Secondary Thank You Message"
        desc=""
        type="textarea"
        value={secondaryMessage}
        onChange={onSecondaryMessageChange}
        error={secondaryMessageError}
        onMEnter={onSecondaryMessageMouseEnter}
        onMLeave={onSecondaryMessageMouseLeave}
      />
    );
  };

  const renderPostSubmissionThankYouMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="postSubmissionThankYouMessage"
        title="Post Submission Thank You Message"
        desc=""
        type="textarea"
        value={postSubmissionThankYouMessage}
        onChange={onPostSubmissionThankYouMessageChange}
        error={postSubmissionThankYouMessageError}
        onMEnter={onPostSubmissionThankYouMessageMouseEnter}
        onMLeave={onPostSubmissionThankYouMessageMouseLeave}
      />
    );
  };

  const renderPrimaryQuestion = () => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="primaryQuestion"
        title="Primary Question"
        desc=""
        type="textarea"
        value={primaryQuestion}
        onChange={onPrimaryQuestionChange}
        error={primaryQuestionError}
        onMEnter={onPrimaryQuestionMouseEnter}
        onMLeave={onPrimaryQuestionMouseLeave}
      />
    );
  };

  const renderHotelMessage = (feedback_settings) => {
    return (
      <GenericHotelDataSingle
        dataTypeCode="hotelMessage"
        title="Secondary Message"
        desc=""
        type="textarea"
        value={hotelMessage}
        onChange={onHotelMessageChange}
        error={hotelMessageError}
        onMEnter={onHotelMessageMouseEnter}
        onMLeave={onHotelMessageMouseLeave}
      />
    );
  };

  


  const renderSingleSelectQuestion = (selectOptions, questionValue, onQuestionAnswerChange, dataTypeCode = '') => {
    if (process.env.NODE_ENV !== 'production') {
        console.log('renderSingleSelectQuestion selectOptions: ', selectOptions, ' # questionValue: ', questionValue);
    }
    return (
      <div className={'gSettingsRow ' + (dataTypeCode || '')} onMouseEnter={onPrimaryQuestionMouseEnter} onMouseLeave={onPrimaryQuestionMouseLeave}>
        <div className="titleRow">
          <p>Choose primary question type</p>
        </div>
        <div className="contentRow">
          <div className="contentBox">
            <div className="gIpt">
              {selectOptions.map((el, index) => (
                  <div key={`opt-${el.value}`} className="opt">
                    <label htmlFor={`opt-${el.value}`}>{el.label}</label>
                    <input
                        type="radio"
                        id={`opt-${el.value}`}
                        name={`opt-${el.value}`}
                        value={el.value}
                        checked={questionValue === el.value}
                        onChange={() => onQuestionAnswerChange(el)}
                    />
                  </div>
              ))}
            </div>
          </div>
          <div className="contentBoxNA">
          </div>
        </div>
      </div>
    );
  };


  const renderSave = (feedback_settings) => {
    if (props.isReadOnly) {
      return (
        <div className="gBCtRw rdOlyRw stndAln">
          <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
        </div>
      );
    }
    return (
      <>
        {
          additionalQuestionsInputError &&
          <div className="gSettingsRow gErrRw">
            {additionalQuestionsInputError}
          </div>
        }
        <div className="gSettingsRow gSaveButton">
          <button
            type="submit"
            className={'gBtn' + (formChanged ? '' : ' disabled')}
          >
            Save Changes
            { feedback_settings.editInProgress && <i className="fa fa-spinner fa-spin" /> }
          </button>
        </div>
      </>
    );
  };

  const renderSettings = (feedback_settings) => {
    return (
      <>
        <form onSubmit={onSubmit}>
      {/* renderAdditionalQuestionsEnabled(feedback_settings) */}
          { renderTitle(feedback_settings) }
          { renderPrimaryMessage(feedback_settings) }
          { renderSecondaryMessage(feedback_settings) }
          <h3>Primary Question</h3>
          { renderSingleSelectQuestion(primaryQuestionOptions, primaryQuestionType, onPrimaryQuestionTypeChange, 'primaryQuestion') }
          { renderPrimaryQuestion(feedback_settings) }
          <h3>Sections</h3>
          {
            generic && generic.sections && Object.keys(generic.sections).sort((a, b) => generic.sections[a].order - generic.sections[b].order).map((item) => {
              return (
                <FeedbackSection
                  key={item}
                  sectionId={item}
                  sectionData={generic.sections[item]}
                  onSectionOrderChange={onSectionOrderChange}
                  onRemoveSection={onRemoveSection}
                  onSectionDataChange={onSectionDataChange}
                  onSectionEnabledChange={onSectionEnabledChange}
                  onSectionMouseEnter={onSectionMouseEnter}
                  onSectionMouseLeave={onSectionMouseLeave}
                />
              )
            })
          }
          <div className="adSctnBtn">
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
              overlay={"Add Section"}
            >
              <span onClick={onAddSection}>Add section<i className="fa fa-plus"></i></span>
            </ToolTipWrapper>
          </div>
          { renderPostSubmissionThankYouMessage(feedback_settings) }
          {/* { renderAdditionalQuestions(feedback_settings) } */}
          {/* <div className="adQuesBtn"><span onClick={onAddQuestion}>Add question</span></div> */}
          {/* { renderHotelMessage(feedback_settings) } */}
          { renderSave(feedback_settings) }
        </form>
      </>
    );
  };

  const renderPreview = (feedback_settings, engage_settings, location_info) => {
    return (
      <div className="emailPreviewWrap positiveFeedback">
        <p>Questions Screen Preview</p>
        {/*<div className="devicesBox">
          <span>Desktop</span>
          <span>Mobile</span>
        </div>*/}
        <div className="emailPreviewBox">
          <div className="emailBodyBox">
            <div className="headerBox">
              { engage_settings.hotel_logo_url && <img src={engage_settings.hotel_logo_url} /> }
              <p>{engage_settings.hotel_name || ''}</p>
            </div>
              <div className="middleBox">
                {
                  props.type === 'positive' &&
                  <p className={'positiveTitle'}><Interweave content={feedback_settings.positive_title} /></p>
                }
                <p className={'title' + (highlightTitle ? ' highlight' : '')}><Interweave content={generic.title} /></p>
                <p className={'primaryMessage' + (highlightPrimaryMessage ? ' highlight' : '')}><Interweave content={generic.primary_msg} /></p>
                <p className={'secondaryMessage' + (highlightSecondaryMessage ? ' highlight' : '')}><Interweave content={generic.secondary_msg} /></p>
                <div className={'prmQstnBx' + (highlightPrimaryQuestion ? ' highlight' : '')}>
                  { generic.primary_question && generic.primary_question.question && <p className="primaryQuestion">{generic.primary_question.question}</p> }
                  {
                    generic.primary_question && generic.primary_question.type === 'nps_rating' &&
                    <div className="iconRow nps">
                      <img src={gtconfig.FEEDBACK_SAD} />
                      {
                        Array(11).fill().map((el, idx) => {
                        return (<div className="npsRtngBx">{idx}</div>)
                        })
                      }
                      <img src={gtconfig.FEEDBACK_HAPPY} />
                    </div>
                  }
                  {
                    generic.primary_question && generic.primary_question.type === 'smiley' &&
                    <div className="iconRow nps">
                      <img src={gtconfig.FEEDBACK_SAD} />
                      <img src={gtconfig.FEEDBACK_HAPPY} />
                    </div>
                  }
                  {
                    generic.primary_question && generic.primary_question.type === 'stars' &&
                    <div className="iconRow">
                      <img src={starIcon} />
                      <img src={starIcon} />
                      <img src={starIcon} />
                      <img src={starIcon} />
                      <img src={starIcon} />
                    </div>
                  }
                </div>
                {
                  generic.sections && Object.keys(generic.sections).sort((a, b) => generic.sections[a].order - generic.sections[b].order).map((item) => {
                    return (
                      <div className={'section ' + (highlightSections[item] ? 'highlight' : '')}>
                        <p className="sectionTitle">{generic.sections[item].title}</p>
                        {
                          generic.sections[item].questions && Object.keys(generic.sections[item].questions).sort((a, b) => generic.sections[item].questions[a].order - generic.sections[item].questions[b].order).map((question) => {
                            return (
                              <div className="questionRow">
                                <p className="question">{generic.sections[item].questions[question].question}</p>
                                {
                                  generic.sections[item].questions[question].type === 'yes-no' &&
                                  <div className="radioOpts">
                                    <div><span className="psdRadio"></span>Yes</div>
                                    <div><span className="psdRadio"></span>No</div>
                                  </div>
                                }
                                {
                                  generic.sections[item].questions[question].type === 'out-of-5' &&
                                  <div className="radioOpts">
                                    <img src={gtconfig.FEEDBACK_SAD} />
                                    <div><span className="psdRadio"></span>1</div>
                                    <div><span className="psdRadio"></span>2</div>
                                    <div><span className="psdRadio"></span>3</div>
                                    <div><span className="psdRadio"></span>4</div>
                                    <div><span className="psdRadio"></span>5</div>
                                    <img src={gtconfig.FEEDBACK_HAPPY} />
                                  </div>
                                }
                                {
                                  generic.sections[item].questions[question].type === 'single-select' &&
                                  <div className="radioOpts single-select">
                                    {
                                      generic.sections[item].questions[question].options && Object.keys(generic.sections[item].questions[question].options).map((option) => {
                                        return (
                                          <div><span className="psdRadio"></span>{generic.sections[item].questions[question].options[option].label}</div>
                                        )
                                      })
                                    }
                                  </div>
                                }
                                {
                                  generic.sections[item].questions[question].type === 'multiple-out-of-5' &&
                                  <div className="radioOpts multiple-out-of-5">
                                    {
                                      generic.sections[item].questions[question].subquestions && Object.keys(generic.sections[item].questions[question].subquestions).map((subquestion) => {
                                        return (
                                          <div className="subquestion">
                                            <p>{generic.sections[item].questions[question].subquestions[subquestion].question}</p>
                                            <div className="subRadioOpts">
                                              <div><span className="psdRadio"></span>1</div>
                                              <div><span className="psdRadio"></span>2</div>
                                              <div><span className="psdRadio"></span>3</div>
                                              <div><span className="psdRadio"></span>4</div>
                                              <div><span className="psdRadio"></span>5</div>
                                            </div>
                                            {/* <div><span className="psdRadio"></span>{generic.sections[item].questions[question].options[option].label}</div> */}
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                }
                                {
                                  generic.sections[item].questions[question].type === 'text' &&
                                  <textarea readOnly={true} rows={3} />
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
                
                {
                  additionalQuestions.length
                  ?
                    additionalQuestions.map((item) => {
                      if (!item.is_enabled) return null;
                      return (
                        <div className={'additionalQuestion' + (item.highlight ? ' highlight' : '')}>
                          <p className={'question'}><Interweave content={item.question} /></p>
                          {
                            item.collect_star_rating &&
                            <div className="starBox">
                              <div className="stars">
                                <img className={'starIcon'} src={starIcon}></img>
                                <img className={'starIcon'} src={starIcon}></img>
                                <img className={'starIcon'} src={starIcon}></img>
                                <img className={'starIcon'} src={starIcon}></img>
                                <img className={'starIcon'} src={starIcon}></img>
                              </div>
                            </div>
                          }
                          {
                            item.collect_text_input &&
                            <textarea />
                          }
                        </div>
                      )
                    })
                  :
                    null
                }
                <p className={'postSubmissionThankYouMessage' + (highlightPostSubmissionThankYouMessage ? ' highlight' : '')}><Interweave content={generic.post_submission_thank_you_msg} /></p>
              </div>
            <div className="footerBox">
              <p className={'hotelMessage' + (highlightHotelMessage ? ' highlight' : '')}><Interweave content={hotelMessage} /></p>
              <p className="address">{engage_settings.hotel_address || ''}</p>
              <p className="contact">
                <span className="link">{engage_settings.hotel_number || ''}</span>
                <span className="link">{engage_settings.hotel_website || ''}</span>
              </p>
              {
                gtconfig.IS_GT &&
                <p className="poweredBy">
                  Powered by <span className="link">{gtconfig.APP_NAME_CONTACT}</span>
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = (feedback_settings, engage_settings, location_info) => {
    return (
        <div className="innerBox">
          <div className="topBox">
            <div className="titleBox">
              <h4>Feedback Generic{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}</h4>
            </div>
          </div>
          <div className="contentBox">
            <div className="left">
              { renderSettings(feedback_settings) }
            </div>
            <div className="right">
              { renderPreview(feedback_settings, engage_settings, location_info) }
            </div>
          </div>
        </div>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('FeedbackGeneric props: ', props);
    console.log('FeedbackGeneric generic: ', generic);
    console.log('FeedbackGeneric additionalQuestionsError: ', additionalQuestionsError);
    console.log('FeedbackGeneric updatedValues: ', updatedValues);
  }

  const { locationInfo } = props;
  const { reputation, engage } = props.settings;
  if (!reputation || !reputation.feedback_settings) return null;
  const { feedback_settings } = reputation;

  return (
    <div className="gPScrlWrp flHt mk">
      <div className="gPg fdbPosPg fdbGnrcPg">
        { renderContent(feedback_settings, engage || {}, locationInfo) }
      </div>
    </div>
  );

}

export default FeedbackGeneric;
