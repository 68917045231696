import axios from 'axios';

import { auth } from '../../firebase';
import { sendEmailAlert } from '../../helpers';

const gtconfig = require('../../gtconfig');

const opMap = {
  'edit_profile': 'EDIT_PROFILE',
  'edit_self_change_password': 'EDIT_SELF_PASSWORD',

  'edit_user_for_location': 'EDIT_USER_FOR_LOCATION',
  'edit_user_reset_password': 'EDIT_USER_RESET_PASSWORD',
  'edit_user_bookmarks': 'EDIT_USER_BOOKMARKS',

  'edit_message_template': 'EDIT_MESSAGE_TEMPLATE',
  'edit_settings': 'EDIT_SETTINGS',
  'edit_webchat_settings': 'EDIT_WEBCHAT_SETTINGS',
  'edit_webchat_helpguide': 'EDIT_WEBCHAT_HELPGUIDE',
  'edit_webchat_rule': 'EDIT_WEBCHAT_RULE',
  'edit_digital_guide_page': 'EDIT_DIGITAL_GUIDE_PAGE',
  'edit_digital_guide_room': 'EDIT_DIGITAL_GUIDE_ROOM',
  'edit_gbm_settings': 'EDIT_GBM_SETTINGS',
  'edit_ai_model_data': 'EDIT_AI_MODEL_DATA',
  'refresh_remote_locks_list': 'REFRESH_REMOTE_LOCKS_LIST',
  'add_guest': 'ADD_GUEST',
  'edit_guest': 'EDIT_GUEST',
  'edit_outside_guest': 'EDIT_OUTSIDE_GUEST',
  'add_outside_guest': 'ADD_OUTSIDE_GUEST',
  'mark_message_as_read': 'MARK_MESSAGE_AS_READ',
  'edit_guest_list_display_preferences': 'EDIT_GUEST_LIST_DISPLAY_PREFERENCES',
  'add_whatsapp_message_template': 'ADD_WHATSAPP_MESSAGE_TEMPLATE',
  'edit_guest_journey_rule': 'EDIT_GUEST_JOURNEY_RULE',
  'edit_engage_guest_label': 'EDIT_ENGAGE_GUEST_LABEL',
  'edit_room': 'EDIT_ROOM',
  'edit_rate_code': 'EDIT_RATE_CODE',
  'edit_booking_source': 'EDIT_BOOKING_SOURCE',
  'edit_sender_name': 'EDIT_SENDER_NAME',
  'edit_sender_email': 'EDIT_SENDER_EMAIL',
  'send_sender_email_verification_email': 'SEND_SENDER_EMAIL_VERIFICATION_EMAIL',
  'edit_broadcast': 'EDIT_BROADCAST',
  'send_message': 'SEND_MESSAGE',
  'send_message_retry': 'SEND_MESSAGE_RETRY',
  'send_message_reaction': 'SEND_MESSAGE_REACTION',
  
  'edit_group': 'EDIT_GROUP',
  'share_group': 'SHARE_GROUP',
  'remove_shared_group': 'REMOVE_SHARED_GROUP',
  'edit_review_channels': 'EDIT_REVIEW_CHANNELS',
  'edit_reputation_alerts': 'EDIT_REPUTATION_ALERTS',
  'edit_reputation_reports': 'EDIT_REPUTATION_REPORTS',
  'send_reputation_report': 'SEND_REPUTATION_REPORT',
  'edit_reputation_auto_responses': 'EDIT_REPUTATION_AUTO_RESPONSES',
  'edit_response_template': 'EDIT_RESPONSE_TEMPLATE',
  'edit_response_template_category': 'EDIT_RESPONSE_TEMPLATE_CATEGORY',
  'edit_prompt_template': 'EDIT_PROMPT_TEMPLATE',
  'edit_prompt_template_category': 'EDIT_PROMPT_TEMPLATE_CATEGORY',
  'edit_reviews_widget_settings': 'EDIT_REVIEWS_WIDGET_SETTINGS',
  'edit_reviews_widget_rule': 'EDIT_REVIEWS_WIDGET_RULE',
  'edit_review': 'EDIT_REVIEW',
  'get_response': 'GET_RESPONSE',
  'update_review_translation': 'UPDATE_REVIEW_TRANSLATION',
  'post_response_on_google': 'POST_RESPONSE_ON_GOOGLE',
  'post_response_on_expedia': 'POST_RESPONSE_ON_EXPEDIA',
  'edit_feedback_text_template': 'EDIT_FEEDBACK_TEXT_TEMPLATE',
  'send_instant_invite_feedback_request': 'SEND_INSTANT_INVITE_FEEDBACK_REQUEST',
  'edit_comp_set_location': 'EDIT_COMP_SET_LOCATION',
 
  'edit_group_review': 'EDIT_GROUP_REVIEW',
  'update_group_review_translation': 'UPDATE_GROUP_REVIEW_TRANSLATION',
  'get_group_response': 'GET_GROUP_RESPONSE',
  'post_group_response_on_google': 'POST_GROUP_RESPONSE_ON_GOOGLE',
  'post_group_response_on_expedia': 'POST_GROUP_RESPONSE_ON_EXPEDIA',
  'add_email_marketing_campaign': 'ADD_EMAIL_MARKETING_CAMPAIGN',
  'edit_email_marketing_campaign': 'EDIT_EMAIL_MARKETING_CAMPAIGN',
  'edit_email_marketing_segment': 'EDIT_EMAIL_MARKETING_SEGMENT',
  'edit_em_email_template': 'EDIT_EM_EMAIL_TEMPLATE',
  
  'edit_email_template': 'EDIT_EMAIL_TEMPLATE',
  'edit_transaction_email': 'EDIT_TRANSACTION_EMAIL',
  'email_template_send_test_email': 'EMAIL_TEMPLATE_SEND_TEST_EMAIL',
  'resend_transaction_email': 'RESEND_TRANSACTION_EMAIL',
  'send_custom_transaction_email': 'SEND_CUSTOM_TRANSACTION_EMAIL',

  'edit_rg_location': 'EDIT_RG_LOCATION',
  'add_rg_location': 'ADD_RG_LOCATION',
  'edit_rg_prompt_template': 'EDIT_RG_PROMPT_TEMPLATE',
  'edit_rg_user': 'EDIT_RG_USER',
  'edit_rg_user_reset_password': 'EDIT_RG_USER_RESET_PASSWORD',

  'upload_feedback_emails': 'UPLOAD_FEEDBACK_EMAILS',
  'edit_feedback_settings': 'EDIT_FEEDBACK_SETTINGS',
  'send_test_email_custom_feedback': 'SEND_TEST_EMAIL_CUSTOM_FEEDBACK',
  'get_response_for_feedback': 'GET_RESPONSE_FOR_FEEDBACK',
  'send_custom_feedback_reply': 'SEND_CUSTOM_FEEDBACK_REPLY',

  'edit_intg_configure_data': 'EDIT_INTG_CONFIGURE_DATA',
};

var lastOpTimeStamp = new Date().getTime();

/* ********************** Start - Edit Data *********************************** */

export function editDataClearAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: `${opMap[payload.op]}_CLEAR`,
      payload,
    });
  };
}

export function editDataAction(payload) {
  return function somename(dispatch, getState) {

    if (process.env.NODE_ENV !== 'production') {
      console.log(`editDataAction ${payload.op} payload: ${JSON.stringify(payload)}`);
      console.log(`editDataAction lastOpTimeStamp: ${lastOpTimeStamp}`);
    }

    const { op, params, clear, init } = payload;

    if (init) {
      dispatch({
        type: `INIT_${opMap[payload.op].split('EDIT_')[1]}`,
        payload,
      });
    }

    dispatch({
      type: opMap[payload.op],
      payload,
    });



    if (clear) {
      dispatch({
        type: `${opMap[payload.op]}_CLEAR`,
        payload,
      });
    }

    let tDiff = new Date().getTime() - lastOpTimeStamp
    if (process.env.NODE_ENV !== 'production') {
      console.log(`editDataAction tDiff: ${tDiff}`);
    }
    let getToken = false;
    if (tDiff > 30 * 1000) getToken = true;
    if (process.env.NODE_ENV !== 'production') {
      console.log(`editDataAction tDiff getToken: ${getToken}`);
    }

    auth.currentUser
      .getIdToken(false)
      .then((idToken) => {
        lastOpTimeStamp = new Date().getTime();
        const url = gtconfig.DATA_BACKEND_URL;
        if (process.env.NODE_ENV !== 'production') {
          console.log(`editDataAction ${payload.op} url: `, url);
        }
        const opdata = {};
        opdata.params = {
          ...params,
          relogin_identifier: window.gtCId,
        };
        const postData = {
          appId: gtconfig.APP_ID,
          idToken,
          op, // 'get_guest_list',
          opdata,
        };

        // try {
        //   const t1 = localStorage.getItem('nktest');
        //   if (process.env.NODE_ENV !== 'production') {
        //     console.log(`getDataAction t1: `, t1);
        //   }
        //   if (t1) {
        //     postData.c1 = t1;
        //   }
        // } catch (e) {
        //   if (process.env.NODE_ENV !== 'production') {
        //     console.log(`getDataAction localStorage e: `, e);
        //   }
        // }

        if (process.env.NODE_ENV !== 'production') {
          console.log(`editDataAction ${payload.op} postData: `, postData);
        }

        const headers = {
          'Content-type': 'application/json',
          'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
        };
        // const axiosData = {
        //   gtp: CryptoJS.AES.encrypt(JSON.stringify(postData), gtconfig.DATA_BACKEND_URL_GT_BASIC_KEY).toString(),
        // };
        const axiosData = {
          gtps: postData,
          M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
        };

        axios
          .post(url, axiosData, { headers, timeout: 30000 })
          .then((response) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`editDataAction ${payload.op} response: `, response.data);
            }
            const { status } = response.data;
            const { data } = response.data;
            // if (status === 'success' && data) {
            //   dispatch({
            //     type: 'SET_VERSION',
            //     payload: { data, params },
            //   });
            // }
            if (status === 'success' && data) {
              if (data.app_info && data.app_info.origin === window.location.origin) {
                const id = (new Date()).getTime();
                let msg = payload.alert_msg || 'Updated successfully';
                if (params.mode === 'remove') {
                  msg = 'Deleted successfully';
                }
                if (payload.show_alert || params.mode === 'remove') {
                  dispatch({
                    type: 'RECEIVED_NEW_SNACK',
                    payload: {
                      snack: {
                        id,
                        msg,
                      },
                    },
                  });
                  // setTimeout(() => {
                  //   dispatch({ type: 'REMOVE_SNACK', payload: { id } });
                  // }, 2000);
                  if (payload.show_alert_timeout) {
                    setTimeout(() => {
                      dispatch({ type: 'REMOVE_SNACK', payload: { id } });
                    }, payload.show_alert_timeout);
                  }
                }
                dispatch({
                  type: `${opMap[payload.op]}_SUCCESS`,
                  payload: {
                    data,
                    params,
                  },
                });
              } else { // logout
                auth.signOut().then(
                  () => {
                    if (process.env.NODE_ENV !== 'production') {
                      console.log('App Info Signed Out'); // comment
                    }
                    dispatch({
                      type: 'USER_LOGOUT',
                      payload,
                    });
                  },
                  (error) => {
                    if (process.env.NODE_ENV !== 'production') {
                      console.error('App Info Sign Out Error', error); // comment
                    }
                  },
                );
              }
            } else {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`editDataAction ${payload.op} error: `, response);
              }
              sendEmailAlert({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - editDataAction(${payload.op || ''}) Error - ${auth.currentUser.uid}`,
                message: JSON.stringify({ postData, responseData: response.data }),
                error: JSON.stringify(response.data),
                // store: JSON.stringify(getState()),
              });
              dispatch({
                type: `${opMap[payload.op]}_ERROR`,
                payload: {
                  data,
                  params,
                },
              });
              if (payload.show_error_alert) {
                const id = (new Date()).getTime();
                let msg = payload.error_alert_msg || 'Error in updating';
                dispatch({
                  type: 'RECEIVED_NEW_SNACK',
                  payload: {
                    snack: {
                      id,
                      msg,
                    },
                  },
                });
              }
            }
          })
          .catch((err) => {
            // axios error
            if (process.env.NODE_ENV !== 'production') {
              console.log(`editDataAction ${payload.op} err--------------------: `, err);
              console.log(`editDataAction ${payload.op} err.response--------------------: `, err.response);
            }
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - editDataAction(${payload.op || ''}) Error - axios - ${auth.currentUser.uid}`,
              message: JSON.stringify({ url, postData }),
              error: JSON.stringify({ err , config: err.config }),
              // store: JSON.stringify(getState()),
            });
            dispatch({
              type: `${opMap[payload.op]}_ERROR`,
              payload: {
                params,
                error: err,
                errorMessage: err.toString(),
              },
            });
          })
          .catch((err) => {
          // firebase error
            if (process.env.NODE_ENV !== 'production') {
              console.log(`editDataAction ${payload.op} firebase err--------------------: ${err}`);
            }
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - editDataAction(${payload.op || ''}) Error - firebase`,
              message: 'Firebase error',
              error: err.toString(),
              // store: JSON.stringify(getState()),
            });
            dispatch({
              type: `${opMap[payload.op]}_ERROR`,
              payload: {
                ...payload,
                errorMessage: err.toString(),
              },
            });
          });
      });
  };
}

/* ***************************  End - Edit Data  ****************************** */
