import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import SegmentSingle from './SegmentSingle';
import SegmentData from './SegmentData';
import GenericAlertModal from '../../../Misc/GenericAlertModal';
import { getDataAction } from '../../../../redux/actions/getDataActions';
import { editDataAction } from '../../../../redux/actions/editDataActions';
import { segmentTargetToLabel, STRS, formatDate, getSegmentNumberOfNightsLabel } from '../../../../helpers';

import editIcon from '../../../../img/icons/edit.svg';
import deleteIcon from '../../../../img/icons/delete.svg';

import './style.css';

const gtconfig = require('../../../../gtconfig');

const modalRoot = document.getElementById('modal-root-alert');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function SegmentCard(props) {
    let navigate = useNavigate();
    let urlParams = useParams();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const { _id, name, target, start_date_yyyymmdd, end_date_yyyymmdd, number_of_nights, date_created, data } = props.segment;
    const onEditClick = () => {
        props.onEditClick(props.segment);
    }
    const onShowDataClick = () => {
        props.onShowDataClick(props.segment);
    }
    const onDeleteClick = (e) => {
        setShowDeleteModal(true);
    };
    const onShowDeleteModal = (e) => {
        setShowDeleteModal(false);
    }
    const onDeleteConfirmClick = (e) => {
        props.onDeleteClick(props.segment);
    }

    return (
        <div className="gCrd emSegCrd" key={_id}>
            <div className="lt">
                <h4>{name}</h4>
                <p className="emCmgnCrdTtlVal">{segmentTargetToLabel[target]}</p>
                { start_date_yyyymmdd && end_date_yyyymmdd && <p className="emCmgnCrdTtlVal">{formatDate(moment(start_date_yyyymmdd, 'YYYY-MM-DD'))} to {formatDate(moment(end_date_yyyymmdd, 'YYYY-MM-DD'))}</p> }
                { number_of_nights && <p className="emCmgnCrdTtlVal">{getSegmentNumberOfNightsLabel(number_of_nights)}</p> }
                <p className="emCmgnCrdTtlVal">Number of guests: { data && data.count ? data.count : 0 }</p>
            </div>
            <div className="cm">
                <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"Show segment guests"}>
                    <div className="edit" onClick={onShowDataClick}>
                        <i className="fa-solid fa-table-list"></i>
                    </div>
                </ToolTipWrapper>
                <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"Edit segment"}>
                    <div className="edit" onClick={onEditClick}>
                        <img src={editIcon} />
                    </div>
                </ToolTipWrapper>
                {
                !props.isReadOnly &&
                <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"Delete segment"}>
                    <div className="delete" onClick={onDeleteClick}>
                        <img src={deleteIcon} />
                    </div>
                </ToolTipWrapper>
                }
            </div>
            {
            showDeleteModal &&
            <GenericAlertModal
              modalType="deleteBroadcast"
              bodyRowOne={`Do you want to delete "${name}" segment ?`}
              setModalVisible={onShowDeleteModal}
              onAffirmativeClick={onDeleteConfirmClick}
              inProgress={props.segment.removeInProgress}
              showCancel={true}
            />
          }
        </div>
    )
}

function Segments(props) {
    const windowState = useSelector((state) => state.windowReducer);
    const dispatch = useDispatch();
    let urlParams = useParams();

    const [selectedSegmentId, setSelectedSegmentId] = useState(null);
    const [showSegmentSingle, setShowSegmentSingle] = useState(false);
    const [showSegmentData, setShowSegmentData] = useState(false);

    useEffect(() => {
        dispatch(getDataAction({
          op: 'get_email_marketing_segments',
          params: {
            location__id: urlParams.locationidentifier,
            // getEmailTemplates: true,
            // getRooms: true,
            // getRateCodes: true,
            // getBookingSources: true,
            // getSenderNames: true,
            // getSenderEmails: true,
            // getGuestLangs: true,
          },
        }));
    }, [urlParams.locationidentifier]); // urlParams.locationidentifier

    const onAddClick = () => {
        setSelectedSegmentId(null);
        setShowSegmentSingle(true);
    }

    const onEditClick = (segment) => {
        setSelectedSegmentId(segment._id);
        setShowSegmentSingle(true);
    }
    const onDeleteClick = (segment) => {
        dispatch(editDataAction({
            op: 'edit_email_marketing_segment',
            params: {
                location__id: urlParams.locationidentifier,
                mode: 'remove',
                segment_id: segment._id,
            },
            show_alert: true,
            alert_msg: 'Deleted successfully',
        }));
    }

    const onSegmentSingleCloseClick = (e) => {
        setShowSegmentSingle(false);
    };

    const onShowDataClick = (segment) => {
        setSelectedSegmentId(segment._id);
        setShowSegmentData(true);
    }
    const onSegmentDataCloseClick = (e) => {
        setShowSegmentData(false);
    }

    const renderSegmentSingleModal = () => {
        return (
          <Modal>
              <div id="cModelId" className="sdMdl sgmSnglMdl">
                <div className="ctnt">
                  <SegmentSingle
                    onCloseClick={onSegmentSingleCloseClick}
                    mode={selectedSegmentId ? 'edit' : 'add'}
                    segmentId={selectedSegmentId}
                    settings={props.settings}
                    locationInfo={props.locationInfo}
                    windowState={windowState}
                    isReadOnly={props.isReadOnly}
                    segments={props.marketing.emailMarketing.segments || {}}
                  />
                </div>
              </div>
            </Modal>
        );
      };

      const renderSegmentDataModal = () => {
        return (
          <Modal>
              <div id="cModelId" className="sdMdl sgmDataMdl">
                <div className="ctnt">
                  <SegmentData
                    onCloseClick={onSegmentDataCloseClick}
                    segmentId={selectedSegmentId}
                    settings={props.settings}
                    windowState={windowState}
                    isReadOnly={props.isReadOnly}
                    segments={props.marketing.emailMarketing.segments || {}}
                  />
                </div>
              </div>
            </Modal>
        );
      };

    if (process.env.NODE_ENV !== 'production') {
        console.log('Segments urlParams: ', urlParams);
        console.log('Segments props: ', props);
    }
    
    const { emailMarketing } = props.marketing;
    if (!emailMarketing || !emailMarketing.segments) return null;
    const { segments } = emailMarketing;

    return (
        <>
        {/*
            reviews.getInProgress &&
            <div className="gPnlLdng">
            <i className="fa fa-spinner fa-spin" />
            </div>
        */}
        {
            segments && segments.getInProgress &&
            <div className="gPnlSplLdng">
            <div className="innerWrap">
                <i className="fa fa-spinner fa-spin" />
                <img src={gtconfig.REPUTATION_LOADER_URL} />
            </div>
            </div>
        }
        <div className="gPScrlWrp flHt mk">
            <div className="gPg segsPg">
                <h4 className="gPageWrapOuterTitle title">
                Email Marketing Segments{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
                {
                    !props.isReadOnly &&
                    <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"Add new segment"}>
                        <span className="add" onClick={onAddClick}><i className="fa fa-plus-circle" /></span>
                    </ToolTipWrapper>
                }
                </h4>
                {
                    segments.list && segments.list.length > 0 && segments.keyValue &&
                    segments.list.map((segmentId) => {
                        if (!segments.keyValue[segmentId]) return null;
                        return <SegmentCard 
                            onEditClick={onEditClick}
                            onDeleteClick={onDeleteClick}
                            onShowDataClick={onShowDataClick}
                            segment={segments.keyValue[segmentId]} />;
                    })
                }
            </div>
        </div>
        { showSegmentSingle && renderSegmentSingleModal() }
        { showSegmentData && renderSegmentDataModal() }
        </>
    );
}
export default Segments;
