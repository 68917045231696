// @flow

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import { Button, DatePicker } from 'antd';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';
import { getDataAction } from '../../../../../redux/actions/getDataActions';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import EMStatCard from '../../../../Misc/EMStatCard';
import CustomTimePicker from '../../../../Misc/CustomTimePicker';

import { formatDate, numberOfNights, COUNTRY_LIST_KEYS, DEVICE_TYPE_CODE_TO_LABEL,
  EM_TASK_STATUS_TO_LABEL, getPercentage, STRS } from '../../../../../helpers';

import closeIcon from '../../../../../img/icons/close.svg';
import requiredIcon from '../../../../../img/icons/required.svg';
import oneTimeIcon from '../../../../../img/icons/broadcast-onetime.svg';
import oneTimeActiveIcon from '../../../../../img/icons/broadcast-onetime-active.svg';
import recurringIcon from '../../../../../img/icons/broadcast-recurring.svg';
import recurringActiveIcon from '../../../../../img/icons/broadcast-recurring-active.svg';

import './style.css';
import MixedCard from "../../../../Misc/MixedCard";

const gtconfig = require('../../../../../gtconfig');

const emailStatusOptions = [];
emailStatusOptions.push({ label: 'Clicked', value: 'clicked' });
emailStatusOptions.push({ label: 'Opened', value: 'opened' });
emailStatusOptions.push({ label: 'Unopened', value: 'unopened' });
emailStatusOptions.push({ label: 'Unsubscribed', value: 'unsubscribed' });

function CampaignStatusBtn(props) {
  const { label, value } = props.option;
  const { selectedEmailStatus, onEmailStatusChange } = props;
  return (
    <div className={`cmpgStsBtn ${selectedEmailStatus === value ? 'active' : ''}`} onClick={() => onEmailStatusChange(selectedEmailStatus === value ? null : value)}>
      <span className="cmpgSts">{label}</span>
    </div>
  );
}

function CampaignData(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  const location = useLocation();

  const [selectedEmailStatus, setSelectedEmailStatus] = useState(null);

  useEffect(() => {
    dispatch(getDataAction({
      op: 'get_email_marketing_campaign_data',
      params: {
        location__id: urlParams.locationidentifier,
        em_campaign_id: props.campaignId,
      },
    }));
  }, [props.campaignId]);

  const onCloseClick = (e) => {
    props.onCloseClick(e);
  };
  
  
  const renderContent = (em_tasks) => {
    return (
      <>
        { em_tasks &&
          <div className="cmpgStatsBx">
            <EMStatCard
              data={{
                label: 'Total Sent',
                value: em_tasks.total_sent,
                labelToolTip: 'Total Sent',
                slabel: 'Delivered',
                svalue: em_tasks.total_delivered,
                ctaLabel: '',
              }}
            />
            <EMStatCard
              data={{
                label: 'Open Rate',
                value: getPercentage(em_tasks.total_opened, em_tasks.total_delivered),
                slabel: 'Unique Opens',
                svalue: em_tasks.total_opened,
                labelToolTip: 'Open Rate',
                ctaLabel: '',
              }}
            />
            <EMStatCard
              data={{
                label: 'Click Through Rate',
                value: getPercentage(em_tasks.total_clicked, em_tasks.total_delivered),
                labelToolTip: 'Click Through Rate',
                slabel: 'Unique Clicks',
                svalue: em_tasks.total_clicked,
                ctaLabel: '',
              }}
            />
            <EMStatCard
              data={{
                label: 'Unsubscribe Rate',
                value: em_tasks.total_unsubscribed,
                labelToolTip: 'Unsubscribe Rate',
                slabel: 'Total Unsubscribed',
                svalue: em_tasks.total_unsubscribed,
                ctaLabel: '',
              }}
            />
          </div>
        }
        <div className="cmpgStsBx">
          {
            emailStatusOptions.map((option) => {
              return (
                <CampaignStatusBtn option={option} selectedEmailStatus={selectedEmailStatus} onEmailStatusChange={setSelectedEmailStatus} />
              )
            })
          }
        </div>
        <div className="sgmDataBx">
          <div className="dataRw hdr">
            <div className="dtPrsnl">
              <span className="dtNm">Name / Check-In / Check-Out / Nights</span>
            </div>
            <span className="dtEml">Email</span>
            <span className="dtEmlStatus">Status</span>
            <span className="dtTs">Timestamp</span>
            <span className="dtCtry">Country/Device</span>
            <span className="dtClicks">Clicks</span>
          </div>
          {
            em_tasks && em_tasks.list && em_tasks.list.length && em_tasks.keyValue &&
            em_tasks.list.map((taskId) => {
              const item = em_tasks.keyValue[taskId];
              if (!item) return null;
              const { email, guest_info, cur_status, cur_timestamp, device_email_open, ip_country_email_open, clicks } = item;
              if (!guest_info) return null;
              if (selectedEmailStatus && selectedEmailStatus !== cur_status) return null;
              return (
                <div className="dataRw" key={taskId}>
                  <div className="dtPrsnl">
                    <span className="dtNm">{guest_info.first_name} {guest_info.last_name}</span>
                    <span className="dtChkIn">{formatDate(guest_info.check_in_date)} - {formatDate(guest_info.check_out_date)}</span>
                    <span className="dtNights">Number of nights: {numberOfNights(guest_info.check_out_date, guest_info.check_in_date)}</span>
                  </div>
                  <span className="dtEml">{email}</span>
                  <span className="dtEmlStatus">{cur_status ? EM_TASK_STATUS_TO_LABEL[cur_status] || '' : 'Unopened'}</span>
                  <span className="dtTs">{cur_timestamp}</span>
                  <span className="dtCtry">
                    { ip_country_email_open &&
                      <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={COUNTRY_LIST_KEYS[ip_country_email_open]}>
                        <div className="ipCountry">{ip_country_email_open}</div>
                      </ToolTipWrapper>
                    }
                    { device_email_open &&
                      <ToolTipWrapper placement="top" overlayClassName={'sbTooltip maxWidth'}
                        overlay={DEVICE_TYPE_CODE_TO_LABEL[device_email_open]}
                      >
                        <div className="device"><span><i className={`fas fa-${device_email_open}-alt`}></i></span></div>
                      </ToolTipWrapper>
                    }
                  </span>
                  <div className="dtClicks">
                    {clicks && clicks.length &&
                      clicks.map((click, idx) => {
                        return (
                          <span key={`clck-${idx}-${taskId}`}>{click.url}</span>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('CampaignData props: ', props);
  }

  const { campaignId, campaigns, segments } = props;
  if (!campaigns) return null;
  const campaign = campaigns.keyValue[campaignId];
  if (!campaign) return null;
  const { em_tasks } = campaign;
  const segmentId = campaign.segment_id;

  const segmentInfo = segments && segments.keyValue && segmentId && segments.keyValue[segmentId];

  return (
    <>
      {
          campaign && campaign.getDataInProgress &&
          <div className="gPnlSplLdng">
          <div className="innerWrap">
              <i className="fa fa-spinner fa-spin" />
              <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
          </div>
      }
      <div className="gPWrp">
        <div className="gPTle title">
          {campaign.name} - Interactions {props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
          <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
        </div>
        {
          segmentInfo && segmentInfo.name &&
          <div className="gPSubTle title">
            Segment: {segmentInfo.name} ( {segmentInfo.data.count} guests )
          </div>
        }
        <div className="gBCtnt">
          { renderContent(em_tasks) }
        </div>
      </div>
    </>
  );
}

export default CampaignData;
