import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';
import Dropzone, {useDropzone} from 'react-dropzone';
import axios from 'axios';

import PreDefinedMessage from './PreDefinedMessage';
// import LocationWrapper from './LocationWrapper';
import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import ImagePreviewModal from '../../../Misc/ImagePreviewModal';
import GenericSideModal from '../../../Misc/GenericSideModal';

import AddWhatsappTemplate from '../../../Settings/Templates/AddWhatsappTemplate';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { MSGTYPE_STR_TO_MSGTYPE, getRoomLabel, STRS, getUserGAId } from '../../../../helpers';

import closeIcon from '../../../../img/icons/close.svg';

import './style.css';

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function JMWhatsapp(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [hasBookingMessage, setHasBookingMessage] = useState(false);
  const [bookingMessage, setBookingMessage] = useState({
    message: '',
    messageId: '',
    sendAfter: '',
  });

  const [hasPreArrivalMessage, setHasPreArrivalMessage] = useState(false);
  const [preArrivalMessage, setPreArrivalMessage] = useState({
    message: '',
    messageId: '',
    sendBefore: '',
    sendAt: '',
    sendAtError: '',
  });

  const [hasWelcomeMessage, setHasWelcomeMessage] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState({
    message: '',
    messageId: '',
    sendAfter: '',
  });

  const [hasRepeatWelcomeMessage, setHasRepeatWelcomeMessage] = useState(false);
  const [repeatWelcomeMessage, setRepeatWelcomeMessage] = useState({
    message: '',
    messageId: '',
    sendAfter: '',
  });

  const [hasDepartureMessage, setHasDepartureMessage] = useState(false);
  const [departureMessage, setDepartureMessage] = useState({
    message: '',
    messageId: '',
    sendAfter: '',
  });

  const [updatedValues, setUpdatedValues] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');

  const [msgTypeAddTemplateModal, setMsgTypeAddTemplateModal] = useState(false);
  const [selectedAddedTemplate, setSelectedAddedTemplate] = useState(null);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const addTemplateModalRef = useRef();

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Engage - Journey Messages - WhatsApp - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    let { settings: { engage }, rule } = props;
    if (!engage.whatsapp) return;
    let engageWhatsapp = { ...engage.whatsapp };
    if (rule) {
      engageWhatsapp = rule.attr && rule.attr.whatsapp ? { ...rule.attr.whatsapp } : {};
    }

    setHasBookingMessage(engageWhatsapp.has_booking_message || false);
    if (engageWhatsapp.booking_message_id && engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
      const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === engageWhatsapp.booking_message_id);
      if (foundMsg) {
        const pMsg = prepMessageFromTemplate(foundMsg);
        setBookingMessage(bookingMessage => ({
          ...bookingMessage,
          message: pMsg, // foundMsg.msg,
          messageId: engageWhatsapp.booking_message_id,
          sendAfter: engageWhatsapp.booking_message_send_after,
        }));
      }
    }

    setHasPreArrivalMessage(engageWhatsapp.has_pre_arrival_message || false);
    if (engageWhatsapp.pre_arrival_message_id && engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
      const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === engageWhatsapp.pre_arrival_message_id);
      if (foundMsg) {
        const pMsg = prepMessageFromTemplate(foundMsg); 
        setPreArrivalMessage(preArrivalMessage => ({
          ...preArrivalMessage,
          message: pMsg, // foundMsg.msg,
          messageId: engageWhatsapp.pre_arrival_message_id,
          sendBefore: engageWhatsapp.pre_arrival_message_send_before,
          sendAt: engageWhatsapp.pre_arrival_message_send_at_time ? moment(engageWhatsapp.pre_arrival_message_send_at_time, 'HH:mm') : '',
          sendAtError: '',
        }));
      }
    }

    setHasWelcomeMessage(engageWhatsapp.has_welcome_message || false);
    if (engageWhatsapp.welcome_message_id && engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
      const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === engageWhatsapp.welcome_message_id);
      if (foundMsg) {
        const pMsg = prepMessageFromTemplate(foundMsg);
        setWelcomeMessage(welcomeMessage => ({
          ...welcomeMessage,
          message: pMsg, // foundMsg.msg,
          messageId: engageWhatsapp.welcome_message_id,
          sendAfter: engageWhatsapp.welcome_message_send_after,
        }));
      }
    }

    setHasRepeatWelcomeMessage(engageWhatsapp.has_repeat_visitor_message || false);
    if (engageWhatsapp.repeat_visitor_message_id && engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
      const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === engageWhatsapp.repeat_visitor_message_id);
      if (foundMsg) {
        const pMsg = prepMessageFromTemplate(foundMsg);
        setRepeatWelcomeMessage(repeatWelcomeMessage => ({
          ...repeatWelcomeMessage,
          message: pMsg, // foundMsg.msg,
          messageId: engageWhatsapp.repeat_visitor_message_id,
          sendAfter: engageWhatsapp.repeat_visitor_message_send_after,
        }));
      }
    }

    setHasDepartureMessage(engageWhatsapp.has_departure_message || false);
    if (engageWhatsapp.departure_message_id && engage.approved_whatsapp_templates && engage.approved_whatsapp_templates.length) {
      const foundMsg = engage.approved_whatsapp_templates.find(el => el._id === engageWhatsapp.departure_message_id);
      if (foundMsg) {
        const pMsg = prepMessageFromTemplate(foundMsg);
        setDepartureMessage(departureMessage => ({
          ...departureMessage,
          message: pMsg, // foundMsg.msg,
          messageId: engageWhatsapp.departure_message_id,
          sendAfter: engageWhatsapp.departure_message_send_after,
        }));
      }
    }

    setFormChanged(false);
  };

  useEffect(() => {
    setInitialValues();
  }, []);

  useEffect(() => {
    setInitialValues();
  }, [
    props.settings.engage.whatsapp,
  ]);

  useEffect(() => {
    if (props.rule) {
      if (!props.rule.editInProgress && props.rule.editStatus && props.rule.editStatus.success) {
        setUpdatedValues({});
      }
    }
  }, [props.rule]);


  const prepMessageFromTemplate = (foundMsg) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('prepMessageFromTemplate foundMsg: ', foundMsg);
    }
    let pMsg = foundMsg.msg;
    try {
      if (foundMsg.meta_template && foundMsg.meta_template.components && foundMsg.meta_template.components.length) {
        pMsg = '';
        foundMsg.meta_template.components.forEach((part) => {
          if (part.text) {
            if (pMsg) { pMsg = `${pMsg}\n\n${part.text}`}
            else { pMsg = part.text; }
          }
        });
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('prepMessageFromTemplate e: ', e);
      }
    }
    return pMsg;
  };

  /******** Booking Message *************/

  const onHasBookingMessageChange = (value) => {
    setHasBookingMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_booking_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onBookingMessageSendAfterChange = (value) => {
    setBookingMessage(bookingMessage => ({
      ...bookingMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      booking_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onBookingMessageIdChange = (messageId) => {
    const { settings } = props;
    if (settings.engage.approved_whatsapp_templates && settings.engage.approved_whatsapp_templates.length) {
      const foundMsg = settings.engage.approved_whatsapp_templates.find(el => el._id === messageId);
      if (foundMsg) {
        const pMsg = prepMessageFromTemplate(foundMsg);
        setBookingMessage(bookingMessage => ({
          ...bookingMessage,
          messageId,
          message: pMsg,
        }));
      }
    }
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      booking_message_id: messageId,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };


  /******** Pre-Arrival Message *************/

  const onHasPreArrivalMessageChange = (value) => {
    setHasPreArrivalMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_pre_arrival_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageSendBeforeChange = (value) => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      sendBefore: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_send_before: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageSendAtTimeChange = (value) => {
    // console.log('onPreArrivalMessageSendAtTimeChange: ', moment(value).format('HH:mm'));
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      sendAt: new Date(moment(value)),
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_send_at_time: moment(value).format('HH:mm'),
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageSendAtErrorChange = (error) => {
    setPreArrivalMessage(preArrivalMessage => ({
      ...preArrivalMessage,
      sendAtError: error,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onPreArrivalMessageIdChange = (messageId) => {
    const { settings } = props;
    if (settings.engage.approved_whatsapp_templates && settings.engage.approved_whatsapp_templates.length) {
      const foundMsg = settings.engage.approved_whatsapp_templates.find(el => el._id === messageId);
      if (foundMsg) {
        const pMsg = prepMessageFromTemplate(foundMsg);
        setPreArrivalMessage(preArrivalMessage => ({
          ...preArrivalMessage,
          messageId,
          message: pMsg,
        }));
      }
    }
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      pre_arrival_message_id: messageId,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };


  /******** Welcome Message *************/

  const onHasWelcomeMessageChange = (value) => {
    setHasWelcomeMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_welcome_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onWelcomeMessageSendAfterChange = (value) => {
    setWelcomeMessage(welcomeMessage => ({
      ...welcomeMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      welcome_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onWelcomeMessageIdChange = (messageId) => {
    const { settings } = props;
    if (settings.engage.approved_whatsapp_templates && settings.engage.approved_whatsapp_templates.length) {
      const foundMsg = settings.engage.approved_whatsapp_templates.find(el => el._id === messageId);
      if (foundMsg) {
        const pMsg = prepMessageFromTemplate(foundMsg);
        setWelcomeMessage(welcomeMessage => ({
          ...welcomeMessage,
          messageId,
          message: pMsg,
        }));
      }
    }
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      welcome_message_id: messageId,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };


  /******** Repeat Welcome Message *************/

  const onHasRepeatWelcomeMessageChange = (value) => {
    setHasRepeatWelcomeMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_repeat_visitor_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onRepeatWelcomeMessageSendAfterChange = (value) => {
    setRepeatWelcomeMessage(repeatWelcomeMessage => ({
      ...repeatWelcomeMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      repeat_visitor_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onRepeatWelcomeMessageIdChange = (messageId) => {
    const { settings } = props;
    if (settings.engage.approved_whatsapp_templates && settings.engage.approved_whatsapp_templates.length) {
      const foundMsg = settings.engage.approved_whatsapp_templates.find(el => el._id === messageId);
      if (foundMsg) {
        const pMsg = prepMessageFromTemplate(foundMsg);
        setRepeatWelcomeMessage(repeatWelcomeMessage => ({
          ...repeatWelcomeMessage,
          messageId,
          message: pMsg,
        }));
      }
    }
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      repeat_visitor_message_id: messageId,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };

  /************* Departure Message *******************/

  const onHasDepartureMessageChange = (value) => {
    setHasDepartureMessage(value);
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      has_departure_message: value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onDepartureMessageSendAfterChange = (value) => {
    setDepartureMessage(departureMessage => ({
      ...departureMessage,
      sendAfter: value.value,
    }));
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      departure_message_send_after: value.value,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };
  const onDepartureMessageIdChange = (messageId) => {
    const { settings } = props;
    if (settings.engage.approved_whatsapp_templates && settings.engage.approved_whatsapp_templates.length) {
      const foundMsg = settings.engage.approved_whatsapp_templates.find(el => el._id === messageId);
      if (foundMsg) {
        const pMsg = prepMessageFromTemplate(foundMsg);
        setDepartureMessage(departureMessage => ({
          ...departureMessage,
          messageId,
          message: pMsg,
        }));
      }
    }
    setUpdatedValues(updatedValues => ({
      ...updatedValues,
      departure_message_id: messageId,
    }));
    setFormChanged(true);
    clearEditRuleData();
  };

  /************* End - Departure Message *******************/

  const clearEditRuleData = () => {
    if (props.rule) {
      dispatch(editDataClearAction({
        op: 'edit_guest_journey_rule',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'edit',
          rule_id: props.rule._id,
        },
      }));
    }
  };

  const onCloseClick = (e) => {
    props.onCancelClick('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    if (hasBookingMessage && (!bookingMessage.messageId || !bookingMessage.message || !bookingMessage.sendAfter)) { setCurrentStatus('error'); return; }
    if (hasPreArrivalMessage && (!preArrivalMessage.messageId || !preArrivalMessage.message || !preArrivalMessage.sendBefore || !preArrivalMessage.sendAt || preArrivalMessage.sendAtError)) { setCurrentStatus('error'); return; }
    if (hasWelcomeMessage && (!welcomeMessage.messageId || !welcomeMessage.message || !welcomeMessage.sendAfter)) { setCurrentStatus('error'); return; }
    if (hasRepeatWelcomeMessage && (!repeatWelcomeMessage.messageId || !repeatWelcomeMessage.message || !repeatWelcomeMessage.sendAfter)) { setCurrentStatus('error'); return; }
    if (hasDepartureMessage && (!departureMessage.messageId || !departureMessage.message || !departureMessage.sendAfter)) { setCurrentStatus('error'); return; }

    const params = {};
    // params.location__id = urlParams.locationidentifier;
    // params.source = 'engage';

    if ('has_booking_message' in updatedValues) params['has_booking_message'] = updatedValues.has_booking_message;
    if ('booking_message_id' in updatedValues) params['booking_message_id'] = updatedValues.booking_message_id;
    if ('booking_message_send_after' in updatedValues) params['booking_message_send_after'] = updatedValues.booking_message_send_after;
    if ('has_pre_arrival_message' in updatedValues) params['has_pre_arrival_message'] = updatedValues.has_pre_arrival_message;
    if ('pre_arrival_message_id' in updatedValues) params['pre_arrival_message_id'] = updatedValues.pre_arrival_message_id;
    if ('pre_arrival_message_send_before' in updatedValues) params['pre_arrival_message_send_before'] = updatedValues.pre_arrival_message_send_before;
    if ('pre_arrival_message_send_at_time' in updatedValues) params['pre_arrival_message_send_at_time'] = updatedValues.pre_arrival_message_send_at_time;
    if ('has_welcome_message' in updatedValues) params['has_welcome_message'] = updatedValues.has_welcome_message;
    if ('welcome_message_id' in updatedValues) params['welcome_message_id'] = updatedValues.welcome_message_id;
    if ('welcome_message_send_after' in updatedValues) params['welcome_message_send_after'] = updatedValues.welcome_message_send_after;
    if ('has_repeat_visitor_message' in updatedValues) params['has_repeat_visitor_message'] = updatedValues.has_repeat_visitor_message;
    if ('repeat_visitor_message_id' in updatedValues) params['repeat_visitor_message_id'] = updatedValues.repeat_visitor_message_id;
    if ('repeat_visitor_message_send_after' in updatedValues) params['repeat_visitor_message_send_after'] = updatedValues.repeat_visitor_message_send_after;
    if ('has_departure_message' in updatedValues) params['has_departure_message'] = updatedValues.has_departure_message;
    if ('departure_message_id' in updatedValues) params['departure_message_id'] = updatedValues.departure_message_id;
    if ('departure_message_send_after' in updatedValues) params['departure_message_send_after'] = updatedValues.departure_message_send_after;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }

    setCurrentStatus('');

    if (props.rule) {
      dispatch(editDataAction({
        op: 'edit_guest_journey_rule',
        show_alert: false,
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'edit',
          rule_id: props.rule._id,
          room_id: props.rule.room_id,
          attr: {
            whatsapp: params,
          },
        }
      }));
    } else {
      dispatch(editDataAction({
        op: 'edit_settings',
        show_alert: true,
        params: {
          location__id: urlParams.locationidentifier,
          source: 'engage',
          whatsapp: params,
        }
      }));
    }



    // dispatch(editSettingsAction({
    //   params,
    // }));
    setFormChanged(false);
    // gtag('event', 'gt_click', {
    //   'event_category': 'Settings - Chat',
    //   'event_label': 'Save',
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onShowAddedTemplateClick = (templateData) => {
    setMsgTypeAddTemplateModal(MSGTYPE_STR_TO_MSGTYPE[templateData.type]);
    setShowAddTemplateModal(true);
    setSelectedAddedTemplate(templateData);
  };
  const onAddNewTemplateClick = (msgType) => {
    setSelectedAddedTemplate(null);
    setMsgTypeAddTemplateModal(msgType);
    setShowAddTemplateModal(true);
  };
  const onAddTemplateModalCloseClick = (pathParam) => {
    setShowAddTemplateModal(false);
    // const { add_guest_single } = queryString.parse(props.location.search);
    // let path = props.location.pathname;
    // path = pathParam || props.location.pathname;
    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('path: ', path);
    // }
    // if (add_guest_single) {
    //   path = path.replace('add_guest_single=1', '');
    // }
    // props.history.push(path);
  };
  // const onWhatsappTemplateAddConfirmClick = (templateData) => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('onWhatsappTemplateAddConfirmClick: ', templateData);
  //   }
  //   // props.dispatch(addWhatsappMessageTemplateAction({
  //   //   params: {
  //   //     uid: props.userprops.user.uid,
  //   //     mode: 'add',
  //   //     location_id: props.locationprops.location.location_id,
  //   //     title: templateData.title,
  //   //     msg: templateData.msg,
  //   //     type: templateData.type
  //   //   },
  //   // }));
  // };
  // const onWhatsappTemplateAddAddClear = (templateData) => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('onWhatsappTemplateAddAddClear: ', templateData);
  //   }
  //   // props.dispatch(clearAddWhatsappMessageTemplateAction({
  //   //   params: {
  //   //     mode: 'add',
  //   //   },
  //   // }));
  // };

  const renderAddTemplateModal = () => {
    return (
      <GenericSideModal
        modalType="addWhatsappMessageTemplate"
        className="mtGen"
        data={{
          mode: selectedAddedTemplate ? 'view' : '',
          messageType: msgTypeAddTemplateModal,
          template: selectedAddedTemplate ? { ...selectedAddedTemplate } : {},
        }}
        extData={{
          title: 'Add WhatsApp Message Template',
          settings: props.settings
        }}
        setModalVisible={onAddTemplateModalCloseClick}
        inProgress={false}
        showCancel={false}
        affirmativeButtonLabel={'OK'}
        disableOutsideClick={true}
      />
    );
      {/*<Modal>
        <div id="cModelId" className="cModal addWhatsappTemplateModal">
          <div className="content" ref={addTemplateModalRef}>
            <AddWhatsappTemplate
              onCancelClick={onAddTemplateModalCloseClick}
              type={msgTypeAddTemplateModal}
              onAddConfirmClick={onWhatsappTemplateAddConfirmClick}
              addInProgress={props.settings.engage.added_whatsapp_templates ? props.settings.engage.added_whatsapp_templates.addInProgress : false}
              addStatus={props.settings.engage.added_whatsapp_templates ? props.settings.engage.added_whatsapp_templates.addStatus : null}
              onAddClear={onWhatsappTemplateAddAddClear}
              selectedAddedTemplate={selectedAddedTemplate}
              mode={selectedAddedTemplate ? 'view' : ''}
            />
          </div>
        </div>
      </Modal>*/}
    // );
  };

  const renderStatus = () => {
    if (!currentStatus) return null;
    return (
      <div className="gBCtRw curSts">
        <p className="error">
          {
            currentStatus === 'error'
            ? 'Check above errors!'
            : currentStatus
          }
        </p>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('JMWhatsapp: ', props, ' # ', props);
  }

  return (
    <div className="gPScrlWrp jMsg">
      <div className="gPWrp jMSms">
        <div className="gPTle title">
          Guest Journey messages - WhatsApp{props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
          {
            props.rule &&
            <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
          }
        </div>
        {
          props.rule
          ?
            <>
              <div className="gPSubTle">
                Rule will be applied on guests whose room number / type matches with this rule and below guest journey messages will be sent.
              </div>
              <div className="gPSubTle">
                <p>Rule Name: {props.rule.label}</p>
                {
                  props.settings.rooms && props.settings.rooms.keyValue && props.settings.rooms.keyValue[props.rule.room_id] &&
                  <p>For Room: {getRoomLabel(props.settings.rooms.keyValue[props.rule.room_id])}</p>
                }
              </div>
            </>
          : null
        }
        <div className="gBCtnt">
          {
            props.settings.engage && props.settings.engage.booking_message_enabled &&
            <div className="gBCtRw jmEntRw">
              <PreDefinedMessage
                type="bookingMessage"
                heading="Booking Message"
                title="Send a booking message to the guest?"
                desc="Set an automated personalized booking message to guests."
                messageLabel="Booking Message"
                hasMessage={hasBookingMessage}
                messageId={bookingMessage.messageId}
                message={bookingMessage.message}
                sendAfter={bookingMessage.sendAfter}
                onHasMessageChange={onHasBookingMessageChange}
                onMessageIdChange={onBookingMessageIdChange}
                onSendAfterChange={onBookingMessageSendAfterChange}
                locationInfo={props.locationInfo}
                settings={props.settings}
                dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the welcome message.</p>}
                onAddNewTemplateClick={onAddNewTemplateClick}
                onShowAddedTemplateClick={onShowAddedTemplateClick}
              />
            </div>
          }
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="preArrivalMessage"
              heading="Pre-Arrival Message"
              title="Send a pre-arrival message to the guest?"
              desc="Set an automated personalized pre-arrival message to guests."
              messageLabel="Pre-Arrival Message"
              hasMessage={hasPreArrivalMessage}
              messageId={preArrivalMessage.messageId}
              message={preArrivalMessage.message}
              sendBefore={preArrivalMessage.sendBefore}
              sendAt={preArrivalMessage.sendAt}

              onHasMessageChange={onHasPreArrivalMessageChange}
              onMessageIdChange={onPreArrivalMessageIdChange}
              onSendBeforeChange={onPreArrivalMessageSendBeforeChange}
              onSendAtTimeChange={onPreArrivalMessageSendAtTimeChange}
              onSendAtErrorChange={onPreArrivalMessageSendAtErrorChange}
              checkInTime={'10:00 AM'}
              locationInfo={props.locationInfo}
              settings={props.settings}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the pre-arrival message.</p>}
              onAddNewTemplateClick={onAddNewTemplateClick}
              onShowAddedTemplateClick={onShowAddedTemplateClick}
            />
          </div>
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="welcomeMessage"
              heading="Welcome Message"
              title="Send a check-in welcome message to the guest?"
              desc="Set an automated personalized welcome message to guests."
              messageLabel="Welcome Message"
              hasMessage={hasWelcomeMessage}
              messageId={welcomeMessage.messageId}
              message={welcomeMessage.message}
              sendAfter={welcomeMessage.sendAfter}
              onHasMessageChange={onHasWelcomeMessageChange}
              onMessageIdChange={onWelcomeMessageIdChange}
              onSendAfterChange={onWelcomeMessageSendAfterChange}
              locationInfo={props.locationInfo}
              settings={props.settings}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the welcome message. Similarly you can add {"{{wifi}}"} code.</p>}
              onAddNewTemplateClick={onAddNewTemplateClick}
              onShowAddedTemplateClick={onShowAddedTemplateClick}
            />
          </div>
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="repeatWelcomeMessage"
              heading="Repeat Welcome Message"
              messageLabel="Welcome Message"
              title="Send check-in welcome message for returning guests?"
              desc="Set an automated personalized welcome message to returning guests."
              hasMessage={hasRepeatWelcomeMessage}
              messageId={repeatWelcomeMessage.messageId}
              message={repeatWelcomeMessage.message}
              sendAfter={repeatWelcomeMessage.sendAfter}
              onHasMessageChange={onHasRepeatWelcomeMessageChange}
              onMessageIdChange={onRepeatWelcomeMessageIdChange}
              onSendAfterChange={onRepeatWelcomeMessageSendAfterChange}
              locationInfo={props.locationInfo}
              settings={props.settings}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the welcome message. Similarly you can add {"{{wifi}}"} code.</p>}
              onAddNewTemplateClick={onAddNewTemplateClick}
              onShowAddedTemplateClick={onShowAddedTemplateClick}
            />
          </div>
          <div className="gBCtRw jmEntRw">
            <PreDefinedMessage
              type="departureMessage"
              heading="Departure Message"
              messageLabel="Departure Message"
              title="Send a check-out message to guests?"
              desc="Set a personalized check-out message to guests."
              hasMessage={hasDepartureMessage}
              messageId={departureMessage.messageId}
              message={departureMessage.message}
              sendAfter={departureMessage.sendAfter}
              onHasMessageChange={onHasDepartureMessageChange}
              onMessageIdChange={onDepartureMessageIdChange}
              onSendAfterChange={onDepartureMessageSendAfterChange}
              locationInfo={props.locationInfo}
              settings={props.settings}
              dynamicLabel={<p>Use braces brackets to add {"{{firstname}}"} or {"{{lastname}}"} of guests to personalize the departure message. Similarly you can add {"{{reviewlink}}"} to collect guest feedback and reviews.</p>}
              onAddNewTemplateClick={onAddNewTemplateClick}
              onShowAddedTemplateClick={onShowAddedTemplateClick}
            />
          </div>
          {
            props.rule
            ?
              <>
                {
                  props.rule.editStatus && (props.rule.editStatus.success || props.rule.editStatus.error) &&
                  <div className="gBCtRw curSts">
                    {
                      props.rule.editStatus.error && <p className="gErr">{props.rule.editStatus.message || 'Error!'}</p>
                    }
                    {
                      props.rule.editStatus.success && <p className="success">Updated successfully</p>
                    }
                  </div>
                }
              </>
            : null
          }
          { renderStatus() }
          {
            !props.isReadOnly &&
            <div className="gBCtRw btnRw">
              {/*<button className="cancel" onClick={onCancelClick}>Cancel</button>*/}
              <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
                Save Changes
                {
                  props.rule
                  ?
                    <>
                      {
                        props.settings.engage.journey_rules &&
                        props.settings.engage.journey_rules.keyValue &&
                        props.settings.engage.journey_rules.keyValue[props.rule._id] &&
                        props.settings.engage.journey_rules.keyValue[props.rule._id].editInProgress
                        ? <i className="fa fa-spinner fa-spin" />
                        : null
                      }
                    </>
                  :
                    <>{ props.settings.editInProgress && <i className="fa fa-spinner fa-spin" /> }</>
                }
              </button>
            </div>
          }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </div>
      { showAddTemplateModal && renderAddTemplateModal() }
    </div>
  );
}

export default JMWhatsapp;
