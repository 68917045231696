import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';
import moment from 'moment';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import CustomTimePicker from '../../../Misc/CustomTimePicker';
import GenericAlertModal from '../../../Misc/GenericAlertModal';

import EmailTemplate from '../../EmailBuilder/EmailTemplate';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';

import { TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP, STRS, LANGUAGE_LIST_KEYS } from '../../../../helpers';

import emojiIcon from '../../../../img/icons/emoji.svg';
import editIcon from '../../../../img/icons/edit-box.svg';
import deleteIcon from '../../../../img/icons/delete-box.svg';

import './style.css';

const modalRoot = document.getElementById('modal-email-template');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

const sendAfterTimes = [];
sendAfterTimes.push({ label: '30 Secs', value: '30 Secs' });
sendAfterTimes.push({ label: '2 Mins', value: '2 Mins' });
sendAfterTimes.push({ label: '10 Mins', value: '10 Mins' });
sendAfterTimes.push({ label: '30 Mins', value: '30 Mins' });
sendAfterTimes.push({ label: '60 Mins', value: '60 Mins' });
sendAfterTimes.push({ label: '24 Hrs', value: '24 Hrs' });

const sendBeforeDays = [];
sendBeforeDays.push({ label: 'Same Day', value: 'Same Day' });
sendBeforeDays.push({ label: '1 Day', value: '1 Day' });
sendBeforeDays.push({ label: '2 Days', value: '2 Days' });
sendBeforeDays.push({ label: '3 Days', value: '3 Days' });
sendBeforeDays.push({ label: '4 Days', value: '4 Days' });
sendBeforeDays.push({ label: '5 Days', value: '5 Days' });
sendBeforeDays.push({ label: '10 Days', value: '10 Days' });
sendBeforeDays.push({ label: '30 Days', value: '30 Days' });

const personalizeOptionsG = [];
personalizeOptionsG.push({ label: 'Personalize', value: 'personalize' });
personalizeOptionsG.push({ label: 'First Name', value: '{{first_name}}' });
personalizeOptionsG.push({ label: 'Last Name', value: '{{last_name}}' });
personalizeOptionsG.push({ label: 'Room Type', value: '{{room_type}}' });
personalizeOptionsG.push({ label: 'Hotel Name', value: '{{hotel_name}}' });

const customizeForOptionsG = [];
customizeForOptionsG.push({ label: 'Rate Code', value: 'rate-code' });
customizeForOptionsG.push({ label: 'Booking Source', value: 'booking-source' });
customizeForOptionsG.push({ label: 'Room Type', value: 'room-type' });
customizeForOptionsG.push({ label: 'Room Number', value: 'room-number' });
customizeForOptionsG.push({ label: 'Guest Language', value: 'guest-lang' });


function SingleTransactionEmail(props) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [showEdit, setShowEdit] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isEnabledChanged, setIsEnabledChanged] = useState(false);

  const [customizeForOptions, setCustomizeForOptions] = useState([]);
  const [customizeForValue, setCustomizeForValue] = useState({});
  const [customizeForError, setCustomizeForError] = useState('');

  const [customValueOptions, setCustomValueOptions] = useState([]);
  const [customValue, setCustomValue] = useState(null);
  const [customValueError, setCustomValueError] = useState('');


  const [emailTemplateId, setEmailTemplateId] = useState('');
  const [emailTemplateError, setEmailTemplateError] = useState('');

  const [subject, setSubject] = useState('');
  const [subjectError, setSubjectError] = useState('');

  const [previewText, setPreviewText] = useState('');
  const [previewTextError, setPreviewTextError] = useState('');

  const [sendAfterValue, setSendAfterValue] = useState({})
  const [sendAfterError, setSendAfterError] = useState('');

  const [sendBeforeValue, setSendBeforeValue] = useState({});
  const [sendBeforeError, setSendBeforeError] = useState('');

  const [sendAt, setSendAt] = useState('');
  const [timeConstraintsScheduleTime, setTimeConstraintsScheduleTime] = useState({ minutes: { step: 15 }});
  const [sendAtError, setSendAtError] = useState('');

  const [personalizeOptions, setPersonalizeOptions] = useState([]);
  const [personalizeValue, setPersonalizeValue] = useState([
    { label: 'Personalize', value: 'personalize' }
  ]);
  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
  const [emojiSelectorPreviewTextOpen, setEmojiSelectorPreviewTextOpen] = useState(false);

  const [showEmailTemplateModal, setShowEmailTemplateModal] = useState(false);
  const emailTemplateModalRef = useRef();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const subjectEl = useRef(null);
  const previewTextEl = useRef(null);

  const [addModeData, setAddModeData] = useState({});

  const [someChange, setSomeChange] = useState(false);

  const clearAction = () => {
    const params = {
      type: props.type,
      location__id: props.locationInfo._id,
    };
    params.mode = props.mode; // 'add', 'edit'
    if (props.data && props.data._id) params.transaction_email_id = props.data._id;
    dispatch(editDataClearAction({
      op: 'edit_transaction_email',
      params,
    }));
  };

  const resetData = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SingleTransactionEmail resetData');
    }
    setIsEnabled(false);
    setSubject('');
    setSubjectError('');
    setPreviewText('');
    setPreviewTextError('');
    setSendAfterValue({});
    setSendAfterError('');
    setEmailTemplateId('');
    setEmailTemplateError('');
    setSendBeforeValue({});
    setSendBeforeError('');
    setSendAt('');
    setSendAtError('');
    setCustomizeForValue({});
    setCustomizeForError('');
    setCustomValue(null);
    setCustomValueError('');
    setAddModeData({});
  };

  const updateCustomizeForRateCodeOptions = () => {
    const rateCodesList = props.settings.rate_codes && props.settings.rate_codes.list && props.settings.rate_codes.keyValue
     ? props.settings.rate_codes.list.filter(el => props.settings.rate_codes.keyValue[el] && props.settings.rate_codes.keyValue[el]._id ? true : false)
        .map((el) => {
          return { label: props.settings.rate_codes.keyValue[el].label, value: props.settings.rate_codes.keyValue[el]._id };
        })
    : [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('rateCodesList: ', rateCodesList);
    }
    setCustomValueOptions([
      ...rateCodesList,
    ]);
  };

  const updateCustomizeForBookingSourceOptions = () => {
    const bookingSourcesList = props.settings.booking_sources && props.settings.booking_sources.list && props.settings.booking_sources.keyValue
     ? props.settings.booking_sources.list.filter(el => props.settings.booking_sources.keyValue[el] && props.settings.booking_sources.keyValue[el]._id ? true : false)
        .map((el) => {
          return { label: props.settings.booking_sources.keyValue[el].label, value: props.settings.booking_sources.keyValue[el]._id };
        })
    : [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('bookingSourcesList: ', bookingSourcesList);
    }
    setCustomValueOptions([
      ...bookingSourcesList,
    ]);
  };

  const updateCustomizeForRoomTypeOptions = () => {
    const roomTypesList = props.settings.rooms && props.settings.rooms.list && props.settings.rooms.keyValue
     ? props.settings.rooms.list.filter(el => props.settings.rooms.keyValue[el] && props.settings.rooms.keyValue[el].attr && props.settings.rooms.keyValue[el].attr['room-type'] ? true : false)
        .map((el) => {
          return { label: props.settings.rooms.keyValue[el].label, value: props.settings.rooms.keyValue[el]._id };
        })
    : [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('roomTypesList: ', roomTypesList);
    }
    setCustomValueOptions([
      ...roomTypesList,
    ]);
  };

  const updateCustomizeForRoomNumberOptions = () => {
    const roomNumbersList = props.settings.rooms && props.settings.rooms.list && props.settings.rooms.keyValue
     ? props.settings.rooms.list.filter(el => props.settings.rooms.keyValue[el] && props.settings.rooms.keyValue[el].attr && props.settings.rooms.keyValue[el].attr['room-number'] ? true : false)
        .map((el) => {
          return { label: props.settings.rooms.keyValue[el].label, value: props.settings.rooms.keyValue[el]._id };
        })
    : [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('roomNumbersList: ', roomNumbersList);
    }
    setCustomValueOptions([
      ...roomNumbersList,
    ]);
  };

  const updateCustomizeForGuestLangOptions = () => {
    const langList = props.settings.engage && props.settings.engage.te_guest_lang_list && Object.keys(props.settings.engage.te_guest_lang_list).length
     ? Object.keys(props.settings.engage.te_guest_lang_list)
        .map((el) => {
          return { label: LANGUAGE_LIST_KEYS[el], value: el };
        })
    : [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('langList: ', langList);
    }
    setCustomValueOptions([
      ...langList,
    ]);
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SingleTransactionEmail useEffect []: ', props.data);
    }
    clearAction();
    if (props.type === 'booking') {
      let newOptions = [];
      // newOptions.push({ label: 'Wifi', value: '{{wifi}}' });
      setPersonalizeOptions([
        ...personalizeOptionsG,
        // ...newOptions,
      ]);
    }
    if (props.type === 'early-pre-arrival' || props.type === 'pre-arrival') {
      let newOptions = [];
      // newOptions.push({ label: 'Wifi', value: '{{wifi}}' });
      setPersonalizeOptions([
        ...personalizeOptionsG,
        // ...newOptions,
      ]);
    }
    if (props.type === 'check-in') {
      let newOptions = [];
      newOptions.push({ label: 'Wifi', value: '{{wifi}}' });
      if (props.locationInfo && props.locationInfo.subdomain && props.locationInfo.mp_is_complete && props.locationInfo.mp_is_active) {
        newOptions.push({ label: 'Info Link', value: '{{infolink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }
    if (props.type === 'check-out') {
      let newOptions = [];
      newOptions.push({ label: 'Review Link', value: '{{reviewlink}}' });
      if (props.locationInfo && props.locationInfo.subdomain && props.locationInfo.mp_is_complete && props.locationInfo.mp_is_active) {
        newOptions.push({ label: 'Info Link', value: '{{infolink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }
  }, []);

  useEffect(() => {
    setShowEdit(false);
  }, [props.type]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SingleTransactionEmail useEffect props.data: ', props.data);
      console.log('SingleTransactionEmail useEffect props.editInProgress: ', props.editInProgress);
      console.log('SingleTransactionEmail useEffect showEdit: ', showEdit);
      console.log('SingleTransactionEmail useEffect someChange: ', someChange);
    }
    if (props.editInProgress) return;
    // if (someChange && showEdit) return;
    // if (!showEdit) return;
    resetData();
    if (props.mode === 'edit') { // props.data
      setIsEnabled(props.data.is_enabled);
      setSubject(props.data.subject);
      setPreviewText(props.data.pre_header_text);
      if (props.data.send_after) setSendAfterValue({ label: props.data.send_after, value: props.data.send_after });
      if (props.data.email_template_id) setEmailTemplateId(props.data.email_template_id);
      if (props.data.send_before) {
        if (props.data.send_before === '0 Day') {
          setSendBeforeValue({ label: 'Same Day', value: 'Same Day' });
        } else {
          setSendBeforeValue({ label: props.data.send_before, value: props.data.send_before });
        }
      }
      if (props.data.send_at) {
        setSendAt(props.data.send_at ? new Date(moment(props.data.send_at, 'HH:mm')) : '');
      } else {
        setSendAt(new Date(moment('10:00', 'HH:mm')));
      }
      if (props.data.custom_for) {
        setCustomizeForValue({
          label: TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP[props.data.custom_for], // nkcheck
          value: props.data.custom_for,
        });
      }
      if (props.data.custom_for === 'rate-code') {
        updateCustomizeForRateCodeOptions();
      }
      if (props.data.custom_for === 'booking-source') {
        updateCustomizeForBookingSourceOptions();
      }
      if (props.data.custom_for === 'room-type') {
        updateCustomizeForRoomTypeOptions();
      }
      if (props.data.custom_for === 'room-number') {
        updateCustomizeForRoomNumberOptions();
      }
      if (props.data.custom_for === 'guest-lang') {
        updateCustomizeForGuestLangOptions();
      }
      if (props.data.custom_value) {
        let label = '';
        if (props.data.custom_for === 'rate-code') {
          label = props.settings.rate_codes && props.settings.rate_codes.keyValue && props.settings.rate_codes.keyValue[props.data.custom_value]
            ? props.settings.rate_codes.keyValue[props.data.custom_value].label : '';
        }
        if (props.data.custom_for === 'booking-source') {
          label = props.settings.booking_sources && props.settings.booking_sources.keyValue && props.settings.booking_sources.keyValue[props.data.custom_value]
            ? props.settings.booking_sources.keyValue[props.data.custom_value].label : '';
        }
        if (props.data.custom_for === 'room-type' || props.data.custom_for === 'room-number') {
          label = props.settings.rooms && props.settings.rooms.keyValue && props.settings.rooms.keyValue[props.data.custom_value]
            ? props.settings.rooms.keyValue[props.data.custom_value].label : '';
        }
        if (process.env.NODE_ENV !== 'production') {
          console.log('props.data.custom_for: ', props.data.custom_for);
          console.log('props.data.custom_value: ', props.data.custom_value);
          console.log('label: ', label);
        }
        setCustomValue([
          ...props.data.custom_value
          // label,
          // value: props.data.custom_value,
        ]);
      }
    } else { // mode === 'add'
      // resetData();
      setSendAt(new Date(moment('10:00', 'HH:mm')));
      if (addModeData.customizeForValue) {
        setCustomizeForValue(addModeData.customizeForValue);
      }
      if (addModeData.customValue) {
        setCustomValue(addModeData.customValue);
      }
      if (addModeData.subject) {
        setSubject(addModeData.subject);
      }
      if (addModeData.previewText) {
        setPreviewText(addModeData.previewText);
      }
      if (addModeData.sendBeforeValue) {
        setSendBeforeValue(addModeData.sendBeforeValue);
      }
      if (addModeData.sendAfterValue) {
        setSendBeforeValue(addModeData.sendAfterValue);
      }
      if (addModeData.sendAt) {
        setSendAt(addModeData.sendAt);
      }
    }
    if (props.isCustom) {
      if (props.mode === 'add') {
        setShowEdit(true);
      }
      let newOptions = [];
      // only of not in use already
      let tempFound = false;
      const { transaction_emails } = props.transactionEmails;
      if (transaction_emails && transaction_emails.list && transaction_emails.keyValue) {
        let tempFound = transaction_emails.list.filter(el => transaction_emails.keyValue &&
          transaction_emails.keyValue[el] && transaction_emails.keyValue[el].custom_for &&
          transaction_emails.keyValue[el].custom_for === 'repeat-visitors');
      }
      if (!tempFound.length || (props.data && props.data.custom_for === 'repeat-visitors')) {
        newOptions = props.type === 'check-in' ? [{ label: 'Repeat Visitors', value: 'repeat-visitors'}] : [];
      }
      setCustomizeForOptions([
        ...customizeForOptionsG,
        ...newOptions,
      ]);
    }
  }, [props.type, props.data, showEdit]);

  useEffect(() => {
    if (!props.editInProgress &&
      props.editStatus &&
      props.editStatus.success
    ) {
      setSomeChange(false);
      clearAction();
      if (props.mode === 'add') {
        resetData();
      }
    }
  }, [props.editStatus, props.editInProgress]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SingleEmailSetting useEffect someChange: ', someChange);
    }
    props.onSomeChange(someChange);
    if (someChange && (props.editStatus && (props.editStatus.success || props.editStatus.error))) {
      clearAction();
    }
  }, [someChange]);


  const onIsEnabledChange = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onIsEnabledChange');
    }
    const { name, checked } = e.target;
    setIsEnabled(checked);
    // props.onIsEnabledChange(checked);
    setIsEnabledChanged(true);
    // setSomeChange(true);
    setShowEdit(true);
    setSomeChange(true);
  };

  const onEditClick = (e) => {
    setShowEdit(showEdit => !showEdit);
  };
  const onDeleteClick = (e) => {
    setShowDeleteModal(true);
  };
  const onShowDeleteModalClose = (e) => {
    setShowDeleteModal(false);
  };
  const onDeleteConfirmClick = (e) => {
    if (!props.data || !props.data._id) return;
    const params = {
      type: props.type,
      location__id: props.locationInfo._id,
    };
    params.mode = 'remove';
    params.transaction_email_id = props.data._id;
    dispatch(editDataAction({
      op: 'edit_transaction_email',
      params,
      show_alert: true,
      alert_msg: 'Deleted successfully',
    }));
  };

  const onCustomizeForChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCustomizeForChange: ', value);
    }
    setCustomizeForValue(value);
    setCustomValue(null);
    if (value && value.value === 'rate-code') {
      updateCustomizeForRateCodeOptions();
    }
    if (value && value.value === 'booking-source') {
      updateCustomizeForBookingSourceOptions();
    }
    if (value && value.value === 'room-type') {
      updateCustomizeForRoomTypeOptions();
    }
    if (value && value.value === 'room-number') {
      updateCustomizeForRoomNumberOptions();
    }
    if (value && value.value === 'guest-lang') {
      updateCustomizeForGuestLangOptions();
    }
    setCustomizeForError(value && value.value ? '' : 'error');
    setSomeChange(true);
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        customizeForValue: value,
      })
    }
  };
  const onCustomeValueChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCustomeValueChange: ', value);
    }
    setCustomValue(value);
    setCustomValueError(value && value.length ? '' : 'error');
    setSomeChange(true);
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        customValue: value,
      })
    }
  };
  const customValueNoOptionsMessage = () => {
    if (customizeForValue.value === 'rate-code') return 'No rate codes available!'
    if (customizeForValue.value === 'booking-source') return 'No booking sources available!'
    if (customizeForValue.value === 'room-type') return 'No rooms available!'
    if (customizeForValue.value === 'room-number') return 'No rooms available!'
    return 'No options!';
  };

  const onEmailTemplateEditChange = (e) => {
    setShowEmailTemplateModal(true);
  };
  const onEmailPreviewModalCloseClick = (e) => {
    setShowEmailTemplateModal(false);
  };
  const onEmailTemplateSaveClick = (data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmailTemplateSaveClick: ', data);
    }
    setEmailTemplateId(data._id);
    // props.onEmailTemplateChange(data._id);
    setSomeChange(true);
    setEmailTemplateError(data._id ? '' : 'error');
  };


  /* Email Subject */

  const onPersonalizeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('subjectEl: ', subjectEl.current.selectionStart);
    }
    let newSubject = '';
    if (value && value.value !== 'personalize') {
      if (subject) {
        // setMessage(message => `${message} ${values[0].label}`);
        newSubject = `${subject.substring(0,subjectEl.current.selectionStart)}${value.value}${subject.substring(subjectEl.current.selectionStart)}`;
        setSubject(newSubject);
      } else {
        newSubject = `${value.value}`;
        setSubject(newSubject);
        // props.onSubjectChange(`${value.value}`);
      }
    }
    setSomeChange(true);
    setSubjectError(value && value.value ? '' : 'error');
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        subject: newSubject,
      })
    }
  };
  const onSubjectChange = (e) => {
    setSubject(e.target.value);
    // props.onSubjectChange(e.target.value);
    setSomeChange(true);
    setSubjectError(e.target.value ? '' : 'error');
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        subject: e.target.value,
      })
    }
  };
  const onOpenEmojiSelectorClick = (e) => {
    setEmojiSelectorOpen(emojiSelectorOpen => !emojiSelectorOpen);
  };
  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    let newSubject = '';
    if (emoji && emoji.native) {
      // setMessage(message => `${message} ${emoji.native}`);
      if (subject) {
        newSubject = `${subject.substring(0,subjectEl.current.selectionStart)}${emoji.native}${subject.substring(subjectEl.current.selectionStart)}`;
        setSubject(newSubject);
        // props.onSubjectChange(`${subject.substring(0,messageEl.current.selectionStart)}${emoji.native}${subject.substring(messageEl.current.selectionStart)}`);
      } else {
        newSubject = `${emoji.native}`;
        setSubject(newSubject);
        // props.onSubjectChange(`${emoji.native}`);
      }
    }
    setSomeChange(true);
    setSubjectError(emoji && emoji.native ? '' : 'error');
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        subject: newSubject,
      })
    }
  };


  /* Preview Text */

  const onPersonalizePreviewTextChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPersonalizePreviewTextChange previewTextEl: ', previewTextEl.current.selectionStart);
    }
    let newPreviewText = '';
    if (value && value.value !== 'personalize') {
      if (previewText) {
        newPreviewText = `${previewText.substring(0,previewTextEl.current.selectionStart)}${value.value}${previewText.substring(previewTextEl.current.selectionStart)}`;
        setPreviewText(newPreviewText);
      } else {
        newPreviewText = `${value.value}`;
        setPreviewText(newPreviewText);
      }
    }
    setSomeChange(true);
    setPreviewTextError(value && value.value ? '' : 'error');
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        previewText: newPreviewText,
      })
    }
  };
  const onPreviewTextChange = (e) => {
    setPreviewText(e.target.value);
    setSomeChange(true);
    setPreviewTextError(e.target.value ? '' : 'error');
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        previewText: e.target.value,
      })
    }
  };
  const onOpenEmojiSelectorPreviewTextClick = (e) => {
    setEmojiSelectorPreviewTextOpen(emojiSelectorPreviewTextOpen => !emojiSelectorPreviewTextOpen);
  };
  const onEmojiPreviewTextClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiPreviewTextClick: ', emoji, ' # ', e);
    }
    let newPreviewText = '';
    if (emoji && emoji.native) {
      // setMessage(message => `${message} ${emoji.native}`);
      if (previewText) {
        newPreviewText = `${previewText.substring(0,previewTextEl.current.selectionStart)}${emoji.native}${previewText.substring(previewTextEl.current.selectionStart)}`;
        setPreviewText(newPreviewText);
      } else {
        newPreviewText = `${emoji.native}`;
        setPreviewText(newPreviewText);
      }
    }
    setSomeChange(true);
    setPreviewTextError(emoji && emoji.native ? '' : 'error');
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        previewText: newPreviewText,
      })
    }
  };

  /* Send before/After */

  const onSendAfterChange = (value) => {
    setSendAfterValue(value);
    // props.onSendAfterChange(value);
    setSomeChange(true);
    setSendAfterError(value && value.value ? '' : 'error');
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        sendAfterValue: value,
      })
    }
  };
  const onSendBeforeChange = (value) => {
    setSendBeforeValue(value);
    if (value.value === 'Same Day') {
      // props.onSendBeforeChange({ label: 'Same Day', value: '0 Day' });
      const checkInTime = moment(props.checkInTime, 'H:mm A');
      if (moment(sendAt).isSameOrBefore(checkInTime)) {
        setSendAtError('');
        // props.onSendAtErrorChange('');
      } else {
        setSendAtError('For same day, it cannot be later than 10 AM');
        // props.onSendAtErrorChange('error');
      }
    } else {
      // props.onSendBeforeChange(value);
      setSendAtError('');
      // props.onSendAtErrorChange('');
    }
    // props.onSendBeforeChange(value);
    setSomeChange(true);
    setSendBeforeError(value && value.value ? '' : 'error');
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        sendBeforeValue: value,
      })
    }
  };
  const onSendAtTimeChange = (selectedTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendAtTimeChange: ', selectedTime);
      console.log('onSendAtTimeChange checkInTime: ', props.checkInTime);
    }
    const checkInTime = moment(props.checkInTime, 'H:mm A');
    if (process.env.NODE_ENV !== 'production') {
      console.log('checkInTime: ', checkInTime.format());
      console.log('sendBeforeValue: ', sendBeforeValue);
    }
    if (sendBeforeValue.value === 'Same Day') {
      if (moment(selectedTime, 'hh:mm A').isSameOrBefore(checkInTime)) {
        setSendAtError('');
        // props.onSendAtErrorChange('');
      } else {
        setSendAtError('For same day, it cannot be later than 10 AM');
        // props.onSendAtErrorChange('error');
      }
    }
    setSendAt(selectedTime ? new Date(moment(selectedTime, 'hh:mm A')) : null);
    // props.onSendAtTimeChange(selectedTime);
    setSomeChange(true);
    if (!selectedTime) setSendAtError('Required');
    if (props.mode === 'add') {
      setAddModeData({
        ...addModeData,
        sendAt: selectedTime ? new Date(moment(selectedTime, 'hh:mm A')) : null,
      })
    }
  };


  const onSaveClick = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SingleTransactionEmail onSaveClick: ', emailTemplateId);
      console.log('onSaveClick someChange: ', someChange);
      console.log('onSaveClick isEnabledChanged: ', isEnabledChanged);
      console.log('onSaveClick subject: ', subject);
      console.log('onSaveClick previewText: ', previewText);
      console.log('onSaveClick sendBeforeValue: ', sendBeforeValue);
      console.log('onSaveClick sendAfterValue: ', sendAfterValue);
      console.log('onSaveClick sendAt: ', sendAt);
      console.log('onSaveClick customizeForValue: ', customizeForValue);
      console.log('onSaveClick customValue: ', customValue);
      console.log('onSaveClick editInProgress: ', props.editInProgress);
    }
    if (props.editInProgress) return;
    if (!someChange && !isEnabledChanged) return;
    setEmailTemplateError(emailTemplateId ? '' : 'error');
    setSubjectError(subject ? '' : 'error');
    // setPreviewTextError(previewTextError ? '' : 'error');
    if (props.type === 'early-pre-arrival' || props.type === 'pre-arrival') {
      setSendBeforeError(sendBeforeValue && sendBeforeValue.value ? '' : 'error');
      if (!sendAt) setSendAtError('Required');
    }
    if (props.type === 'booking' || props.type === 'check-in' || props.type === 'check-out') {
      setSendAfterError(sendAfterValue && sendAfterValue.value ? '' : 'error');
    }
    if (props.isCustom) {
      setCustomizeForError(customizeForValue && customizeForValue.value ? '' : 'error');
      setCustomValueError(customValue && customValue.length ? '' : 'error');
    }
    if (!emailTemplateId || !subject) return;
    if (props.type === 'early-pre-arrival' || props.type === 'pre-arrival') {
      if (!sendBeforeValue || !sendBeforeValue.value || !sendAt) return;
    }
    if (props.type === 'booking' || props.type === 'check-in' || props.type === 'check-out') {
      if (!sendAfterValue || !sendAfterValue.value) return;
    }
    const params = {
      type: props.type,
      location__id: props.locationInfo._id,
    };
    params.mode = props.mode; // 'add', 'edit'
    if (props.data && props.data._id) params.transaction_email_id = props.data._id;
    if (someChange) {
      params.email_template_id = emailTemplateId;
      params.subject = subject;
      params.pre_header_text = previewText || '';
      if (props.type === 'early-pre-arrival' || props.type === 'pre-arrival') {
        params.send_before = sendBeforeValue.value;
        params.send_at = moment(sendAt).format('HH:mm'); // sendAt;
      }
      if (props.type === 'booking' || props.type === 'check-in' || props.type === 'check-out') {
        params.send_after = sendAfterValue.value;
      }
      if (props.isDefault) params.is_default = true;
      if (props.isCustom) {
        params.is_custom = true;
        if (customizeForValue && customizeForValue.value) params.custom_for = customizeForValue.value;
        if (customValue && customValue.length) params.custom_value = customValue;
      }
    }
    if (isEnabledChanged) {
      params.is_enabled = isEnabled;
    }
    dispatch(editDataAction({
      op: 'edit_transaction_email',
      params,
      show_alert: true,
      alert_msg: props.data && props.data._id ? 'Updated successfully' : 'Added successfully',
    }));
    setSomeChange(false);
  };

  const onCancelClick = (e) => {
    if (props.mode === 'add') {
      props.onCancelAddNew(e);
    }
  };

  const renderEmailTemplateModal = (customTitle) => {
    return (
      <Modal>
        <div id="cModelId" className="cModal etModal">
          <div className="content" ref={emailTemplateModalRef}>
            <EmailTemplate
              onCancelClick={onEmailPreviewModalCloseClick}
              onSaveClick={onEmailTemplateSaveClick}
              title={`${customTitle} - ${props.emailSelectorTitle}`}
              emailTemplateId={emailTemplateId}
              type={props.type}
              userState={userState}
              locationInfo={props.locationInfo}
              isTransactionEmail={true}
              // email_templates={props.transactionEmails.email_templates}
              transactionEmails={props.transactionEmails}
              settings={props.settings}
              isReadOnly={props.isReadOnly}
            />
          </div>
        </div>
      </Modal>
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('SingleTransactionEmail addModeData: ', addModeData);
  }

  let emailTemplateName = '';
    if (emailTemplateId && props.transactionEmails.email_templates && props.transactionEmails.email_templates.keyValue &&
      props.transactionEmails.email_templates.keyValue[emailTemplateId]
    ) {
      emailTemplateName = props.transactionEmails.email_templates.keyValue[emailTemplateId].name || '';
    }
    let customTitle = 'Default';
    if (props.isCustom) {
      customTitle = 'Custom';
      if (customizeForValue.value === 'rate-code') customTitle = `${customTitle} - Rate Code`;
      if (customizeForValue.value === 'booking-source') customTitle = `${customTitle} - Booking Source`;
      if (customizeForValue.value === 'room-type') customTitle = `${customTitle} - Room Type`;
      if (customizeForValue.value === 'room-number') customTitle = `${customTitle} - Room Number`;
      if (customizeForValue.value === 'repeat-visitors') customTitle = `${customTitle} - Repeat Visitors`;
      // if (customValue) console.log('renderMessage customValue: ', customValue, ' # customValue.length: ', customValue.length);
      if (customValue && customValue.length) customTitle = `${customTitle} - ${customValue.reduce((prev, cur, curIndex) => { if (curIndex === customValue.length-1) { return prev + cur.label; } else { return prev + cur.label  + ' / '; }}, '')}`;
    }

  return (
    <div className="teSngl">
      <div className="teRw hdr">
        {customTitle}
      </div>
      <div className="teRw hasTgl">
        <div className="lt">
          <div className="title">
            {props.title}
          </div>
          <div className="desc">
            {props.desc}
          </div>
          {
            props.data && props.data._id &&
            <>
              <p className="kVGnrc"><span>Template:</span> {emailTemplateName || ''}</p>
              <p className="kVGnrc"><span>Subject:</span> {subject || ''}</p>
              {
                (props.type === 'early-pre-arrival' || props.type === 'pre-arrival') &&
                <p className="kVGnrc"><span>Send before:</span> {sendBeforeValue ? sendBeforeValue.value : ''}</p>
              }
              {
                (props.type === 'early-pre-arrival' || props.type === 'pre-arrival') &&
                <p className="kVGnrc"><span>Send at:</span> {sendAt ? moment(sendAt).format('HH:mm') : ''}</p>
              }
              {
                (props.type === 'booking' || props.type === 'check-in' || props.type === 'check-out') &&
                <p className="kVGnrc"><span>Send after:</span> {sendAfterValue ? sendAfterValue.value : ''}</p>
              }
            </>
          }
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
            {
                props.data && props.data._id
                ?
                <>
                  {
                    props.isReadOnly
                    ?
                    <span>{isEnabled ? 'Active' : 'In-Active'}</span>
                    :
                    <><input type="checkbox" id={'isEnabled_'+props.data._id} checked={isEnabled} onChange={onIsEnabledChange}/><label htmlFor={'isEnabled_'+props.data._id}>Toggle</label></>
                  }
                </>
                :
                <>
                  {
                    props.isReadOnly
                    ?
                    <span>{isEnabled ? 'Active' : 'In-Active'}</span>
                    :
                    <><input type="checkbox" id={`isEnabled_${props.type}_${props.isCustom ? 'custom' : ''}`} checked={isEnabled} onChange={onIsEnabledChange}/><label htmlFor={`isEnabled_${props.type}_${props.isCustom ? 'custom' : ''}`}>Toggle</label></>
                  }
                </>
              }
            </div>
          </div>
          { props.mode === 'edit' &&
              <div className="edit" onClick={onEditClick}>
                <img src={editIcon} />
              </div>
            }
            { props.mode === 'edit' && props.isCustom && !props.isReadOnly &&
              <div className="delete" onClick={onDeleteClick}>
                <img src={deleteIcon} />
              </div>
            }
        </div>
      </div>
      {
        showEdit &&
        <>
          { props.isCustom &&
            <div className="teRw cstmTypVal">
              <div className="lt">
                <label className="gLbl">Customize for</label>
                <Select
                  options={customizeForOptions}
                  value={customizeForValue}
                  onChange={onCustomizeForChange}
                />
                {
                  customizeForError &&
                  <span className="error">Required</span>
                }
              </div>
              <div className="rt">
              {
                customizeForValue && customizeForValue.value === 'repeat-visitors'
                ? null
                :
                  <>
                    <label className="gLbl">
                      Choose Value
                      {
                        customizeForValue && customizeForValue.value === 'rate-code' &&
                        <Link to={`/settings/locations/${props.locationInfo._id}/hotel-details/rate-codes`}>Add rate code</Link>
                      }
                      {
                        customizeForValue && customizeForValue.value === 'booking-source' &&
                        <Link to={`/settings/locations/${props.locationInfo._id}/hotel-details/booking-sources`}>Add booking source</Link>
                      }
                      {
                        customizeForValue &&
                        (customizeForValue.value === 'room-type' || customizeForValue.value === 'room-number') &&
                        <Link to={`/settings/locations/${props.locationInfo._id}/hotel-details/rooms`}>Add room</Link>
                      }
                    </label>
                    <Select
                      isMulti={true}
                      options={customValueOptions}
                      value={customValue}
                      onChange={onCustomeValueChange}
                      noOptionsMessage={customValueNoOptionsMessage}
                    />
                    {
                      customValueError &&
                      <span className="error">Required</span>
                    }
                  </>
              }
              </div>
            </div>
          }
        <div className="teRw tplNmRw">
          <div className="lt">
            <label className="gLbl">Email Template</label>
            <div className="title">
              {
                emailTemplateId &&
                <span className="etNm"><span>Name:</span> {emailTemplateName}</span>
              }
              {
                emailTemplateId
                ? <span className="etEdit" onClick={onEmailTemplateEditChange}>View/Edit Template</span>
                : <span className="etEdit" onClick={onEmailTemplateEditChange}>Select Template</span>
              }
            </div>
            {
              emailTemplateError &&
              <span className="gErr">Required</span>
            }
          </div>
        </div>
        <div className="teRw emValRw">
          <div className="lt">
            <label className="gLbl">Email Subject
              <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"Subject of your email. It's a good idea to include a relevant subject as it captures your audience's attention and generally leads to higher open rates."}>
                <span className="tltp">
                  <i className="fas fa-info-circle"></i>
                </span>
              </ToolTipWrapper>
            </label>
            <div className="gIpt">
              <input
                className={'gInput ' + (isEnabled && !subject ? 'error' : '')}
                onChange={onSubjectChange}
                placeholder="Subject..."
                value={subject}
                rows="4"
                ref={subjectEl}
              />
            </div>
            {
              subjectError &&
              <span className="gErr">Required</span>
            }
            <div className={'emojiIconBox' + (subjectError ? ' adjTp' : '')}>
              <div className="emojiIcon" onClick={onOpenEmojiSelectorClick}>
                {
                  emojiSelectorOpen
                  ? <i className="fa fa-times-circle" />
                  : <img src={emojiIcon} />
                }

              </div>
            </div>
            { emojiSelectorOpen &&
              <div className="emojiBox">
                <Picker data={emojiData}
                  className="em"
                  onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
                />
              </div>
            }
          </div>
        </div>
        <div className="teRw emPrsnlRw">
          <div className="lt">
            <label className="gSLbl">Choose a dynamic label to personalize the email subject line</label>
            <Select
              className="emPrsnlSel"
              options={personalizeOptions}
              value={personalizeValue}
              onChange={onPersonalizeChange}
            />
            <div className="gHelpMsg">
                {props.dynamicLabel}
              </div>
          </div>
        </div>

        <div className="teRw emValRw">
          <div className="lt">
            <label className="gLbl">Preview Text
              <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"A brief description of what's in your email, shown as a preview in the inbox next to the subject line. It's a good idea to include preview text as it captures your audience's attention and generally leads to higher open rates."}>
                <span className="tltp">
                  <i className="fas fa-info-circle"></i>
                </span>
              </ToolTipWrapper>
            </label>
            <div className="gIpt">
              <input
                className={'gInput ' + (isEnabled && !subject ? 'error' : '')}
                onChange={onPreviewTextChange}
                placeholder="Subject..."
                value={previewText}
                rows="4"
                ref={previewTextEl}
              />
            </div>
            {/*
              previewTextError &&
              <span className="gErr">Required</span>
            */}
            <div className={'emojiIconBox' + (subjectError ? ' adjTp' : '')}>
              <div className="emojiIcon" onClick={onOpenEmojiSelectorPreviewTextClick}>
                {
                  emojiSelectorPreviewTextOpen
                  ? <i className="fa fa-times-circle" />
                  : <img src={emojiIcon} />
                }

              </div>
            </div>
            { emojiSelectorPreviewTextOpen &&
              <div className="emojiBox">
                <Picker data={emojiData}
                  className="em"
                  onEmojiSelect={(emoji, e) => onEmojiPreviewTextClick(emoji, e)}
                />
              </div>
            }
          </div>
        </div>
        <div className="teRw emPrsnlRw">
          <div className="lt">
            <label className="gSLbl">Choose dynamic label to personalize preview text</label>
            <Select
              className="emPrsnlSel"
              options={personalizeOptions}
              value={personalizeValue}
              onChange={onPersonalizePreviewTextChange}
            />
            <div className="gHelpMsg">
                {props.dynamicLabel}
              </div>
          </div>
        </div>

        {
          (props.type === 'early-pre-arrival' || props.type === 'pre-arrival')
          ?
            <>
              <div className="teRw sndBfrAft">
                <div className="lt">
                  <label className="gLbl">Send Before</label>
                  <Select
                    options={sendBeforeDays}
                    value={sendBeforeValue}
                    onChange={onSendBeforeChange}
                    className={isEnabled && !sendBeforeValue.value ? 'hasError' : ''}
                  />
                  {
                    sendBeforeError &&
                    <span className="gErr">Required</span>
                  }
                </div>
              </div>
              <div className="teRw sndBfrAft">
                <div className="lt">
                  <label className="gLbl">Send At</label>
                  <CustomTimePicker
                    value={moment(sendAt).format('hh:mm A')}
                    onChange={onSendAtTimeChange}
                    every15Min={true}
                  />
                </div>
              </div>
            </>
          : null
        }

        {
          props.type === 'booking' || props.type === 'check-in' || props.type === 'check-out'
          ?
            <div className="teRw sndBfrAft">
              <div className="lt">
                <label className="gLbl">Send After
                  <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={"Choose when the email should be sent"}>
                    <span className="tltp">
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </ToolTipWrapper>
                </label>
                <Select
                  options={sendAfterTimes}
                  value={sendAfterValue}
                  onChange={onSendAfterChange}
                  className={isEnabled && !sendAfterValue.value ? 'hasError' : ''}
                />
                {
                  sendAfterError &&
                  <span className="gErr">Required</span>
                }
              </div>
            </div>
          : null
        }
        {
          !props.isReadOnly &&
          <div className="teRw btnRw">
            {
              props.mode === 'add' &&
              <>
                <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onSaveClick} >
                  Add
                  {
                    props.editInProgress && <i className="fa fa-spinner fa-spin" />
                  }
                </button>
                <button className={'gBtn cancel '} onClick={onCancelClick} >
                  Cancel
                </button>
              </>
            }
            {
              props.mode === 'edit' &&
              <button type="submit" className={'gBtn ok ' + (someChange ? '' : 'disabled ')} onClick={onSaveClick} >
                Save Changes
                {
                  props.editInProgress && <i className="fa fa-spinner fa-spin" />
                }
              </button>
            }
          </div>
        }
        {
          props.isReadOnly &&
          <div className="gBCtRw rdOlyRw">
            <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
          </div>
        }
      </>
    }

    { (showEmailTemplateModal) && renderEmailTemplateModal(customTitle) }
    { showDeleteModal &&
      <GenericAlertModal
        modalType="delete"
        title={`Are you sure you want to delete this custom targeted guest journey email?`}
        bodyRowOne={""}
        bodyRowNote=""
        setModalVisible={onShowDeleteModalClose}
        affirmativeButtonLabel="Yes, Delete"
        onAffirmativeClick={onDeleteConfirmClick}
        inProgress={props.data.removeInProgress}
        // currentStatus={guest.checkOutStatus}
        showCancel={true}
      />
    }

    </div>
  );
}

export default SingleTransactionEmail;
