import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';

import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../redux/actions/editDataActions';

import { isGTUser, sendEmailAlert } from '../../../helpers';

import closeIcon from '../../../img/icons/close.svg';

import '../style.css';

const gtconfig = require('../../../gtconfig');

function LocationRow(props) {
  const onConnectClick = (e) => {
    if (props.data.connectionInProgress) return;
    props.onConnectClick(props.data);
  }
  const onReconnectClick = (e) => {
    if (props.data.connectionInProgress) return;
    props.onConnectClick(props.data);
  }
  return (
    <div className="lRow">
      <div className="left">
        {
          props.data.welcome_screen_image_url &&
          <img src={props.data.welcome_screen_image_url} />
        }
      </div>
      <div className="right">
        <p className="name">{props.data.name}
          {
            isGTUser(props.user) &&
            ` ${props.data.location_id} - ${props.data.serverid}`
          }
        </p>
        <p className="address">{props.data.address}</p>
        <div className="cntRw">
          {
            (props.data.whatsapp_connnected || props.data.connected) && !props.data.disconnected
            ? 
              <>
                <span>Connected with Meta Whatsapp</span>
                <button className="gBtn warning" onClick={onReconnectClick}>
                  Re-Connect
                  {
                    props.data.connectionInProgress &&
                    <span><i className="fa fa-spinner fa-spin" /></span>
                  }
                </button>
              </>
            : null
          }
          {
            !props.data.whatsapp_connnected && !props.data.connected
            ?
            <button className="gBtn" onClick={onConnectClick}>
              Connect
              {
                props.data.connectionInProgress &&
                <span><i className="fa fa-spinner fa-spin" /></span>
              }
            </button>
            : null
          }
        </div>
      </div>
    </div>
  );
}

function Whatsapp(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const intgState = useSelector((state) => state.intgReducer);
  const snackState = useSelector((state) => state.snackReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  const selectedLocationRef = useRef(null);
  const fbDataRef = useRef(null);

  const [connectionInProgress, setConnectionInProgress] = useState(false);

  
  const [somethingConnected, setSomethingConnected] = useState(false);
  const [somethingDisconnected, setSomethingDisconnected] = useState(false);
  const [connectedLocationMid, setConnectedLocationMid] = useState('');

  const getData = () => {
    dispatch(getDataAction({
      op: 'get_intg_configure_data',
      params: {
        partner: 'whatsapp',
      },
    }));
  };

  useEffect(() => {
    // SDK initialization
    // window.fbAsyncInit = function() {
    //   window.FB.init({
    //     appId            : '890627742985700',
    //   autoLogAppEvents : true,
    //   xfbml            : true,
    //   version          : 'v22.0'
    //   });
    // };
    function handleFbMessage(event) {
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") return;
      try {
        const data = JSON.parse(event.data);
        fbDataRef.current = data;
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          console.log('handleFbMessage message event: ', data); // remove after testing
          // your code goes here
          sendEmailAlert({
            app: gtconfig.APP_NAME,
            subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - handleFbMessage Done - ${userState.user.user_info.uid}`,
            message: JSON.stringify({ data, selectedLocation: selectedLocationRef.current }),
            // error: msg,
            // store: JSON.stringify(getState()),
          });
        }
      } catch (e) {
        console.log('handleFbMessage error message event: ', event.data); // remove after testing
        sendEmailAlert({
          app: gtconfig.APP_NAME,
          subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - handleFbMessage Error - ${userState.user.user_info.uid}`,
          message: JSON.stringify({ e, selectedLocation: selectedLocationRef.current }),
          error: JSON.stringify(e),
          // store: JSON.stringify(getState()),
        });
      }
    }
    // Add event listener
    window.addEventListener("message", handleFbMessage);

    // Remove event listener on cleanup
    return () => window.removeEventListener("message", handleFbMessage);

  });

  const fbLoginCallback = (response) => {
    setConnectionInProgress(false);
    if (response.authResponse) {
      const code = response.authResponse.code;
      console.log('response code: ', code); // remove after testing
      // your code goes here
      console.log('response fbData: ', fbDataRef.current);
      console.log('response selectedLocationRef: ', selectedLocationRef.current);
      if (code && selectedLocationRef.current && selectedLocationRef.current._id) {
        dispatch(editDataAction({
          op: 'edit_intg_configure_data',
          params: {
            code,
            fbData: fbDataRef.current,
            partner: 'whatsapp',
            location__id: selectedLocationRef.current._id,
          },
        }));
      }
      sendEmailAlert({
        app: gtconfig.APP_NAME,
        subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - fbLoginCallback Done - ${userState.user.user_info.uid}`,
        message: JSON.stringify({ code, selectedLocation: selectedLocationRef.current, fbData: fbDataRef.current, authResponse: response.authResponse }),
        // error: msg,
        // store: JSON.stringify(getState()),
      });
    } else {
      console.log('response: ', response); // remove after testing
      // your code goes here
      sendEmailAlert({
        app: gtconfig.APP_NAME,
        subject: `${gtconfig.APP_NAME}(${gtconfig.APP_ID}) - fbLoginCallback Error - ${userState.user.user_info.uid}`,
        message: JSON.stringify({ response, selectedLocation: selectedLocationRef.current, fbData: fbDataRef.current }),
        error: JSON.stringify(response),
        // store: JSON.stringify(getState()),
      });
    }
  };

  const launchWhatsAppSignup = () => {
    setConnectionInProgress(true);
    fbDataRef.current = null;
    window.FB.login(fbLoginCallback, {
      config_id: '479273318364973', // configuration ID goes here
      response_type: 'code', // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        setup: {"business":{"id":null,"name":null,"email":null,"phone":{"code":null,"number":null},"website":null,"address":{"streetAddress1":null,"streetAddress2":null,"city":null,"state":null,"zipPostal":null,"country":null},"timezone":null},"phone":{"displayName":null,"category":null,"description":null},"preVerifiedPhone":{"ids":null},"solutionID":null,"whatsAppBusinessAccount":{"ids":null}},
        featureType: '',
        sessionInfoVersion: '2',
      }
    });
  };



  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Whatsapp location: ', location);
    }
    dispatch(getDataClearAction({
      op: 'get_intg_configure_data',
      params: {}
    }));
    getData();
  }, []);

  // useEffect(() => {
  //   if (intgState.intg_data && intgState.intg_data.locations && intgState.intg_data.locations_list) {
  //     let connectedFound = false;
  //     intgState.intg_data.locations_list.forEach((item) => {
  //       if (intgState.intg_data.locations[item] && intgState.intg_data.locations[item].connected) {
  //         connectedFound = true;
  //         setConnectedLocationMid(item);
  //       }
  //     });
  //     setSomethingConnected(connectedFound);
  //   }
  // }, [intgState.intg_data])

  const onGetDataClick = (e) => {
    // getData();
  };

  const onConnectClick = (data) => {
    if (connectionInProgress) return;
    
    if (somethingConnected) return;
    if (process.env.NODE_ENV !== 'production') {
      console.log('Whatsapp onConnectClick data: ', data);
    }
    selectedLocationRef.current = data;
    launchWhatsAppSignup();
    // const queryJson = queryString.parse(location.search);
    // const { code } = queryJson;
    // if (code && data && data._id) {
    //   dispatch(editDataAction({
    //     op: 'edit_intg_configure_data',
    //     params: {
    //       code,
    //       partner: 'remote-lock',
    //       location__id: data._id,
    //     },
    //   }));
    // }
  };

  // const onDisconnectClick = (data) => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('RemoteLock onDisconnectClick data: ', data);
  //   }
  //   const queryJson = queryString.parse(location.search);
  //   // const { code } = queryJson;
  //   // if (code && data && data._id) {
  //     dispatch(editDataAction({
  //       op: 'edit_intg_configure_data',
  //       params: {
  //         disconnect: true,
  //         partner: 'remote-lock',
  //         location__id: data._id,
  //       },
  //     }));
  //   // }
  //   setSomethingDisconnected(true);
  // };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Whatsapp: ', props);
    console.log('Whatsapp intgState: ', intgState);
  }

  const { intg_data } = intgState;
  if (!intg_data) return null;

  const { locations_list, locations } = intg_data;

  if (process.env.NODE_ENV !== 'production') {
    console.log('Whatsapp locations_list: ', locations_list);
    console.log('Whatsapp locations: ', locations);
  }

  return (
    <div className="gPScrlWrp flHt allHme">
      <div className="gPWrp homeAllLocsPg">
        <div className="gPTle" onClick={onGetDataClick}>
          <button onClick={launchWhatsAppSignup}>Login with Facebook</button>
        </div>
        <div className="gBCtnt">
          {
            locations_list && locations && locations_list.length
            ?
              locations_list.map((locationId) => {
                if (locations[locationId]) {
                  return (
                    <LocationRow data={locations[locationId]}
                      key={`lr_${locationId}`}
                      user={userState.user} appInfo={appState.appInfo}
                      onConnectClick={onConnectClick}
                      // onDisconnectClick={onDisconnectClick}
                      somethingConnected={somethingConnected}
                    />
                  )
                }
                return null;
              })
            : null
          }
        </div>
        {/* {
          somethingConnected && !somethingDisconnected &&
          <div className="cntFollowUp">
            <p>Congratulations! Remote Lock is now connected. Please procees to Engage to retrieve and map locks.</p>
            <p><Link to={`/settings/locations/${connectedLocationMid}/hotel-details/remote-locks`}>Get Locks</Link></p>
          </div>
        } */}
      </div>
    </div>
  );

}

export default Whatsapp;
