import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import ToolTipWrapper from '../../Misc/ToolTipWrapper';
import useOnClickOutside from '../../Misc/OutsideClickHook';

import Search from '../../Engage/Messages/Search';
import AddGuestSingle from '../../Engage/AddGuest/AddGuestSingle';
import NewMessage from '../../Engage/Messages/NewMessage';
import ReceivedMessages from '../../Engage/Messages/ReceivedMessages';

import { onSideBarToggle, setLocationIdentifier, setGroupIdentifier } from '../../../redux/actions/appActions';
import { productIdToLabels, getProductAccess, isLocationAdminAndAbove,
  getEngageAccess, getMarketingAccess, getResponseGeneratorAccess,
  PRODUCT_TO_LABELS, getUserGAId, 
  RG_PLAN, RG_PLAN_LABEL } from '../../../helpers';

import collapseIcon from '../../../img/icons/collapse.svg';
import searchIcon from '../../../img/icons/search.svg';
import addGuestIcon from '../../../img/icons/add-guest.svg';
import newMessageIcon from '../../../img/icons/new-message.svg';


import currentIcon from '../../../img/icons/current.svg';
import currentIconActive from '../../../img/icons/current-active.svg';
import pastIcon from '../../../img/icons/past.svg';
import pastIconActive from '../../../img/icons/past-active.svg';
import upcomingIcon from '../../../img/icons/upcoming.svg';
import upcomingIconActive from '../../../img/icons/upcoming-active.svg';
import staffIcon from '../../../img/icons/staff.svg';
import staffIconActive from '../../../img/icons/staff-active.svg';
import webtextIcon from '../../../img/icons/webchat.svg';
import webtextIconActive from '../../../img/icons/webchat-active.svg';
import gbmIcon from '../../../img/icons/gbm.png';
import gbmIconActive from '../../../img/icons/gbm-active.png';
import leadsIcon from '../../../img/icons/leads.svg';
import leadsIconActive from '../../../img/icons/leads-active.svg';
import directIcon from '../../../img/icons/direct-messaging.svg';
import directIconActive from '../../../img/icons/direct-messaging-active.svg';

import broadcastIcon from '../../../img/icons/broadcast.svg';
import broadcastIconActive from '../../../img/icons/broadcast-active.svg';

import usageIcon from '../../../img/icons/usage.png';
import usageIconActive from '../../../img/icons/usage-active.png';

import generateIcon from '../../../img/icons/generate.png';
import generateIconActive from '../../../img/icons/generate-active.png';
import overviewIcon from '../../../img/icons/overview.svg';
import overviewIconActive from '../../../img/icons/overview-active.svg';
import statsIcon from '../../../img/icons/stats.svg';
import statsIconActive from '../../../img/icons/stats-active.svg';
import configureIcon from '../../../img/icons/configure.svg';
import configureIconActive from '../../../img/icons/configure-active.svg';
import historyIcon from '../../../img/icons/history.svg';
import historyIconActive from '../../../img/icons/history-active.svg';
import upgradeIcon from '../../../img/icons/upgrade.png';
import upgradeIconActive from '../../../img/icons/upgrade-active.png';

import allLocationsIcon from '../../../img/icons/locations.png';
import allLocationsIconActive from '../../../img/icons/locations-active.png';
import allGroupsIcon from '../../../img/icons/groups.png';
import allGroupsIconActive from '../../../img/icons/groups-active.png';

import dashboardIcon from '../../../img/icons/dashboard.svg';
import dashboardIconActive from '../../../img/icons/dashboard-active.svg';
import reviewsIcon from '../../../img/icons/reviews.svg';
import reviewsIconActive from '../../../img/icons/reviews-active.svg';
import compIcon from '../../../img/icons/comp.png';
import compIconActive from '../../../img/icons/comp-active.png';
import analyticsIcon from '../../../img/icons/analytics.svg';
import analyticsIconActive from '../../../img/icons/analytics-active.svg';
import feedbacksIcon from '../../../img/icons/feedback.svg';
import feedbacksIconActive from '../../../img/icons/feedback-active.svg';
import guestlistIcon from '../../../img/icons/guestlist.svg';
import guestlistIconActive from '../../../img/icons/guestlist-active.svg';
import instantInviteIcon from '../../../img/icons/instant-invite.png';
import instantInviteIconActive from '../../../img/icons/instant-invite-active.png';

import settingsIcon from '../../../img/icons/settings.svg';
import settingsIconActive from '../../../img/icons/settings-active.svg';

import usersIcon from '../../../img/icons/settings/settings-users.svg';
import userActiveIcon from '../../../img/icons/settings/settings-users-active.svg';

import emailMarketingIcon from '../../../img/icons/email-marketing-segments.svg';
import emailMarketingIconActive from '../../../img/icons/email-marketing-segments-active.svg';
import emailMarketingCampaignsIcon from '../../../img/icons/email-marketing-campaigns.svg';
import emailMarketingCampaignsIconActive from '../../../img/icons/email-marketing-campaigns-active.svg';

import './SideBar.css';
import Marketing from "../../Marketing";

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

/* Reputation */

let REP_MENU_OPTIONS = [];
REP_MENU_OPTIONS.push({
  label: 'Dashboard',
  id: 'dashboard',
  icon: dashboardIcon,
  iconActive: dashboardIconActive,
  ttText: 'Dashboard',
});
REP_MENU_OPTIONS.push({
  label: 'Reviews',
  id: 'reviews',
  icon: reviewsIcon,
  iconActive: reviewsIconActive,
  ttText: 'Reviews',
});
REP_MENU_OPTIONS.push({
  label: 'Comp Analysis',
  id: 'comp-analysis',
  icon: compIcon,
  iconActive: compIconActive,
  ttText: 'Comp Analysis',
});
REP_MENU_OPTIONS.push({
  label: 'Sentiments',
  id: 'sentiments',
  icon: analyticsIcon,
  iconActive: analyticsIconActive,
  ttText: 'Sentiments',
});
REP_MENU_OPTIONS.push({
  label: 'Long Term',
  id: 'long-term',
  icon: compIcon,
  iconActive: compIconActive,
  ttText: 'Long Term',
});
REP_MENU_OPTIONS.push({
  label: 'Trends',
  id: 'trends',
  icon: compIcon,
  iconActive: compIconActive,
  ttText: 'Trends',
});
REP_MENU_OPTIONS.push({
  label: 'Direct Feedbacks',
  id: 'feedbacks',
  icon: feedbacksIcon,
  iconActive: feedbacksIconActive,
  ttText: 'Direct Feedbacks',
});
REP_MENU_OPTIONS.push({
  label: 'Instant Invite',
  id: 'instant-invite',
  icon: instantInviteIcon,
  iconActive: instantInviteIconActive,
  ttText: 'Instant Invite',
});
REP_MENU_OPTIONS.push({
  label: 'Guest List',
  id: 'guestlist/overview',
  icon: guestlistIcon,
  iconActive: guestlistIconActive,
  ttText: 'Guest List',
});



/* Engage */

let ENGAGE_MENU_OPTIONS = [];
ENGAGE_MENU_OPTIONS.push({
  type: 'sectionHeader',
  label: 'Guest Messaging',
  id: 'gm',
});
ENGAGE_MENU_OPTIONS.push({
  label: 'Checked In',
  id: 'messages/guests',
  icon: currentIcon,
  iconActive: currentIconActive,
  ttText: 'Checked In',
});
ENGAGE_MENU_OPTIONS.push({
  label: 'Upcoming',
  id: 'messages/upcoming-guests',
  icon: upcomingIcon,
  iconActive: upcomingIconActive,
  ttText: 'Upcoming',
});
ENGAGE_MENU_OPTIONS.push({
  className: 'ss',
  label: 'Checked Out',
  id: 'messages/past-guests',
  icon: pastIcon,
  iconActive: pastIconActive,
  ttText: 'Checked Out',
});
ENGAGE_MENU_OPTIONS.push({
  className: 'ss',
  label: 'Staff',
  id: 'messages/staff',
  icon: staffIcon,
  iconActive: staffIconActive,
  ttText: 'Staff',
});
ENGAGE_MENU_OPTIONS.push({
  type: 'sectionHeader',
  label: 'Business Messaging',
  id: 'wm',
});
ENGAGE_MENU_OPTIONS.push({
  label: 'WebChat',
  id: 'messages/webtext',
  icon: webtextIcon,
  iconActive: webtextIconActive,
  ttText: 'WebChat',
});
// ENGAGE_MENU_OPTIONS.push({
//   label: 'Leads',
//   id: 'messages/leads',
//   icon: leadsIcon,
//   iconActive: leadsIconActive,
//   ttText: 'Leads',
// });
ENGAGE_MENU_OPTIONS.push({
  className: 'ss',
  label: 'Google Messages',
  id: 'messages/gbm',
  icon: gbmIcon,
  iconActive: gbmIconActive,
  ttText: 'Google Messages',
});
ENGAGE_MENU_OPTIONS.push({
  className: 'ss',
  label: 'Direct Messages',
  id: 'messages/outside-guests',
  icon: directIcon,
  iconActive: directIconActive,
  ttText: 'Direct Messages',
});
// ENGAGE_MENU_OPTIONS.push({
//   className: 'ss',
//   label: 'Journey Messages',
//   id: 'journey-messages',
//   icon: broadcastIcon,
//   iconActive: broadcastIconActive,
//   ttText: 'Journey Messages',
// });
ENGAGE_MENU_OPTIONS.push({
  className: 'ss',
  label: 'Broadcast',
  id: 'broadcast',
  icon: broadcastIcon,
  iconActive: broadcastIconActive,
  ttText: 'Broadcast',
});
ENGAGE_MENU_OPTIONS.push({
  label: 'Usage',
  id: 'usage',
  icon: usageIcon,
  iconActive: usageIconActive,
  ttText: 'Usage',
});


/* Marketing */

let MARKETING_MENU_OPTIONS = [];
MARKETING_MENU_OPTIONS.push({
  className: 'ss',
  label: 'Overview',
  id: 'overview',
  icon: overviewIcon,
  iconActive: overviewIconActive,
  ttText: 'Overview',
});
MARKETING_MENU_OPTIONS.push({
  type: 'sectionHeader',
  label: 'Email Marketing',
  id: 'email-marketing',
});
MARKETING_MENU_OPTIONS.push({
  label: 'Campaigns',
  id: 'email-marketing/campaigns',
  icon: emailMarketingCampaignsIcon,
  iconActive: emailMarketingCampaignsIconActive,
  ttText: 'Campaigns',
});
MARKETING_MENU_OPTIONS.push({
  className: 'ss',
  label: 'Segments',
  id: 'email-marketing/segments',
  icon: emailMarketingIcon,
  iconActive: emailMarketingIconActive,
  ttText: 'Segments',
});
MARKETING_MENU_OPTIONS.push({
  type: 'sectionHeader',
  label: 'Transaction Emails',
  id: 'gje',
});
MARKETING_MENU_OPTIONS.push({
  label: 'Create & Manage',
  id: 'transaction-emails/configure',
  icon: configureIcon,
  iconActive: configureIconActive,
  ttText: 'Create & Manage',
});
MARKETING_MENU_OPTIONS.push({
  label: 'Analyze',
  id: 'transaction-emails/stats',
  icon: statsIcon,
  iconActive: statsIconActive,
  ttText: 'Analyze',
});
MARKETING_MENU_OPTIONS.push({
  label: 'History',
  id: 'transaction-emails/history',
  icon: historyIcon,
  iconActive: historyIconActive,
  ttText: 'History',
});
MARKETING_MENU_OPTIONS.push({
  className: 'ss',
  label: 'Reservations',
  id: 'transaction-emails/reservations',
  // icon: currentIcon,
  // iconActive: currentIconActive,
  faIcon: 'fa fa-list',
  faIconActive: 'fa fa-list',
  ttText: 'Reservations',
});
// MARKETING_MENU_OPTIONS.push({
//   type: 'sectionHeader',
//   label: 'Email Marketing',
// });
// MARKETING_MENU_OPTIONS.push({
//   label: 'Contacts',
//   id: 'email-marketing/contacts',
//   icon: feedbacksIcon,
//   iconActive: feedbacksIconActive,
//   ttText: 'Contacts',
// });
// MARKETING_MENU_OPTIONS.push({
//   label: 'Lists',
//   id: 'email-marketing/lists',
//   icon: feedbacksIcon,
//   iconActive: feedbacksIconActive,
//   ttText: 'Lists',
// });
// MARKETING_MENU_OPTIONS.push({
//   label: 'Campaigns',
//   id: 'email-marketing/campaigns',
//   icon: feedbacksIcon,
//   iconActive: feedbacksIconActive,
//   ttText: 'Campaigns',
// });


/* Response Generator */

let RESPONSE_GENERATOR_MENU_OPTIONS = [];
RESPONSE_GENERATOR_MENU_OPTIONS.push({
  className: 'ss',
  label: 'Generate',
  id: 'generate',
  icon: generateIcon,
  iconActive: generateIconActive,
  ttText: 'Generate',
});
RESPONSE_GENERATOR_MENU_OPTIONS.push({
  // className: 'ss',
  label: 'Overview',
  id: 'overview',
  icon: overviewIcon,
  iconActive: overviewIconActive,
  ttText: 'Overview',
});
RESPONSE_GENERATOR_MENU_OPTIONS.push({
  label: 'Configure',
  id: 'configure',
  icon: configureIcon,
  iconActive: configureIconActive,
  ttText: 'Configure',
});
RESPONSE_GENERATOR_MENU_OPTIONS.push({
  className: 'ss',
  label: 'History',
  id: 'history',
  icon: historyIcon,
  iconActive: historyIconActive,
  ttText: 'History',
});
RESPONSE_GENERATOR_MENU_OPTIONS.push({
  className: 'ss',
  label: 'Users',
  id: 'users',
  icon: usersIcon,
  iconActive: userActiveIcon,
  ttText: 'Users',
});
RESPONSE_GENERATOR_MENU_OPTIONS.push({
  className: 'ss upgd',
  label: 'Upgrade',
  id: 'upgrade',
  icon: upgradeIcon,
  iconActive: upgradeIconActive,
  ttText: 'Upgrade',
});


const MenuEntry = ({ className, pathname, label, menuId, onClick, link, icon, iconActive, faIcon, faIconActive, ttText, menuOpen, unreadCount }) => {
  let active = pathname.indexOf(link) > -1;
  if (link === '/reputation/locations') {
    if (pathname === '/reputation/locations' || pathname === '/reputation/locations/') { active = true; } else { active = false; }
  }
  if (link === '/engage/locations') {
    if (pathname === '/engage/locations' || pathname === '/engage/locations/') { active = true; } else { active = false; }
  }
  if (link === '/marketing/locations') {
    if (pathname === '/marketing/locations' || pathname === '/marketing/locations/') { active = true; } else { active = false; }
  }
  return (
    <li className={(className ? className : '') + (active ? ' active' : '')}>
      <ToolTipWrapper placement="right" overlayClassName={'sbTooltip ' + (menuOpen ? 'hide' : '')}
        overlay={ttText}
      >
        {
          menuId === 'upgrade'
          ?
          <div 
            // onClick={onClick}
            {...(menuId === 'upgrade' ? { onClick: onClick } : {})}
          >
            {
              icon &&
              <img src={active ? iconActive : icon} />
            }
            <span>
             {label}
            </span>
          </div>
          :
          <Link to={link}>
            {
              icon &&
              <img src={active ? iconActive : icon} />
            }
            {
              faIcon &&
              <>
                {
                  !active
                  ? <i className={'faGt ' + (faIcon)} />
                  : <i className={'faGt active ' + (faIconActive)} />
                }
              </>
            }
            {
              unreadCount ? <span className="unrCnt">{unreadCount}</span> : null
            }
            <span>{label}</span>
          </Link>
        }
      </ToolTipWrapper>
    </li>
  );
}

function SideBar(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [product, setProduct] = useState('');
  // const [menuOpen, setMenuOpen] = useState(true);
  const [spanStyle, setSpanStyle] = useState({});

  const [showSearchModal, setShowSearchModal] = useState(false);
  const searchModalRef = useRef();
  useOnClickOutside(searchModalRef, useCallback(() => {
      onSearchModalCloseClick();
  }));

  const [showEditGuestModal, setShowEditGuestModal] = useState(false);
  const editGuestModalRef = useRef();
  useOnClickOutside(editGuestModalRef, useCallback(() => {
      onEditGuestModalCloseClick();
  }));

  const [showNewMessageModal, setShowNewMessageModal] = useState(false);
  const newMessageModalRef = useRef();
  useOnClickOutside(newMessageModalRef, useCallback(() => {
      onNewMessageModalCloseClick();
  }));

  const [showReceivedMessagesModal, setShowReceivedMessagesModal] = useState(false);
  const receivedMessagesModalRef = useRef();
  useOnClickOutside(receivedMessagesModalRef, useCallback(() => {
      onReceivedMessagesModalCloseClick();
  }));


  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('SideBar urlParams: ', urlParams);
      console.log('SideBar location: ', location);
    }
    const { pathname } = location;
    if (pathname.indexOf('/reputation') > -1) setProduct('reputation');
    if (pathname.indexOf('/engage') > -1) setProduct('engage');
    if (pathname.indexOf('/marketing') > -1) setProduct('marketing');
    if (pathname.indexOf('/response-generator') > -1) setProduct('response-generator');
    const { locationidentifier, groupidentifier } = urlParams;
    // dispatch(setLocationIdentifier({ locationIdentifier: locationidentifier || '' }));
    dispatch(setGroupIdentifier({ groupIdentifier: groupidentifier || '' }));
  }, [urlParams.groupidentifier, location.pathname]);


  const onSideBarToggleClick = (e) => {
    dispatch(onSideBarToggle({ open: !appState.sideBarOpen }));
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Side Bar Toggle`,
        'event_label': `Side Bar Toggle`,
        page_path: `${location.pathname}`,
        page_title: `Engage - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  // const onSettingsClick = (e) => {
  //   const { locationidentifier } = urlParams;
  //   if (locationidentifier) {
  //     navigate(`/settings/locations/${locationidentifier}/${product}`,
  //       { state: { screenBeforeSettingsName: productIdToLabels[product], screenBeforeSettingsPath: pathname }}
  //     );
  //   }
  // };

  const onSearchClick = (e) => {
    setShowSearchModal(true);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Open Search`,
        'event_label': `Open Search`,
        page_path: `${location.pathname}`,
        page_title: `Engage - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onSearchModalCloseClick = (pathParam) => {
    setShowSearchModal(false);
  };

  const onAddGuestClick = (e) => {
    if (props.isReadOnly) return;
    setShowEditGuestModal(true);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Add Guest`,
        'event_label': `Add Guest`,
        page_path: `${location.pathname}`,
        page_title: `Engage - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onEditGuestModalCloseClick = (pathParam) => {
    setShowEditGuestModal(false);
    // clearGuestEditStatus(); // nkcheck
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEditGuestModalCloseClick pathParam: ', pathParam);
    }
    if (pathParam) {
      navigate(pathParam);
    }
  };

  const onNewMessageClick = (e) => {
    if (props.isReadOnly) return;
    setShowNewMessageModal(true);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - New Message`,
        'event_label': `New Message`,
        page_path: `${location.pathname}`,
        page_title: `Engage - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onNewMessageModalCloseClick = (pathParam) => {
    setShowNewMessageModal(false);
  };

  const onReceivedMessagesClick = (e) => {
    setShowReceivedMessagesModal(true);
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Engage - Open Received Messages`,
        'event_label': `Open Received Messages`,
        page_path: `${location.pathname}`,
        page_title: `Engage - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };
  const onReceivedMessagesModalCloseClick = (pathParam) => {
    setShowReceivedMessagesModal(false);
  };

  const onUpgradeClick = (e) => {
    try { window.Intercom('showNewMessage', `Upgrade Review Response AI to Pro`); } catch(e) {}
  };

  const renderSearchModal = () => {
    if (!urlParams.locationidentifier) return null;
    if (!locationState.locations || !locationState.locations[urlParams.locationidentifier]) return null;
    const { settings, engage } = locationState.locations[urlParams.locationidentifier];
    if (!settings || !engage) return null;

    return (
      <Modal>
        <div id="cModelId" className="cModal addGuestModal">
          <div className="content" ref={searchModalRef}>
            <Search
              settings={settings}
              engage={engage}
              onCancelClick={onSearchModalCloseClick}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderEditGuestModal = () => {
    if (!urlParams.locationidentifier) return null;
    if (!locationState.locations || !locationState.locations[urlParams.locationidentifier]) return null;
    const { settings, engage } = locationState.locations[urlParams.locationidentifier];
    if (!settings || !engage) return null;

    return (
      <Modal>
        <div id="cModelId" className="cModal addGuestModal">
          <div className="content" ref={editGuestModalRef}>
            <AddGuestSingle mode="add" guestType={'guests'}
              settings={settings}
              engage={engage}
              onCancelClick={onEditGuestModalCloseClick}
            />
          </div>
        </div>
      </Modal>
    );
  };


  const renderNewMessageModal = () => {
    if (!urlParams.locationidentifier) return null;
    if (!locationState.locations || !locationState.locations[urlParams.locationidentifier]) return null;
    const { settings, engage } = locationState.locations[urlParams.locationidentifier];
    if (!settings || !engage) return null;

    return (
      <Modal>
        <div id="cModelId" className="cModal addGuestModal">
          <div className="content" ref={newMessageModalRef}>
            <NewMessage
              settings={settings}
              engage={engage}
              onCancelClick={onNewMessageModalCloseClick}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderReceivedMessagesModal = () => {
    if (!urlParams.locationidentifier) return null;
    if (!locationState.locations || !locationState.locations[urlParams.locationidentifier]) return null;
    const { settings, engage } = locationState.locations[urlParams.locationidentifier];
    if (!settings || !engage) return null;

    return (
      <Modal>
        <div id="cModelId" className="cModal addGuestModal">
          <div className="content" ref={receivedMessagesModalRef}>
            <ReceivedMessages
              settings={settings}
              engage={engage}
              onCancelClick={onReceivedMessagesModalCloseClick}
            />
          </div>
        </div>
      </Modal>
    );
  };


  if (!location) return null;
  const { pathname } = location;
  const { locationidentifier, rglocationidentifier } = urlParams;

  let engage = {};
  if (locationidentifier &&
    locationState.locations[locationidentifier]
  ) {
    engage = locationState.locations[locationidentifier].engage ? { ...locationState.locations[locationidentifier].engage } : {};
  }

  const { sideBarOpen } = appState;


  // if (!locationidentifier) return null;

  const hasEngage = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[locationidentifier]
    ? userState.user.user_info.locations[locationidentifier].has_engage
    : false;
  if (product === 'engage' && !hasEngage && locationidentifier) return null;

  const hasGuestMessaging = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[locationidentifier]
    ? userState.user.user_info.locations[locationidentifier].has_guest_messaging
    : false;

  const hasInstantInvite = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[locationidentifier]
    ? userState.user.user_info.locations[locationidentifier].has_instant_invite
    : false;

  const hasCompAnalysis = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[locationidentifier]
    ? userState.user.user_info.locations[locationidentifier].has_comp_analysis
    : false;

  const hasReputationTrend = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[locationidentifier]
    ? userState.user.user_info.locations[locationidentifier].has_reputation_trend
    : false;

  const hasReputationLongTerm = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[locationidentifier]
    ? userState.user.user_info.locations[locationidentifier].has_reputation_long_term
    : false;

  const hasTransactionEmail = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[locationidentifier]
    ? userState.user.user_info.locations[locationidentifier].has_transaction_emails
    : false;
  const hasEmailMarketing = userState && userState.user && userState.user.user_info
    && userState.user.user_info.locations
    && userState.user.user_info.locations[locationidentifier]
    ? userState.user.user_info.locations[locationidentifier].has_email_marketing
    : false;
  if (product === 'marketing' && (!hasTransactionEmail && !hasEmailMarketing) && locationidentifier) return null;

  let { hasAccessToReputation, hasAccessToEngage, hasAccessToMarketing, hasAccessToResponseGenerator  } = getProductAccess(userState.user.user_info, locationidentifier);

  let accessLevel = userState.user.user_info && userState.user.user_info.profile &&
    userState.user.user_info.profile.access_level ? userState.user.user_info.profile.access_level : '';

  const { hasAccessToBroadcast } = getEngageAccess(userState.user.user_info, locationidentifier);
  const { hasAccessToManageTransactionEmails } = getMarketingAccess(userState.user.user_info, locationidentifier);
  const { hasAccessToResponseGeneratorConfigure, hasAccessToResponseGeneratorUsers } = getResponseGeneratorAccess(userState.user.user_info, rglocationidentifier);

  // let hasAccessToReputation = false, hasAccessToEngage = false, hasAccessToMarketing = false;
  // if (userState.user.user_info && userState.user.user_info.profile) {
  //   const { profile } = userState.user.user_info;
  //   if (profile.locations && profile.locations[locationidentifier]) {
  //     if (profile.locations[locationidentifier].reputation &&
  //       profile.locations[locationidentifier].access_level === 'GENERIC_USER'
  //     ) {
  //       hasAccessToReputation = true;
  //     }
  //     if (profile.locations[locationidentifier].engage &&
  //       profile.locations[locationidentifier].access_level === 'GENERIC_USER'
  //     ) {
  //       hasAccessToEngage = true;
  //     }
  //     if (profile.locations[locationidentifier].marketing &&
  //       profile.locations[locationidentifier].access_level === 'GENERIC_USER'
  //     ) {
  //       hasAccessToMarketing = true;
  //     }
  //     if (isLocationAdminAndAbove(profile.locations[locationidentifier].access_level)) {
  //       hasAccessToReputation = true;
  //       hasAccessToEngage = true;
  //       hasAccessToMarketing = true;
  //     }
  //   }
  //   if (isGroupAdminAndAbove(profile.access_level)) {
  //     hasAccessToReputation = true;
  //     hasAccessToEngage = true;
  //     hasAccessToMarketing = true;
  //   }
  // }

  const rgPlan = userState.user.user_info && userState.user.user_info.profile
    ? userState.user.user_info.profile.rg_plan || '' : '';

  let productSubLabel = '';
  if (product === 'response-generator') {
    if (userState.user.user_info && userState.user.user_info.profile &&
      userState.user.user_info.profile.rg_plan) {
        productSubLabel = RG_PLAN_LABEL[userState.user.user_info.profile.rg_plan] || '';
      }
  }


  return (
    <div className={'sb' + (!sideBarOpen ? ' collapsed' : '')}>
      <div className={'fxd top' + (product === 'engage' && locationidentifier ? ' locSlctd' : '')}>
        <div className="prdRow">
          <ToolTipWrapper placement="right" overlayClassName={'sbTooltip ' + (sideBarOpen ? 'h1ide' : '')}
            overlay={sideBarOpen ? 'Collapse' : productIdToLabels[product]}
          >
            <span className="mTg" onClick={onSideBarToggleClick}><img className={(sideBarOpen ? '' : 'rotateRight180')} src={collapseIcon} /></span>
          </ToolTipWrapper>
          { sideBarOpen && 
            <div className="pTtl">
              {PRODUCT_TO_LABELS[product]}
              { productSubLabel && <span>{productSubLabel}</span> }
            </div> 
          }
        </div>
        {
          product === 'engage' && locationidentifier && hasAccessToEngage &&
          <div className="aRow">
            <ToolTipWrapper placement={sideBarOpen ? 'bottom' : 'top'} overlayClassName={'sbTooltip'}
              overlay="Search Guests"
            >
              <span className="mTg" onClick={onSearchClick}><img src={searchIcon} /></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement={sideBarOpen ? 'bottom' : 'top'} overlayClassName={'sbTooltip'}
              overlay="Add Guest"
            >
              <span className="mTg" onClick={onAddGuestClick}><img src={addGuestIcon} /></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
              overlay="New Message"
            >
              <span className="mTg" onClick={onNewMessageClick}><img src={newMessageIcon} /></span>
            </ToolTipWrapper>
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
              overlay="Received Messages"
            >
              <span className="mTg fa" onClick={onReceivedMessagesClick}><i className="fa-solid fa-angles-down"></i></span>
            </ToolTipWrapper>
          </div>
        }
      </div>
      <div className="mn">
        {
          product === 'reputation' &&
          <ul id="menu" >
            <MenuEntry className="ss" label="All Locations" pathname={pathname} menuOpen={sideBarOpen}
              key={`sb_rep_all_locations`}
              link={`/reputation/locations`}
              icon={allLocationsIcon} iconActive={allLocationsIconActive}
              ttText={`All Locations`}
            />
            {
              isLocationAdminAndAbove(accessLevel) &&
              <MenuEntry className="ss" label="All Groups" pathname={pathname} menuOpen={sideBarOpen}
                key={`sb_rep_all_groups`}
                link={`/reputation/groups`}
                icon={allGroupsIcon} iconActive={allGroupsIconActive}
                ttText={`All Groups`}
              />
            }
            {
              locationidentifier && hasAccessToReputation &&
              REP_MENU_OPTIONS.map((entry) => {
                if (!hasInstantInvite && entry.id === 'instant-invite') return null;
                if (!hasCompAnalysis && entry.id === 'comp-analysis') return null;
                if (!hasReputationTrend && entry.id === 'trends') return null;
                if (!hasReputationLongTerm && entry.id === 'long-term') return null;
                return (
                  <MenuEntry className={entry.className ? entry.className : ''} label={entry.label} pathname={pathname} menuOpen={sideBarOpen}
                    key={`sb_rep_${entry.id}`}
                    link={`/reputation/locations/${locationidentifier}/${entry.id}`}
                    icon={entry.icon} iconActive={entry.iconActive}
                    ttText={entry.ttText}
                  />
                )
              })
            }
          </ul>
        }
        {
          product === 'engage' &&
          <ul id="menu" >
            <MenuEntry className="ss" label="All Locations" pathname={pathname} menuOpen={sideBarOpen}
              key={`sb_rep_all_locations`}
              link={`/engage/locations`}
              icon={allLocationsIcon} iconActive={allLocationsIconActive}
              ttText={`All Locations`}
            />
            {
              locationidentifier && hasAccessToEngage &&
              ENGAGE_MENU_OPTIONS.map((entry) => {
                if (entry.type === 'sectionHeader') {
                  if (!hasGuestMessaging) return null;
                  return (
                    <li className="sh" key={`sb_eng_sh_${entry.id}`}>
                      <p>{entry.label}</p>
                    </li>
                  )
                }
                if (!hasGuestMessaging && entry.id === 'messages/guests') return null;
                if (!hasGuestMessaging && entry.id === 'messages/past-guests') return null;
                if (!hasGuestMessaging && entry.id === 'messages/upcoming-guests') return null;
                if (entry.id === 'broadcast' && !hasAccessToBroadcast) return null;
                let unreadCount = 0;
                if (entry.id === 'messages/guests') { unreadCount = engage.guests ? engage.guests.unreadCount || 0 : 0 }
                if (entry.id === 'messages/past-guests') { unreadCount = engage.pastGuests ? engage.pastGuests.unreadCount || 0 : 0 }
                if (entry.id === 'messages/upcoming-guests') { unreadCount = engage.upcomingGuests ? engage.upcomingGuests.unreadCount || 0 : 0 }
                if (entry.id === 'messages/outside-guests') { unreadCount = engage.outsideGuests ? engage.outsideGuests.unreadCount || 0 : 0 }
                if (entry.id === 'messages/staff') { unreadCount = engage.staff ? engage.staff.unreadCount || 0 : 0 }
                if (entry.id === 'messages/webtext') { unreadCount = engage.webtext ? engage.webtext.unreadCount || 0 : 0 }
                if (entry.id === 'messages/gbm') { unreadCount = engage.gbm ? engage.gbm.unreadCount || 0 : 0 }
                return (
                  <MenuEntry className={entry.className ? entry.className : ''} menuOpen={sideBarOpen}
                    label={entry.label} pathname={pathname}
                    key={`sb_eng_${entry.id}`}
                    link={`/engage/locations/${locationidentifier}/${entry.id}`}
                    icon={entry.icon} iconActive={entry.iconActive}
                    ttText={entry.ttText}
                    unreadCount={unreadCount}
                  />
                )
              })
            }
          </ul>
        }
        {
          product === 'marketing' &&
          <ul id="menu" >
            <MenuEntry className="ss" label="All Locations" pathname={pathname} menuOpen={sideBarOpen}
              key={`sb_rep_all_locations`}
              link={`/marketing/locations`}
              icon={allLocationsIcon} iconActive={allLocationsIconActive}
              ttText={`All Locations`}
            />
            {
              locationidentifier && hasAccessToMarketing &&
              MARKETING_MENU_OPTIONS.map((entry) => {
                if (entry.type === 'sectionHeader') {
                  if (!hasEmailMarketing && entry.id === 'email-marketing') return null;
                  return (
                    <li className="sh" key={`sb_mkt_sh_${entry.id}`}>
                      <p>{entry.label}</p>
                    </li>
                  )
                }
                if (entry.id === 'transaction-emails/configure' && !hasAccessToManageTransactionEmails) return null;
                
                if (!hasEmailMarketing && entry.id === 'email-marketing/campaigns') return null;
                if (!hasEmailMarketing && entry.id === 'email-marketing/segments') return null;

                return (
                  <MenuEntry className={entry.className ? entry.className : ''} menuOpen={sideBarOpen}
                    label={entry.label} pathname={pathname}
                    key={`sb_mkt_${entry.id}`}
                    link={`/marketing/locations/${locationidentifier}/${entry.id}`}
                    icon={entry.icon} iconActive={entry.iconActive}
                    faIcon={entry.faIcon} faIconActive={entry.faIconActive}
                    ttText={entry.ttText}
                  />
                )
              })
            }
          </ul>
        }
        {
          product === 'response-generator' &&
          <ul id="menu" >
            {
              hasAccessToResponseGenerator &&
              RESPONSE_GENERATOR_MENU_OPTIONS.map((entry) => {
                if (entry.type === 'sectionHeader') {
                  return (
                    <li className="sh" key={`sb_mkt_sh_${entry.id}`}>
                      <p>{entry.label}</p>
                    </li>
                  )
                }
                if (entry.id === 'configure' && !hasAccessToResponseGeneratorConfigure) return null;
                if (entry.id === 'users' && !hasAccessToResponseGeneratorUsers) return null;
                if (entry.id === 'upgrade' && rgPlan === RG_PLAN.PRO) return null;

                return (
                  <MenuEntry className={entry.className ? entry.className : ''} menuOpen={sideBarOpen}
                    label={entry.label} pathname={pathname}
                    key={`sb_mkt_${entry.id}`}
                    link={`/response-generator/${entry.id}`}
                    menuId={entry.id}
                    {...(entry.id === 'upgrade' ? { onClick: onUpgradeClick } : {})}
                    icon={entry.icon} iconActive={entry.iconActive}
                    faIcon={entry.faIcon} faIconActive={entry.faIconActive}
                    ttText={entry.ttText}
                  />
                )
              })
            }
          </ul>
        }
      </div>
      {
        locationidentifier &&
        (
          (product === 'reputation' && hasAccessToReputation) ||
          (product === 'engage' && hasAccessToEngage) ||
          (product === 'marketing' && hasAccessToMarketing)
        ) &&
        <div className="fxd bottom">
          <div className="prdRow sts">
            <ToolTipWrapper placement="right" overlayClassName={'sbTooltip ' + (sideBarOpen ? 'hide' : '')}
              overlay={`${productIdToLabels[product]} settings`}
            >
              <Link to={`/settings/locations/${locationidentifier}/${product}`}
                state={{ screenBeforeSettingsName: productIdToLabels[product], screenBeforeSettingsPath: pathname }}
              >
                <img src={settingsIcon} />
                <span>{productIdToLabels[product]} Settings</span>
              </Link>
              {/*<p onClick={onSettingsClick}>Settings</p>*/}
            </ToolTipWrapper>
          </div>
        </div>
      }
      {/*<Routes>
        <Route path="locations" element={<LocationsWrapper />}>
          <Route path=":locationidentifier/*" element={<LocationWrapper />} />
          <Route index element={<Locations />} />
        </Route>
      </Routes>*/}
      { showSearchModal && renderSearchModal() }
      { showEditGuestModal && renderEditGuestModal() }
      { showNewMessageModal && renderNewMessageModal() }
      { showReceivedMessagesModal && renderReceivedMessagesModal() }
    </div>
  );
}

export default SideBar;
