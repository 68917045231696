import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import Pagination from 'rc-pagination';


import ToolTipWrapper from '../../../Misc/ToolTipWrapper';

import ReviewRatingChartFaux from '../../Charts/ReviewRatingChartFaux';
import LongTermChart from '../../Charts/LongTermChart';
import LongTermLineChart from "../../Charts/LongTermLineChart";
import LongTermMultiLineChart from "../../Charts/LongTermMultiLineChart";

import { setCommonParams } from '../../../../redux/actions/appActions';
import { getDataAction } from '../../../../redux/actions/getDataActions';

import { DATE_DURATION_OPTIONS_MAP, OTA_ORDER, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP,
  renderOTALogo, REVIEW_TABS_TO_RS_MAP, OTA_SOURCE_TO_NAME_MAP, getReviewState,
  ratingToInt, RS, RATING_TO_STRING_MAP, REVIEW_TABS_MAP,
  getReputationAccess, getUserGAId, OTA_SOURCE_TO_NAME_FORMAL_MAP } from '../../../../helpers';

import searchIcon from '../../../../img/icons/search.svg';
import closeIcon from '../../../../img/icons/close.svg';
import downloadIcon from '../../../../img/icons/download.svg';

import './style.css';


var Scroll   = require('react-scroll');
var scroll = Scroll.animateScroll;

const { RangePicker } = DatePicker;

const gtconfig = require('../../../../gtconfig');

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });
lastMonthOptions.push({ label: 'Last 18 Months', value: 'last-18-months' });
lastMonthOptions.push({ label: 'Last 24 Months', value: 'last-24-months' });



function LongTerm(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 6 Months', value: 'last-6-months' }
  );
  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(6, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());

  const [showTrends, setShowTrends] = useState(true);


  const getReviewsData = (reviewState, startDate, endDate) => {
    if (props.isGroup) {
      dispatch(getDataAction({
        op: 'get_long_term_data',
        params: {
          group_id: urlParams.groupidentifier,
          review_state: reviewState,
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
          // keyword,
          // ota_filter: otaFilter,
          // rating_filter: ratingFilter,
          // skip: pageSize * (pageNumber - 1),
          // limit: pageSize,
        },
      }));
    } else {
      dispatch(getDataAction({
        op: 'get_long_term_data',
        params: {
          location__id: urlParams.locationidentifier,
          review_state: reviewState,
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
          // keyword,
          // ota_filter: otaFilter,
          // rating_filter: ratingFilter,
          // skip: pageSize * (pageNumber - 1),
          // limit: pageSize,
        },
      }));
    }
    setTimeout(() => {
      scroll.scrollToTop({
        duration: 100,
        delay: 0,
        smooth: true,
        // containerId: 'app',
        // offset: 50, // Scrolls to element + 50 pixels down the page
      });
    }, 300);
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Reputation - LongTerm - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect locationidentifier: ', urlParams.locationidentifier);
    }
    // const { commonParams } = appState;
    // let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
    // let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
    // let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
    // let dateUpdated = commonParams ? commonParams.dateUpdated || new Date(moment().subtract(3, 'days')) : new Date(moment().subtract(3, 'days'));
    // if (moment().diff(dateUpdated, 'hours') > 24) {
    //   startDate = new Date(moment().subtract(1, 'month'));
    //   endDate = new Date();
    // }
    // setDateFilterStartDate(startDate);
    // setDateFilterEndDate(endDate);
    // setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
    // getReviewsData(selectedTab, startDate, endDate, filterKeyword, otaFilterValues, ratingFilterValues, 1);

    const { has_self_response, has_gt_response, has_self_and_gt_response, otas } = props.locationInfo;
    const { locationidentifier } = urlParams;

    // getReviewsData('all', startDate, endDate);
    getReviewsData('all', dateFilterStartDate, dateFilterEndDate);

  }, [urlParams.locationidentifier]);


  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect props.reputation.reviews: ', props.reputation.reviews);
    }
  }, [props.reputation.reviews]);

  const onDateRangeLastMonthChange = (value) => {
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      // dispatch(setCommonParams({
      //   params: {
      //     rangeKeyword: rangeValue,
      //     startDate: new Date(moment().subtract(period, 'days')),
      //     endDate: new Date(),
      //     dateUpdated: new Date(),
      //   },
      // }));
      // getReviewsData(new Date(moment().subtract(period, 'days')), new Date());
      getReviewsData('all', new Date(moment().subtract(period, 'days')), new Date());
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      // dispatch(setCommonParams({
      //   params: {
      //     rangeKeyword: rangeValue,
      //     startDate: new Date(moment().subtract(period, 'month')),
      //     endDate: new Date(),
      //     dateUpdated: new Date(),
      //   },
      // }));
      // getReviewsData(new Date(moment().subtract(period, 'month')), new Date());
      getReviewsData('all', new Date(moment().subtract(period, 'month')), new Date());
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - LongTerm - Date Change Last`,
        'event_label': `Date Change Last - ${rangeValue}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - LongTerm - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { reviews } = props.reputation;
    if (!reviews || reviews.getInProgress) return;
    // setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    // dispatch(setCommonParams({
    //   params: {
    //     rangeKeyword: 'select',
    //     startDate: new Date(dates[0]),
    //     endDate: new Date(dates[1]),
    //     dateUpdated: new Date(),
    //   },
    // }));
    // getReviewsData(dates[0], dates[1]);
    getReviewsData('all', dates[0], dates[1]);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - LongTerm - Date Change Range`,
        'event_label': `Date Change Range - ${dateStrings}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - LongTerm - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };


  const onShowTrendsClick = (e) => {
    setShowTrends(showTrends => !showTrends);
  };


  const renderDateFilterRow = (reviews) => {
    const { has_csv_download_reviews } = props.locationInfo;
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            {
              props.isGroup && urlParams.groupidentifier && props.groupName &&
              <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
                overlay={"Group Name, click to go to Group Overview"}
              >
                <Link to={`/reputation/groups/${urlParams.groupidentifier}/dashboard`}><span>{props.groupName}</span></Link>
              </ToolTipWrapper>
            }
            Long Term Data
          </div>
        </div>
        <div className="dateFilter">
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };


  const renderTrendsChart = (long_term) => {
    if (props.isGroup) return null;
    if (!long_term.data) return null;
    return (
      <div className="revsTrndBx">
        <div className="chartBox">
          <LongTermLineChart
            windowState={windowState}
            width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
            height={160}
            data={long_term.data ? long_term.data.monthly || {} : {}}
            timePeriod='monthly'
            attributeName='average_rating'
            yLabel='Average Rating'
            cType='average_rating'
          />
        </div>
        <div className="chartBox">
          <LongTermChart
            className='lngtrm'
            windowState={windowState}
            width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
            height={160}
            data={long_term.data ? long_term.data.monthly || {} : {}}
            timePeriod='monthly'
            attributeName='total_reviews'
            yLabel='Number of reviews'
            cType='total_reviews'
          />
        </div>
        <div className="chartBox">
          <LongTermChart
            className='lngtrm'
            windowState={windowState}
            width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
            height={160}
            data={long_term.data ? long_term.data.monthly || {} : {}}
            timePeriod='monthly'
            attributeName='average_rating'
            yLabel='Average Rating'
            cType='average_rating_1'
          />
        </div>
        <div className="chartBox">
          <LongTermChart
            className={`lngtrm rating_1`}
            windowState={windowState}
            width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
            height={160}
            data={long_term.data ? long_term.data.monthly || {} : {}}
            timePeriod='monthly'
            attributeName='rating_1'
            yLabel='1 Star reviews count'
            cType='rating_1'
          />
        </div>
        <div className="chartBox">
          <LongTermChart
            className={`lngtrm rating_5`}
            windowState={windowState}
            width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
            height={160}
            data={long_term.data ? long_term.data.monthly || {} : {}}
            timePeriod='monthly'
            attributeName='rating_5'
            yLabel='5 Star reviews count'
            cType='rating_5'
          />
        </div>
        {
          long_term.data.otas && long_term.data.otas.length > 0 &&
          long_term.data.otas.map((ota) => {
            return (
              <div className="chartBox">
                <LongTermChart
                  windowState={windowState}
                  className={`lngtrm ota_${ota}`}
                width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
                height={160}
                data={long_term.data ? long_term.data.monthly || {} : {}}
                timePeriod='monthly'
                attributeName={`ota_${ota}`}
                yLabel={`Number of ${OTA_SOURCE_TO_NAME_FORMAL_MAP[ota]} Reviews`}
                cType={`ota_${ota}`}
                />
              </div>
            )
          })
        }
        <div className="chartBox">
          <LongTermChart
            className={`lngtrm`}
            windowState={windowState}
            width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
            height={160}
            data={long_term.data ? long_term.data.monthly || {} : {}}
            timePeriod='monthly'
            attributeName='reservations_checked_out'
            yLabel='Number of Reservations'
            cType='reservations_checked_out'
          />
        </div>
        <div className="chartBox">
          <LongTermChart
            className={`lngtrm`}
            windowState={windowState}
            width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
            height={160}
            data={long_term.data ? long_term.data.monthly || {} : {}}
            timePeriod='monthly'
            attributeName='reservations_per_review'
            yLabel='Number of Reservations per Review'
            cType='reservations_per_review'
          />
        </div>
        <div className="chartBox">
          <LongTermLineChart
            windowState={windowState}
            width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
            height={160}
            data={long_term.data ? long_term.data.monthly || {} : {}}
            timePeriod='monthly'
            attributeName='reviews_per_reservation'
            yLabel='Reviews per Reservation'
            cType='reviews_per_reservation'
          />
        </div>
        <div className="chartBox">
          <LongTermChart
            className={`lngtrm`}
            windowState={windowState}
            width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
            height={160}
            data={long_term.data ? long_term.data.monthly || {} : {}}
            timePeriod='monthly'
            attributeName='response_rate'
            yLabel='Response Rate'
            cType='response_rate'
          />
        </div>
        <div className="chartBox">
          <LongTermMultiLineChart
            windowState={windowState}
            width={(windowState.window.windowWidth - 196 - 1 - 40 - 20 + 16 )}
            height={160}
            data={long_term.data ? long_term.data.monthly || {} : {}}
            timePeriod='monthly'
            attributeName='reservations_checked_out'
            attribute2Name='total_reviews'
            yLabel='Number of Reservations / Total Reviews'
            cType='reservations_checked_out1'
          />
        </div>
      </div>
    )
  };







  if (process.env.NODE_ENV !== 'production') {
    console.log('LongTerm urlParams: ', urlParams);
    console.log('LongTerm props: ', props);
  }

  const { reviews, long_term } = props.reputation;
  if (!long_term) return null;


  return (
    <>
      {/*
        reviews.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      */}
      {
        long_term.getInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg revsPg">
          { renderDateFilterRow(reviews) }
          <div className="revsBd">
            <div className="lt">
              { renderTrendsChart(long_term) }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LongTerm;
