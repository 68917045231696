import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Highlighter from "react-highlight-words";

import { whatsappMetaTemplatePreview } from '../../../../../helpers';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';

import editIcon from '../../../../../img/icons/edit.svg';
import deleteIcon from '../../../../../img/icons/delete.svg';

function WhatsappTemplateRow(props) {
  const { template } = props;
  if (process.env.NODE_ENV !== 'production') {
    console.log('WhatsappTemplateRow template: ', template);
  }
  let metaTemplateStr = '';
  if (template.meta_template && template.meta_template.components && template.meta_template.components.length) {
    metaTemplateStr = whatsappMetaTemplatePreview(template);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('WhatsappTemplateRow metaTemplateStr: ', metaTemplateStr);
  }
  return (
    <div className="gBCtRw msgTplRw" id={`wt_${template._id}`}>
      <div className="tp">
        <div className="lt">
          {
            props.keyword
            ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.title}
              />
            : template.title
          }
        </div>
        <div className="rt">
          {
            template.status === 'added' &&
            <div className="aprvPndg">Added - Approval Pending</div>
          }
          {
            template.status === 'approved' &&
            <div className="aprvPndg">Approved</div>
          }
        </div>
      </div>
      <div className="bt">
        {
          template.msg
          &&
          <>
            {
              props.keyword
              ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={template.msg}
              />
              : template.msg
            }
          </>
        }
        {
          template.meta_template
          &&
          <>
            {
              props.keyword
              ?
              <Highlighter
                highlightClassName="highlightClass"
                searchWords={[props.keyword]}
                autoEscape={true}
                textToHighlight={metaTemplateStr}
              />
              : metaTemplateStr
            }
          </>
        }
      </div>
    </div>
  )

}

export default WhatsappTemplateRow;
