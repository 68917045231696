// @flow

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import { Button, DatePicker } from 'antd';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import CustomTimePicker from '../../../../Misc/CustomTimePicker';

import { segmentTargetToLabel, getSegmentNumberOfNightsLabel,
  TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP, STRS, LANGUAGE_LIST_KEYS } from '../../../../../helpers';

import closeIcon from '../../../../../img/icons/close.svg';
import requiredIcon from '../../../../../img/icons/required.svg';
import oneTimeIcon from '../../../../../img/icons/broadcast-onetime.svg';
import oneTimeActiveIcon from '../../../../../img/icons/broadcast-onetime-active.svg';
import recurringIcon from '../../../../../img/icons/broadcast-recurring.svg';
import recurringActiveIcon from '../../../../../img/icons/broadcast-recurring-active.svg';

import './style.css';

const { RangePicker } = DatePicker;

var Scroll   = require('react-scroll');
var Element  = Scroll.Element;
var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;



const timeConstraintsScheduleTime = { minutes: { step: 15 }};
const yesterday = moment().subtract(1, 'day');

const targetOptions = [];
targetOptions.push({ label: 'All Past Guests', value: 'all-past-guests' });
targetOptions.push({ label: 'All Future Guests', value: 'all-future-guests' });
targetOptions.push({ label: 'Currently Staying', value: 'checked-in' });

const numberOfNightsOptions = [];
numberOfNightsOptions.push({ label: 'Select', value: 'select' });
numberOfNightsOptions.push({ label: '1 Night', value: '1-night' });
numberOfNightsOptions.push({ label: 'More than 1 Night', value: 'more-than-1-night' });
numberOfNightsOptions.push({ label: '2 Nights', value: '2-nights' });
numberOfNightsOptions.push({ label: 'More than 2 Nights', value: 'more-than-2-nights' });
numberOfNightsOptions.push({ label: '3 Nights', value: '3-nights' });
numberOfNightsOptions.push({ label: 'More than 3 Nights', value: 'more-than-3-nights' });
numberOfNightsOptions.push({ label: '5 Nights', value: '5-nights' });
numberOfNightsOptions.push({ label: 'More than 5 Nights', value: 'more-than-5-nights' });
numberOfNightsOptions.push({ label: '7 Nights', value: '7-nights' });
numberOfNightsOptions.push({ label: 'More than 7 Nights', value: 'more-than-7-nights' });
numberOfNightsOptions.push({ label: '10 Nights', value: '10-nights' });
numberOfNightsOptions.push({ label: 'More than 10 Nights', value: 'more-than-10-nights' });
numberOfNightsOptions.push({ label: '14 Nights', value: '14-nights' });
numberOfNightsOptions.push({ label: 'More than 14 Nights', value: 'more-than-14-nights' });
numberOfNightsOptions.push({ label: '30 Nights', value: '30-nights' });
numberOfNightsOptions.push({ label: 'More than 30 Nights', value: 'more-than-30-nights' });



const checkInDateSelectOptions = [];
checkInDateSelectOptions.push({ label: 'Same Day', value: 'same-day' });
checkInDateSelectOptions.push({ label: '1 day later', value: '1-day-later' });
checkInDateSelectOptions.push({ label: '2 days later', value: '2-days-later' });
checkInDateSelectOptions.push({ label: '3 days later', value: '3-days-later' });
checkInDateSelectOptions.push({ label: '7 days later', value: '7-days-later' });
checkInDateSelectOptions.push({ label: '10 days later', value: '10-days-later' });
checkInDateSelectOptions.push({ label: '30 days later', value: '30-days-later' });

const daysSinceCheckInSelectOptions = [];
daysSinceCheckInSelectOptions.push({ label: '1 Day', value: '1-day' });
daysSinceCheckInSelectOptions.push({ label: '2 Days', value: '2-day' });
daysSinceCheckInSelectOptions.push({ label: '3 Days', value: '3-day' });

const departingDateSelectOptions = [];
departingDateSelectOptions.push({ label: 'Same Day', value: 'same-day' });
departingDateSelectOptions.push({ label: 'Next Day', value: 'next-day' });

const daysArr = [];
daysArr.push({ label: 'Mon', 'value': 'monday' });
daysArr.push({ label: 'Tue', 'value': 'tuesday' });
daysArr.push({ label: 'Web', 'value': 'wednesday' });
daysArr.push({ label: 'Thu', 'value': 'thursday' });
daysArr.push({ label: 'Fri', 'value': 'friday' });
daysArr.push({ label: 'Sat', 'value': 'saturday' });
daysArr.push({ label: 'Sun', 'value': 'sunday' });

const personalizeOptionsG = [];
personalizeOptionsG.push({ label: 'Personalize', value: 'personalize' });
personalizeOptionsG.push({ label: 'First Name', value: '{{firstname}}' });
personalizeOptionsG.push({ label: 'Last Name', value: '{{lastname}}' });

const customizeForOptionsG = [];
customizeForOptionsG.push({ label: 'Rate Code', value: 'rate-code' });
customizeForOptionsG.push({ label: 'Booking Source', value: 'booking-source' });
customizeForOptionsG.push({ label: 'Room Type', value: 'room-type' });
customizeForOptionsG.push({ label: 'Room Number', value: 'room-number' });
customizeForOptionsG.push({ label: 'Guest Language', value: 'guest-lang' });

const MessageTemplateButton = (props) => {
  const onButtonClick = (e) => { props.onButtonClick(props.template); };

  return (
    <ToolTipWrapper placement="right" overlayClassName={'sbTooltip'}
      overlay={props.template.msg}
    >
    <div className="lEntry" onClick={onButtonClick} data-tip data-for={`tt_mt_${props.template._id}`}>
      {props.template.title}
      {/*<ReactTooltip id={`tt_mt_${props.template._id}`} data-html={true}
            insecure={true}
            multiline={true} className="messageTemplateTT">
        <span>{props.template.msg}</span>
      </ReactTooltip>*/}
    </div>
    </ToolTipWrapper>
  )
};

function SingleDay(props) {
  const onClick = (e) => {
    props.onSingleDayClick(props.day);
  }
  return (
    <div className={'singleDay ' + (props.selected ? 'selected' : '')} onClick={onClick}>
      {props.day.label}
    </div>
  );
}

function SingleTag(props) {
  const onRemoveClick = (e) => {
    props.onRemoveTagClick(props.tag);
  };
  if (!props.tag) return null;
  return (
    <div className="singleTag">
      {props.tag.label}
      <span onClick={onRemoveClick}>
        {
          props.tag.editInProgress
          ? <i className="fa fa-spinner fa-spin" />
          : <i className="fa fa-times"></i>
        }
      </span>
    </div>
  );
}

function SegmentSingle(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  const location = useLocation();

  const [broadcastType, setBroadcastType] = useState('');

  const [targetValue, setTargetValue] = useState(
    { label: 'All Past Guests', value: 'all-past-guests' }
  );

  const [lastMonthOptions, setLastMonthOptions] = useState([]);
  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    // { label: 'Last 1 Month', value: 'last-1-month' }
    { label: 'Select', value: 'select' }
  );
  // const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  // const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());
  const [dateFilterStartDate, setDateFilterStartDate] = useState('');
  const [dateFilterEndDate, setDateFilterEndDate] = useState('');

  const [targetError, setTargetError] = useState('');
  
  const [numberOfNightsSelectValue, setNumberOfNightsSelectValue] = useState({});

  const [customizeForOptions, setCustomizeForOptions] = useState([]);
  const [customizeForValue, setCustomizeForValue] = useState({});
  const [customizeForError, setCustomizeForError] = useState('');

  const [customValueOptions, setCustomValueOptions] = useState([]);
  const [customValue, setCustomValue] = useState(null);
  const [customValueError, setCustomValueError] = useState('');

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [someChange, setSomeChange] = useState(false);

  const messageEl = useRef(null);

  const updateLastMonthOptions = (target) => {
    if (target === 'all-past-guests') {
      const tOptions = [];
      tOptions.push({ label: 'Select', value: 'select' });
      tOptions.push({ label: 'Clear', value: 'clear' });
      for (let i = 2023; i <= 2025; i++) {
        tOptions.push({ label: `Year ${i}`, value: `year-${i}` });
      }
      for (let i = 2023; i <= 2025; i++) {
        for (let j = 1; j <= 4; j++) {
          tOptions.push({ label: `${j}st Qtr Year ${i}`, value: `qtr-${j}-year-${i}` });
        }
      }
      tOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
      tOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
      tOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
      tOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
      tOptions.push({ label: '1st Qtr Year 2024', value: 'qtr-1-year-2024' });
      tOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
      tOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
      tOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
      tOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
      setLastMonthOptions(tOptions);
    }
    if (target === 'all-future-guests') {
      const tOptions = [];
      tOptions.push({ label: 'Select', value: 'select' });
      for (let i = 2025; i <= 2026; i++) {
        tOptions.push({ label: `Year ${i}`, value: `year-${i}` });
      }
      for (let i = 2025; i <= 2026; i++) {
        for (let j = 1; j <= 4; j++) {
          tOptions.push({ label: `${j}st Qtr Year ${i}`, value: `qtr-${j}-year-${i}` });
        }
      }
      setLastMonthOptions(tOptions);
    }
  };


  useEffect(() => {
    dispatch(editDataClearAction({
      op: 'edit_email_marketing_segment',
      params: {
        location__id: urlParams.locationidentifier,
        mode: 'add',
      },
    }));
    if (props.mode === 'add' || props.mode === 'edit') {
      // let tempTagOptions = [];
      // if (props.settings && props.settings.engage && props.settings.engage.labels &&
      //   props.settings.engage.labels.list && props.settings.engage.labels.list.length) {
      //   props.settings.engage.labels.list.forEach((item) => {
      //     if (props.settings.engage.labels.keyValue[item]) {
      //       tempTagOptions.push({ label: props.settings.engage.labels.keyValue[item].label, value: props.settings.engage.labels.keyValue[item]._id });
      //     }
      //   });
      // }
      // setTagOptions(tempTagOptions);
      updateLastMonthOptions(targetValue.value);
      // tempTagOptions = [];
      // if (props.settings && props.settings.engage && props.settings.engage.mews_products &&
      //   props.settings.engage.mews_products.list && props.settings.engage.mews_products.list.length) {
      //   props.settings.engage.mews_products.list.forEach((item) => {
      //     if (props.settings.engage.mews_products.keyValue[item]) {
      //       tempTagOptions.push({ label: props.settings.engage.mews_products.keyValue[item].name, value: props.settings.engage.mews_products.keyValue[item]._id });
      //     }
      //   });
      // }
      // setMewsProductOptions(tempTagOptions);
    }
    setCustomizeForOptions([
      ...customizeForOptionsG,
      // ...newOptions,
    ]);

    

    setTimeout(() => {
      scroll.scrollToTop({
        duration: 100,
        delay: 0,
        smooth: true,
        containerId: 'broadcastWrapId',
        // offset: 50, // Scrolls to element + 50 pixels down the page
      });
    }, 300);
  }, []);

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('broadcast location.search: ', location.search);
  //   }
  //   const { success } = queryString.parse(location.search);
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('broadcast success: ', success);
  //   }
  //   if (!success) {
  //     dispatch(editDataClearAction({
  //       op: 'edit_broadcast',
  //       params: {
  //         location__id: urlParams.locationidentifier,
  //         mode: 'add',
  //       },
  //     }));
  //   }
  // }, [location.search]);

  useEffect(() => {
    if (props.mode === 'edit' && props.segmentId) {
      const segmentInfo = segments.keyValue[props.segmentId];
      if (process.env.NODE_ENV !== 'production') {
        console.log('segmentInfo: ', segmentInfo);
      }
      if (segmentInfo) {
        if (segmentInfo.target) {
          let temp = { label: segmentTargetToLabel[segmentInfo.target], value: segmentInfo.target };
          setTargetValue(temp);
          if (segmentInfo.start_date_yyyymmdd) setDateFilterStartDate(new Date(moment(segmentInfo.start_date_yyyymmdd, 'YYYY-MM-DD')));
          if (segmentInfo.end_date_yyyymmdd) setDateFilterEndDate(new Date(moment(segmentInfo.end_date_yyyymmdd, 'YYYY-MM-DD')));
          if (segmentInfo.number_of_nights) setNumberOfNightsSelectValue({ label: getSegmentNumberOfNightsLabel(segmentInfo.number_of_nights), value: segmentInfo.number_of_nights });
          if (segmentInfo.custom_for) {
            setCustomizeForValue({
              label: TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP[segmentInfo.custom_for], // nkcheck
              value: segmentInfo.custom_for,
            });
            if (segmentInfo.custom_value) {
              if (segmentInfo.custom_for === 'rate-code') {
                updateCustomizeForRateCodeOptions();
              }
              if (segmentInfo.custom_for === 'booking-source') {
                updateCustomizeForBookingSourceOptions();
              }
              if (segmentInfo.custom_for === 'room-type') {
                updateCustomizeForRoomTypeOptions();
              }
              if (segmentInfo.custom_for === 'room-number') {
                updateCustomizeForRoomNumberOptions();
              }
              if (segmentInfo.custom_for === 'guest-lang') {
                updateCustomizeForGuestLangOptions();
              }
              let label = '';
              if (segmentInfo.custom_for === 'rate-code') {
                label = props.settings.rate_codes && props.settings.rate_codes.keyValue && props.settings.rate_codes.keyValue[segmentInfo.custom_value]
                  ? props.settings.rate_codes.keyValue[segmentInfo.custom_value].label : '';
              }
              if (segmentInfo.custom_for === 'booking-source') {
                label = props.settings.booking_sources && props.settings.booking_sources.keyValue && props.settings.booking_sources.keyValue[segmentInfo.custom_value]
                  ? props.settings.booking_sources.keyValue[segmentInfo.custom_value].label : '';
              }
              if (segmentInfo.custom_for === 'room-type' || segmentInfo.custom_for === 'room-number') {
                label = props.settings.rooms && props.settings.rooms.keyValue && props.settings.rooms.keyValue[segmentInfo.custom_value]
                  ? props.settings.rooms.keyValue[segmentInfo.custom_value].label : '';
              }
              if (process.env.NODE_ENV !== 'production') {
                console.log('segmentInfo.custom_for: ', segmentInfo.custom_for);
                console.log('segmentInfo.custom_value: ', segmentInfo.custom_value);
                console.log('label: ', label);
              }
              setCustomValue([
                ...segmentInfo.custom_value
                // label,
                // value: props.data.custom_value,
              ]);
            }
          }
        }
        // let tempTagOptions = [];
        // if (props.settings && props.settings.engage && props.settings.engage.labels &&
        //   props.settings.engage.labels.list && props.settings.engage.labels.list.length
        // ) {
        //   props.settings.engage.labels.list.forEach((item) => {
        //     if (props.settings.engage.labels.keyValue[item]) {
        //       if (broadcast.target_labels && broadcast.target_labels.length) {
        //         if (!broadcast.target_labels.find(el => el._id === item)) {
        //           tempTagOptions.push({ label: props.settings.engage.labels.keyValue[item].label, value: item });
        //           if (process.env.NODE_ENV !== 'production') {
        //             console.log('added: ', props.settings.engage.labels.keyValue[item]);
        //           }
        //         }
        //       } else {
        //         tempTagOptions.push({ label: props.settings.engage.labels.keyValue[item].label, value: item });
        //       }
        //     }
        //   });
        // }
        // if (process.env.NODE_ENV !== 'production') {
        //   console.log('tempTagOptions: ', tempTagOptions);
        // }
        // setTagOptions(tempTagOptions);
        // if (broadcast.target_labels && broadcast.target_labels.length) {
        //   let tempAllSelectedTags = [];
        //   broadcast.target_labels.forEach((item) => {
        //     tempAllSelectedTags.push(item);
        //   });
        //   setAllSelectedTags(tempAllSelectedTags);
        // }
        // // mews products
        // tempTagOptions = [];
        // if (props.settings && props.settings.engage && props.settings.engage.mews_products &&
        //   props.settings.engage.mews_products.list && props.settings.engage.mews_products.list.length
        // ) {
        //   props.settings.engage.mews_products.list.forEach((item) => {
        //     if (props.settings.engage.mews_products.keyValue[item]) {
        //       if (broadcast.target_mews_products && broadcast.target_mews_products.length) {
        //         if (!broadcast.target_mews_products.find(el => el._id === item)) {
        //           tempTagOptions.push({ label: props.settings.engage.mews_products.keyValue[item].name, value: item });
        //           if (process.env.NODE_ENV !== 'production') {
        //             console.log('added: ', props.settings.engage.mews_products.keyValue[item]);
        //           }
        //         }
        //       } else {
        //         tempTagOptions.push({ label: props.settings.engage.mews_products.keyValue[item].name, value: item });
        //       }
        //     }
        //   });
        // }
        // if (process.env.NODE_ENV !== 'production') {
        //   console.log('tempTagOptions: ', tempTagOptions);
        // }
        // setMewsProductOptions(tempTagOptions);
        // if (broadcast.target_mews_products && broadcast.target_mews_products.length) {
        //   let tempAllSelectedTags = [];
        //   broadcast.target_mews_products.forEach((item) => {
        //     tempAllSelectedTags.push(item);
        //   });
        //   setAllSelectedMewsProducts(tempAllSelectedTags);
        // } // end of mews products

        // if (broadcast.when) setWhen(broadcast.when);
        // if (broadcast.when_all_days) setWhenAllDays(true);
        // if (broadcast.when_days) setWhenWeekDays(broadcast.when_days);
        // if (broadcast.when_schedule_date) setScheduleDate(new Date(moment(broadcast.when_schedule_date, 'YYYY-MM-DD')));
        // if (broadcast.when_schedule_time) setScheduleTime(moment(broadcast.when_schedule_time, 'k:mm'));
        // setMessage(broadcast.message);
        setName(segmentInfo.name);
      }
    }
  }, [props.segmentId])


  useEffect(() => {
    const { segments } = props;
    if (props.mode === 'add' && !segments.addInProgress && segments.addStatus && segments.addStatus.success) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('add success');
        // setSomeChange(false);
        // setBroadcastType('');
        // setMessage('');
        // setName('');
        // let tempTagOptions = [];
        // if (props.locationprops.tags && props.locationprops.tags.list && props.locationprops.tags.list.length) {
        //   props.locationprops.tags.list.forEach((item) => {
        //     if (props.locationprops.tags.keyValue[item]) {
        //       tempTagOptions.push({ label: props.locationprops.tags.keyValue[item].label, value: props.locationprops.tags.keyValue[item]._id });
        //     }
        //   });
        // }
        // setTagOptions(tempTagOptions);
        // setTagSelectValue([]);
        // setAllSelectedTags([]);
      }
      // navigate(`/engage/locations/${urlParams.locationidentifier}/broadcast/new?success=1`);
      resetData();
    }
  }, [props.segments.addInProgress]);

  useEffect(() => {
    if (props.mode === 'add') {
      resetData();
    }
  }, [props.mode]);

  useEffect(() => {
    if (someChange && !props.segments.addInProgress &&
      props.segments.addStatus && props.segments.addStatus.success) {
      dispatch(editDataClearAction({
        op: 'edit_email_marketing_segment',
        params: {
          location__id: urlParams.locationidentifier,
          mode: 'add',
        },
      }));
    }
    if (someChange && props.segmentId && props.segments.keyValue &&
      props.segments.keyValue[props.segmentId] &&
      !props.segments.keyValue[props.segmentId].editInProgress &&
      props.segments.keyValue[props.segmentId].editStatus &&
      props.segments.keyValue[props.segmentId].editStatus.success) {
      dispatch(editDataClearAction({
        op: 'edit_email_marketing_segment',
        params: {
          location__id: urlParams.locationidentifier,
          segment_id: props.segmentId,
          mode: 'edit',
        },
      }));
    }
  }, [someChange]);

  const resetData = () => {
    setSomeChange(false);
    setName('');
    // const { engage } = props.settings;
    // let tempTagOptions = [];
    // if (engage.labels && engage.labels.list && engage.labels.list.length) {
    //   engage.labels.list.forEach((item) => {
    //     if (engage.labels.keyValue[item]) {
    //       tempTagOptions.push({ label: engage.labels.keyValue[item].label, value: engage.labels.keyValue[item]._id });
    //     }
    //   });
    // }
    // setTagOptions(tempTagOptions);
    // setTagSelectValue([]);
    // setAllSelectedTags([]);
  };

  const onCloseClick = (e) => {
    props.onCloseClick(e);
  };

  const onTargetChange = (value) => {
    setTargetValue(value);
    // updateLastMonthOptions(value.value);

    // if (value.value === 'all-past-guests') {
    //   setDateFilterStartDate(new Date(moment().startOf('year').subtract(1, 'year')));
    //   setDateFilterEndDate(new Date(moment().startOf('year').subtract(1, 'year').endOf('year')));
    // }
    // if (value.value === 'all-future-guests') {
    //   setDateFilterStartDate(new Date(moment().startOf('year').add(1, 'year')));
    //   setDateFilterEndDate(new Date(moment().startOf('year').add(1, 'year').endOf('year')));
    // }

    setTargetError('');
    setSomeChange(true);
  };

  const onDateRangeLastMonthChange = (value) => {
    // setDateRangeLastMonthValue(value);
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    const rangeValue = value.value;
    if (rangeValue.indexOf('clear') > -1) {
      setDateFilterStartDate('');
      setDateFilterEndDate('');
    }
    if (rangeValue.indexOf('year-') > -1) {
      let period = rangeValue.split('year-')[1];
      setDateFilterStartDate(new Date(moment(period, 'YYYY').startOf('year')))
      setDateFilterEndDate(new Date(moment(period, 'YYYY').endOf('year')))
    }
    if (rangeValue.indexOf('qtr-') > -1) { // qtr-1-year-2024
      let period = rangeValue.split('qtr-')[1]; // 1-year-2024
      let qtr = period.split('-')[0];
      let year = period.split('year-')[1];
      setDateFilterStartDate(new Date(moment(year, 'YYYY').quarter(qtr).startOf('quarter')))
      setDateFilterEndDate(new Date(moment(year, 'YYYY').quarter(qtr).endOf('quarter')))
    }
    setSomeChange(true);
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    setSomeChange(true);
  };

  const onNumberOfNightsSelectChange = (value) => {
    setNumberOfNightsSelectValue(value);
    setSomeChange(true);
  };

  const onNameChange = (e) => {
    const { name, value } = e.target;
    setName(value);
    setNameError(value ? '' : 'error');
    setSomeChange(true);
  };

  const onCustomizeForChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCustomizeForChange: ', value);
    }
    setCustomizeForValue(value);
    setCustomValue(null);
    if (value && value.value === 'rate-code') {
      updateCustomizeForRateCodeOptions();
    }
    if (value && value.value === 'booking-source') {
      updateCustomizeForBookingSourceOptions();
    }
    if (value && value.value === 'room-type') {
      updateCustomizeForRoomTypeOptions();
    }
    if (value && value.value === 'room-number') {
      updateCustomizeForRoomNumberOptions();
    }
    if (value && value.value === 'guest-lang') {
      updateCustomizeForGuestLangOptions();
    }
    setCustomizeForError(value && value.value ? '' : 'error');
    setSomeChange(true);
  };

  const onCustomeValueChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onCustomeValueChange: ', value);
    }
    setCustomValue(value);
    setCustomValueError(value && value.length ? '' : 'error');
    setSomeChange(true);
  };
  const customValueNoOptionsMessage = () => {
    if (customizeForValue.value === 'rate-code') return 'No rate codes available!'
    if (customizeForValue.value === 'booking-source') return 'No booking sources available!'
    if (customizeForValue.value === 'room-type') return 'No rooms available!'
    if (customizeForValue.value === 'room-number') return 'No rooms available!'
    return 'No options!';
  };

  const updateCustomizeForRateCodeOptions = () => {
    const rateCodesList = props.settings.rate_codes && props.settings.rate_codes.list && props.settings.rate_codes.keyValue
     ? props.settings.rate_codes.list.filter(el => props.settings.rate_codes.keyValue[el] && props.settings.rate_codes.keyValue[el]._id ? true : false)
        .map((el) => {
          return { label: props.settings.rate_codes.keyValue[el].label, value: props.settings.rate_codes.keyValue[el]._id };
        })
    : [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('rateCodesList: ', rateCodesList);
    }
    setCustomValueOptions([
      ...rateCodesList,
    ]);
  };

  const updateCustomizeForBookingSourceOptions = () => {
    const bookingSourcesList = props.settings.booking_sources && props.settings.booking_sources.list && props.settings.booking_sources.keyValue
     ? props.settings.booking_sources.list.filter(el => props.settings.booking_sources.keyValue[el] && props.settings.booking_sources.keyValue[el]._id ? true : false)
        .map((el) => {
          return { label: props.settings.booking_sources.keyValue[el].label, value: props.settings.booking_sources.keyValue[el]._id };
        })
    : [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('bookingSourcesList: ', bookingSourcesList);
    }
    setCustomValueOptions([
      ...bookingSourcesList,
    ]);
  };

  const updateCustomizeForRoomTypeOptions = () => {
    const roomTypesList = props.settings.rooms && props.settings.rooms.list && props.settings.rooms.keyValue
     ? props.settings.rooms.list.filter(el => props.settings.rooms.keyValue[el] && props.settings.rooms.keyValue[el].attr && props.settings.rooms.keyValue[el].attr['room-type'] ? true : false)
        .map((el) => {
          return { label: props.settings.rooms.keyValue[el].label, value: props.settings.rooms.keyValue[el]._id };
        })
    : [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('roomTypesList: ', roomTypesList);
    }
    setCustomValueOptions([
      ...roomTypesList,
    ]);
  };

  const updateCustomizeForRoomNumberOptions = () => {
    const roomNumbersList = props.settings.rooms && props.settings.rooms.list && props.settings.rooms.keyValue
     ? props.settings.rooms.list.filter(el => props.settings.rooms.keyValue[el] && props.settings.rooms.keyValue[el].attr && props.settings.rooms.keyValue[el].attr['room-number'] ? true : false)
        .map((el) => {
          return { label: props.settings.rooms.keyValue[el].label, value: props.settings.rooms.keyValue[el]._id };
        })
    : [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('roomNumbersList: ', roomNumbersList);
    }
    setCustomValueOptions([
      ...roomNumbersList,
    ]);
  };

  const updateCustomizeForGuestLangOptions = () => {
    const langList = props.settings.engage && props.settings.engage.te_guest_lang_list && Object.keys(props.settings.engage.te_guest_lang_list).length
     ? Object.keys(props.settings.engage.te_guest_lang_list)
        .map((el) => {
          return { label: LANGUAGE_LIST_KEYS[el], value: el };
        })
    : [];
    if (process.env.NODE_ENV !== 'production') {
      console.log('langList: ', langList);
    }
    setCustomValueOptions([
      ...langList,
    ]);
  };

  
  const onCreateClick = (e) => {
    if (props.segments.addInProgress) return;

    if (process.env.NODE_ENV !== 'production') {
      console.log('onCreateClick mode: ', props.mode);
      console.log('onCreateClick broadcastType: ', broadcastType);
      console.log('onCreateClick targetValue: ', targetValue);
      console.log('onCreateClick dateFilterStartDate: ', dateFilterStartDate);
      console.log('onCreateClick dateFilterEndDate: ', dateFilterEndDate);
      console.log('onCreateClick numberOfNightsSelectValue: ', numberOfNightsSelectValue);
    }

    performSave();

  };

  const onEditClick = (e) => {
    const { segments, segmentId } = props;
    if (segments && segments.keyValue && segmentId && segments.keyValue[segmentId] &&
      segments.keyValue[segmentId].editInProgress) return;

    if (process.env.NODE_ENV !== 'production') {
      console.log('onEditClick mode: ', props.mode);
      console.log('onEditClick props: ', props);
      console.log('onEditClick targetValue: ', targetValue);
      console.log('onEditClick dateFilterStartDate: ', dateFilterStartDate);
      console.log('onEditClick dateFilterEndDate: ', dateFilterEndDate);
      console.log('onEditClick numberOfNightsSelectValue: ', numberOfNightsSelectValue);
    }

    performSave();
  };

  const performSave = () => {
    const { segmentId, segments } = props;
    if (props.mode === 'edit' && segmentId) {
      if (segments && segments.keyValue && segments.keyValue[segmentId] && segments.keyValue[segmentId].editInProgress) return;
    }
    if (props.mode === 'add') {
      if (segments.addInProgress) return;
    }

    setTargetError(targetValue && targetValue.value ? '' : 'error');
    setNameError(name ? '' : 'error');
    
    if (!targetValue || !targetValue.value || !name) return;

    if (customizeForValue && customizeForValue.value) {
      setCustomValueError(customValue && customValue.length ? '' : 'error');
      if (!customValue || !customValue.length) return;
    }


    if (targetValue && targetValue.value && name) {
      const params = {
        mode: 'add',
        location__id: urlParams.locationidentifier,
        name,
      };
      if (props.mode === 'edit') {
        params.mode = 'edit';
        params.segment_id = props.segmentId;
      }
      params.target = targetValue.value;
      if (dateFilterStartDate && dateFilterEndDate) {
        params.start_date = moment(dateFilterStartDate).format('YYYY-MM-DD');
        params.end_date = moment(dateFilterEndDate).format('YYYY-MM-DD');
      }
      if (numberOfNightsSelectValue && numberOfNightsSelectValue.value) {
        params.number_of_nights = numberOfNightsSelectValue.value;
      }
      if (customizeForValue && customizeForValue.value) params.custom_for = customizeForValue.value;
      if (customValue && customValue.length) params.custom_value = customValue;
      
      // if (targetValue.value === 'upcoming') {
      //   if (broadcastType === 'one-time') {
      //     params.target_check_in_date = moment(checkInDate).format('YYYY-MM-DD');;
      //   }
      //   if (broadcastType === 'recurring') {
      //     params.target_check_in_days_ahead = checkInDateSelectValue.value;
      //   }
      // }
      // if (targetValue.value === 'departing-soon') {
      //   params.target_check_out_days_ahead = departingDateSelectValue.value;
      // }
      // params.when = when;
      // if (broadcastType === 'recurring') {
      //   if (whenAllDays) {
      //     params.when_all_days = true;
      //     params.when_days = {};
      //   }
      //   else {
      //     params.when_days = whenWeekDays;
      //   }
      // }
      // if (broadcastType === 'one-time') {
      //   if (when === 'later') {
      //     params.when_schedule_date = moment(scheduleDate).format('YYYY-MM-DD');
      //     params.when_schedule_time = moment(scheduleTime).format('k:mm');
      //   }
      // }
      // if (broadcastType === 'recurring') {
      //   if (when === 'later') {
      //     if (targetValue.value === 'checked-in' || targetValue.value === 'staff') {
      //       params.when_schedule_time = moment(scheduleTime).format('k:mm');
      //     }
      //     if (targetValue.value === 'upcoming' || targetValue.value === 'departing-soon') {
      //       params.when_schedule_time = moment(scheduleTime).format('k:mm');
      //     }
      //   }
      // }
      if (process.env.NODE_ENV !== 'production') {
        console.log('performSave: ', params);
      }
      dispatch(editDataAction({
        op: 'edit_email_marketing_segment',
        params,
      }));
      setSomeChange(false);
    }
  };

  const renderCustom = () => {
    return (
      <div className="targetTagRow cstmTypVal">
        <div className="lt">
          <label className="gLbl">Customize for (Optional)</label>
          <Select
            options={customizeForOptions}
            value={customizeForValue}
            onChange={onCustomizeForChange}
          />
          {
            customizeForError &&
            <span className="error">Required</span>
          }
        </div>
        <div className="rt">
        {
          customizeForValue && customizeForValue.value === 'repeat-visitors'
          ? null
          :
            <>
              <label className="gLbl">
                Choose Value
                {
                  customizeForValue && customizeForValue.value === 'rate-code' &&
                  <Link to={`/settings/locations/${props.locationInfo._id}/hotel-details/rate-codes`}>Add rate code</Link>
                }
                {
                  customizeForValue && customizeForValue.value === 'booking-source' &&
                  <Link to={`/settings/locations/${props.locationInfo._id}/hotel-details/booking-sources`}>Add booking source</Link>
                }
                {
                  customizeForValue &&
                  (customizeForValue.value === 'room-type' || customizeForValue.value === 'room-number') &&
                  <Link to={`/settings/locations/${props.locationInfo._id}/hotel-details/rooms`}>Add room</Link>
                }
              </label>
              <Select
                isMulti={true}
                options={customValueOptions}
                value={customValue}
                onChange={onCustomeValueChange}
                noOptionsMessage={customValueNoOptionsMessage}
              />
              {
                customValueError &&
                <span className="gErr">Required</span>
              }
            </>
        }
        </div>
      </div>
    )
  };

  const renderTarget = () => {
    return (
      <div className="cardWrap">
        <div className="titleRow">
          <p className="title">Select Audience</p>
          <p className="info">Choose guests to whom message will be sent.</p>
        </div>
        <div className="targetRow">
          <div className="targetBox">
            <Select
              options={targetOptions}
              onChange={onTargetChange}
              value={targetValue}
            />
          </div>
        </div>
        {
          targetError &&
          <div className="errorRow">
            <div className="gErr">
              <img src={requiredIcon} /> Audience is required
            </div>
          </div>
        }
        {
          targetValue && targetValue.value && (targetValue.value === 'all-past-guests' || targetValue.value === 'all-future-guests') &&
          <div className="targetTagRow">
            <div className="itemBox dtBox">
              <div className="gLabel">
                Select Dates (Optional)
              </div>
              <div className="gLabelInfo">
                Choose dates to select a group of guests within eligible guests.
              </div>
              <div className="dateFilter">
                <div className="dateFilterLastMonth">
                  <Select
                    className="dateFilterLastMonthSelect"
                    options={lastMonthOptions}
                    onChange={onDateRangeLastMonthChange}
                    value={dateRangeLastMonthValue}
                  />
                </div>
                {
                  dateFilterStartDate && dateFilterEndDate &&
                  <div className="dateFilterRange">
                    <RangePicker
                      value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
                      onChange={onDateRangeChange}
                      format="DD MMM YYYY"
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        }
        <div className="targetTagRow">
          <div className="itemBox tagBox">
            <div className="gLabel">
              Select Number of nights (Optional)
            </div>
            <div className="gLabelInfo">
              Choose number of nights to select a group of guests within eligible guests.
            </div>
            <div className="gInput">
              <Select
                options={numberOfNightsOptions}
                onChange={onNumberOfNightsSelectChange}
                value={numberOfNightsSelectValue}
                isMulti={false}
                placeholder="Select Labels"
              />
            </div>
          </div>
        </div>
        { renderCustom() }
      </div>
    );
  }
  
  const renderName = () => {
    return (
      <div className="cardWrap">
        <div className="titleRow">
          <p className="title">Segment Name</p>
        </div>
        <div className="nameRow">
          <div className="itemBox">
            <div className="gIpt">
              <input placeholder="Name" name="name" onChange={onNameChange} value={name} />
            </div>
            {
              nameError &&
              <div className="gErr">
                <img src={requiredIcon} /> Name is required
              </div>
            }
          </div>
        </div>
      </div>
    );
  };

  const renderStatus = (segments, segmentInfo) => {
    if (segments.addStatus && segments.addStatus.error) {
      return (
        <div className="statusRow">
          <p>{ props.mode === 'add' ? 'Error in creating!' : 'Error in updating!'}</p>
        </div>
      );
    }
    if (segments.addStatus && segments.addStatus.success) {
      return (
        <div className="statusRow">
          <p>Created successfully.</p>
        </div>
      );
    }
    if (segmentInfo && segmentInfo.editStatus && segmentInfo.editStatus.error) {
      return (
        <div className="statusRow">
          <p>Error in updating!</p>
        </div>
      );
    }
    if (segmentInfo && segmentInfo.editStatus && segmentInfo.editStatus.success) {
      return (
        <div className="statusRow">
          <p>Updated successfully.</p>
        </div>
      );
    }
    return null;
  }

  const renderButtons = (segments, segmentInfo) => {
    let buttonText = 'Add';
    if (props.mode === 'edit') buttonText = 'Update';
    return (
      <div className="buttonsRow">
        {
          props.mode === 'add' &&
          <button className={'create ' + (someChange ? '' : 'disabled')} onClick={onCreateClick}>
            {buttonText} { segments.addInProgress && <i className="fa fa-spinner fa-spin" /> }
          </button>
        }
        {
          props.mode === 'edit' &&
          <button className={'create edit ' + (someChange ? '' : 'disabled')} onClick={onEditClick}>
            {buttonText} { segmentInfo.editInProgress && <i className="fa fa-spinner fa-spin" /> }
          </button>
        }
      </div>
    );
  }


  const renderContent = (segments, segmentInfo) => {
    return (
      <>
        <div className="snglSgmnt">
          { renderName() }
          { renderTarget() }
          { renderStatus(segments, segmentInfo) }
          { !props.isReadOnly && renderButtons(segments, segmentInfo) }
          {
            props.isReadOnly &&
            <div className="gBCtRw rdOlyRw">
              <div className="gBtnReadOnly">{STRS.READ_ONLY_DESC}</div>
            </div>
          }
        </div>
      </>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('SegmentSingle props: ', props);
  }

  const { segmentId, segments } = props;
  if (!segments) return null;

  const segmentInfo = segments && segments.keyValue && segmentId && segments.keyValue[segmentId];

  return (
    <div className="gPWrp">
      <div className="gPTle title">
        {props.mode === 'add' ? 'Add New Segment' : 'Edit Segment'} {props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
      </div>
      {
        segmentInfo && segmentInfo.name &&
        <div className="gPSubTle title">
          {segmentInfo.name}
        </div>
      }
      <div className="gBCtnt">
        { renderContent(segments, segmentInfo) }
      </div>
    </div>
  );
}

export default SegmentSingle;
