import React, {Component, useEffect, useRef, useState} from 'react';
import {withFauxDOM} from 'react-faux-dom'
import moment from 'moment';

import * as d3 from "d3";
import './style.css';

const MIN_BAR_WIDTH = 24;


function LongTermMultiLineChart(props) {
  const svgRef = useRef();
  const [margin, setMargin] = useState({ top: 60, right: 60, bottom: 60, left: 60 });
  const [barWidth, setBarWidth] = useState(40);
  const [data, setData] = useState([]);
  const [canvasHeight, setCanvasHeight] = useState(360);
  const [canvasWidth, setCanvasWidth] = useState(360);

  const [chartUpdating, setChartUpdating] = useState(false);

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(props.timePeriod);


  useEffect(() => {
    setCanvasHeight(props.height);
    // setCanvasWidth(props.windowprops.window.windowWidth/2 - 100);
    setCanvasWidth(props.width - 120 - 20);
    setData([
      { period: 'jan', value: 2, average_rating: 4 },
      { period: 'feb', value: 14, average_rating: 5},
      { period: 'mar', value: 6, average_rating: 1 },
      { period: 'apr', value: 8, average_rating: 2 },
      { period: 'may', value: 10, average_rating: 5 },
    ]);
    drawChart();
  }, []);

  useEffect(() => {
    if (props.data) {
      // console.log('props.data changed: ', props.data);
      let tempCanvasWidth = props.width - 120 - 20;
      if (selectedTimePeriod === 'monthly' && props.data) {
        setData(props.data);
        setBarWidth((tempCanvasWidth/props.data.length)/2);
      }
    }
  }, [props.data]);

  useEffect(() => {
    // console.log('data changed');
    setChartUpdating(true);
    updateChart('ratings', selectedTimePeriod);
  }, [data, canvasWidth, canvasHeight]);

  useEffect(() => {
    setCanvasHeight(props.height);
    setCanvasWidth(props.width - 120 - 20);
    updateChart('ratings', selectedTimePeriod);
  }, [props.windowState.window.windowHeight]);



  const scaleValueXAxis = d3.scaleBand()
    // .domain([0,1,2,3,4])
    .domain(d3.range(0, data.length))
    .range([0,canvasWidth]);

  const scaleValueYAxis = d3.scaleLinear()
    // .domain([0, d3.max(data, function(d) { return parseFloat(d[props.attributeName]); })])
    .domain([0, d3.max(data, function(d) { return Math.max(parseFloat(d[props.attributeName]), parseFloat(d[props.attribute2Name])); })])
    .range([canvasHeight,0]);

  const scaleValueYAxis2 = d3.scaleLinear()
    .domain([0, d3.max(data, function(d) { return parseFloat(d[props.attribute2Name]); })])
    .range([canvasHeight,0]);

  const scaleValueXAxisOrdinal = d3.scaleBand()
  // .domain(["jan","feb","mar","apr","may"])
  .domain(data.map(el => moment(el.period_str, 'YYYY-MM').format('MMM YYYY')))
  .range([0, canvasWidth]);


  const drawChart = () => {
    // const margin = 30;

    const faux = props.connectFauxDOM('div', 'chart');

    const scale = 20;
    const svgCanvas = d3.select(faux)
      .append('svg')
      .attr('width', canvasWidth + margin.left + margin.right)
      .attr('height', canvasHeight + margin.top + margin.bottom)
      // .style('border', '1px solid #EEE')


    // Add scale to Y axis
    const y_axis = d3.axisLeft()
                   .scale(scaleValueYAxis);

    // Add Y axis
    svgCanvas.append("g")
      .attr("class", "y axis 1")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .call(y_axis);

    
    // Add scale to X axis
    const x_axis = d3.axisBottom()
                   .scale(scaleValueXAxisOrdinal);

    // Add X axis
    svgCanvas.append("g")
    .attr("class", "x axis")
      .attr("transform", "translate(" + margin.left + "," + (canvasHeight+margin.bottom) + ")")
      .call(x_axis);

    
    // Add g tag for line chart
    svgCanvas.append("g")
      .attr('id', 'lineChart');

    // Define the div for the tooltip
    const div = d3.select("body").append("div")
      .attr("class", "lineChartTooltip")
      .style("opacity", 0);

  }

  const updateChart = (chartType, timePeriod) => {
    // console.log('updateChart: ', (new Date()).getTime());

    setChartUpdating(true);
    d3.select(`#chartLoader_${props.cType}`).style('display', 'block');
    // console.log('updateChart after setChartUpdating: ', (new Date()).getTime());

    
    const faux = props.connectFauxDOM('div', 'chart')

    // const svgCanvas = d3.select(svgRef.current)
    const svgCanvas = d3.select(faux)
      .select('svg')
      .attr('width', canvasWidth + margin.left + margin.right)
      .attr('height', canvasHeight + margin.top + margin.bottom)


    // scaleValueYAxis.domain([0, d3.max(data, function(d) { return parseFloat(d[props.attributeName]); })]);
    scaleValueYAxis.domain([0, d3.max(data, function(d) { return Math.max(parseFloat(d[props.attributeName]), parseFloat(d[props.attribute2Name])); })]);
    scaleValueYAxis2.domain([0, d3.max(data, function(d) { return parseFloat(d[props.attribute2Name]); })]);

    scaleValueXAxis.domain(d3.range(0, data.length));

    scaleValueXAxisOrdinal.domain(data.map(el => moment(el.period_str, 'YYYY-MM').format('MMM YYYY')));

    // clear barChart abd barText
    svgCanvas
      .select('#barChart')
      .selectAll('rect')
      .remove();

    svgCanvas
      .select('#barText')
      .selectAll('text')
      .remove();

    const entryOffset = (canvasWidth - barWidth * data.length)/(data.length*2);

    
    // Remove Y axis and Y axis label
    svgCanvas
      .select('.y.axis')
      .remove();

    svgCanvas
      .select('#ylabel')
      .remove();

    
    if (chartType === 'ratings' || chartType === 'ratings-and-reviews') {
      // Add y axis right
      svgCanvas.append("g")
        .attr("class", "y axis")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        // .attr("transform", "translate("  + (canvasWidth + margin.left) + "," + margin.top + ")")
        .call(d3.axisLeft()
          .ticks(5)
          .scale(scaleValueYAxis));

      // Add y axis label
      svgCanvas.append("text")
        .attr("id", "ylabel")
        .attr("y",  30)
        .attr("x", margin.left - 40 )
        .text(props.yLabel)

    }

    let xAxisTransform = "translate("  + (-40) + "," + (50) + "), rotate(-60)";
    if (selectedTimePeriod === 'weekly') {
      xAxisTransform = "translate("  + (-80) + "," + (45) + "), rotate(-25)";
    }
    if (selectedTimePeriod === 'monthly') {
      xAxisTransform = "translate("  + (-30) + "," + (20) + "), rotate(-30)";
    }
    // Add X axis
    svgCanvas.select(".x.axis") // change the x axis
      // .duration(750)
      // .transition()
      // .duration(750)
      .attr("transform", "translate(" + margin.left + "," + (canvasHeight+margin.top) + ")")
      .call(d3.axisBottom()
              .scale(scaleValueXAxisOrdinal)
      )
      .selectAll("text")
      .attr("y", 10)
      .attr("x", 0)
      // .attr("transform", "rotate(-60)")
      .attr("transform", xAxisTransform)
      .style("text-anchor", "start")
      .style("font-size", "10px")

    
    // Remove line chart, dots and dots text
    svgCanvas
      .select('#lineChart')
      .selectAll('path')
      .remove();
    svgCanvas
      .select('#lineChart')
      .selectAll('path.line2')
      .remove();
    svgCanvas
      .select('#lineChart')
      .selectAll('.dot')
      .remove();
    svgCanvas
      .select('#lineChart')
      .selectAll('.dot2')
      .remove();
    svgCanvas
      .select('#lineChart')
      .selectAll('.dotText')
      .remove();
    svgCanvas
      .select('#lineChart')
      .selectAll('.dotText2')
      .remove();

    if (chartType === 'ratings' || chartType === 'ratings-and-reviews') {
      // Define the line
      const valueline = d3.line()
        .x(function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2; })
        .y(function(d, i) { return scaleValueYAxis(parseFloat(d[props.attributeName])) + margin.top });

      // Define the line
      const valueline2 = d3.line()
        .x(function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2; })
        .y(function(d, i) { return scaleValueYAxis(parseFloat(d[props.attribute2Name])) + margin.top });

      // Add the valueline path.


      svgCanvas
        .select('#lineChart')
        .append('path')
        .data([data])
        .attr("class", "line")
        .attr("d", valueline);

      svgCanvas
        .select('#lineChart')
        .append('path')
        .data([data])
        .attr("class", "line2")
        .attr("d", valueline2);

      var div = d3.select(".lineChartTooltip");

      // Appends a circle for each datapoint
      svgCanvas
        .select('#lineChart')
        .selectAll(".dot")
        .data(data).enter()
        .append("circle") // Uses the enter().append() method
        .attr("class", "dot") // Assign a class for styling
        // .attr("cx", function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + 15; })
        .attr("cx", function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2})
        .attr("cy", function(d) { return scaleValueYAxis(parseFloat(d[props.attributeName])) + margin.top + 5; })
        .attr("r", 8)
        .on("mouseover", (e, d, i) => {
          d3.select(this).transition()
            .duration('50')
            .attr('opacity', '.5')
          // .attr('class', 'splspl')
          div.transition()
            .duration(200)
            .style("opacity", .9);
          // console.log('mouseover e: ', e);
          // console.log('mouseover i: ', i);
          div.html(`${d[props.attributeName]}`) // average rating label
            .style("left", (e.pageX - 10) + "px")
            .style("top", (e.pageY - 60) + "px");
        })
        .on("mouseout", (d) => {
          d3.select(this).transition()
            .duration('50')
            .attr('opacity', '1')
          div.transition()
            .duration(500)
            .style("opacity", 0);
        });

      // add text on top of dot
      svgCanvas
        .select('#lineChart')
        .selectAll('text.dotText')
        .data(data).enter()
        .append('text')
        .attr('class', 'dotText')
        .attr('x', (d, i) => scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2 - 10)
        // .attr('x', (dataPoint, i) => i * 45 + 15)
        .attr('y', (d) => (scaleValueYAxis(d[props.attributeName]) + margin.top - 5))
        // .attr('y', (dataPoint, i) => canvasHeight - scaleValueYAxis(dataPoint.value) - 10)
        .text(dataPoint => parseFloat(dataPoint[props.attributeName]))


      // Second line
        // Appends a circle for each datapoint
      svgCanvas
      .select('#lineChart')
      .selectAll(".dot2")
      .data(data).enter()
      .append("circle") // Uses the enter().append() method
      .attr("class", "dot2") // Assign a class for styling
      // .attr("cx", function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + 15; })
      .attr("cx", function(d, i) { return scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2})
      .attr("cy", function(d) { return scaleValueYAxis(parseFloat(d[props.attribute2Name])) + margin.top + 5; })
      .attr("r", 8)
      .on("mouseover", (e, d, i) => {
        d3.select(this).transition()
          .duration('50')
          .attr('opacity', '.5')
        // .attr('class', 'splspl')
        div.transition()
          .duration(200)
          .style("opacity", .9);
        // console.log('mouseover e: ', e);
        // console.log('mouseover i: ', i);
        div.html(`${d[props.attribute2Name]}`) // average rating label
          .style("left", (e.pageX - 10) + "px")
          .style("top", (e.pageY - 30) + "px");
      })
      .on("mouseout", (d) => {
        d3.select(this).transition()
          .duration('50')
          .attr('opacity', '1')
        div.transition()
          .duration(500)
          .style("opacity", 0);
      });

    // add text on top of dot
    svgCanvas
      .select('#lineChart')
      .selectAll('text.dotText2')
      .data(data).enter()
      .append('text')
      .attr('class', 'dotText2')
      .attr('x', (d, i) => scaleValueXAxis(i) + parseInt(entryOffset) + margin.left + barWidth/2 - 10)
      // .attr('x', (dataPoint, i) => i * 45 + 15)
      .attr('y', (d) => (scaleValueYAxis(d[props.attribute2Name]) + margin.top + 25))
      // .attr('y', (dataPoint, i) => canvasHeight - scaleValueYAxis(dataPoint.value) - 10)
      .text(dataPoint => parseFloat(dataPoint[props.attribute2Name]))


    }
    // console.log('updateChart end: ', (new Date()).getTime());

    
    props.animateFauxDOM(800)

    d3.select(`#chartLoader_${props.cType}`).style('display', 'none');

    setTimeout(() => {
      setChartUpdating(false);
    }, 50);
  };


  /* ************ Loader **************** */

  function degToRad (degrees) {
    return degrees * Math.PI / 180;
  }

  // Returns a tween for a transition’s "d" attribute, transitioning any selected
  // arcs from their current angle to the specified new angle.
  function arcTween(newAngle, angle) {
    return function(d) {
      var interpolate = d3.interpolate(d[angle], newAngle);
      return function(t) {
        d[angle] = interpolate(t);
        return arc(d);
      };
    };
  }

  const animationTime = 1200;
  const loaderRadius = 40;
  const loaderColor = '#ccc';

  const arc = d3.arc()
      .innerRadius(0)
      .outerRadius(loaderRadius);

  const hideLoader = () => {
    d3.select("#loader_container").selectAll('g').remove();
  }
  const showLoader = () => {

    var svg = d3.select("#loader_container"),
        width = 900,
        height = 600,
        g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
      // console.log('loader_container svg: ', svg);
    var loader = g.append("path")
        .datum({endAngle: 0, startAngle: 0})
        .style("fill", loaderColor)
        .attr("d", arc);

    d3.interval(function() {
      loader.datum({endAngle: 0, startAngle: 0})

      loader.transition()
          .duration(animationTime)
          .attrTween("d", arcTween(degToRad(360), 'endAngle'));

       loader.transition()
          .delay(animationTime)
          .duration(animationTime)
          .attrTween("d", arcTween(degToRad(360), 'startAngle'));
    }, animationTime * 2);
  }

  
  const cStyle = {};
  cStyle['width'] = props.width;

  return (
    <div className={'barChartWrap ' + (props.className || '' )} style={cStyle}>
      {/*<svg id="loader_container" width="900" height="600"></svg>*/}
      <div id={`chartLoader_${props.cType}`} className="chartLoader">
        <i className="fa fa-spinner fa-spin" />
      </div>
      {props.chart}
      <div className="bottomRow">
        <div className="timePeriodBox">
        </div>
      </div>
    </div>
  )
}

export default withFauxDOM(LongTermMultiLineChart);
