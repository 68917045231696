// @flow

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import { Button, DatePicker } from 'antd';

import { editDataAction, editDataClearAction } from '../../../../../redux/actions/editDataActions';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import CustomTimePicker from '../../../../Misc/CustomTimePicker';

import { formatDate, numberOfNights, segmentTargetToLabel, getSegmentNumberOfNightsLabel,
  broadcastTargetDaysSinceCheckInToLabel, broadcastWeekDayToLabel, STRS } from '../../../../../helpers';

import closeIcon from '../../../../../img/icons/close.svg';
import requiredIcon from '../../../../../img/icons/required.svg';
import oneTimeIcon from '../../../../../img/icons/broadcast-onetime.svg';
import oneTimeActiveIcon from '../../../../../img/icons/broadcast-onetime-active.svg';
import recurringIcon from '../../../../../img/icons/broadcast-recurring.svg';
import recurringActiveIcon from '../../../../../img/icons/broadcast-recurring-active.svg';

import './style.css';


function SegmentData(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  const location = useLocation();


  const onCloseClick = (e) => {
    props.onCloseClick(e);
  };
  
  
  const renderContent = (segments, segmentInfo) => {
    return (
      <>
        <div className="sgmDataBx">
          <div className="dataRw hdr">
            <span className="dtNm">Name</span>
            <span className="dtEml">Email</span>
            <span className="dtChkIn">Check-In Date</span>
            <span className="dtChkOut">Check-Out Date</span>
            <span className="dtNights">Nights</span>
          </div>
          {
            segmentInfo && segmentInfo.data && segmentInfo.data.count && segmentInfo.data.list && segmentInfo.data.list.length > 0 &&
            segmentInfo.data.list.map((item) => {
              return (
                <div className="dataRw">
                  <span className="dtNm">{item.first_name} {item.last_name}</span>
                  <span className="dtEml">{item.email}</span>
                  <span className="dtChkIn">{formatDate(item.check_in_date)}</span>
                  <span className="dtChkOut">{formatDate(item.check_out_date)}</span>
                  <span className="dtNights">{numberOfNights(item.check_out_date, item.check_in_date)}</span>
                </div>
              )
            })
          }
        </div>
      </>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('SegmentData props: ', props);
  }

  const { segmentId, segments } = props;
  if (!segments) return null;

  const segmentInfo = segments && segments.keyValue && segmentId && segments.keyValue[segmentId];

  return (
    <div className="gPWrp">
      <div className="gPTle title">
        Segment Guests {props.isReadOnly && <> [{STRS.READ_ONLY_LABEL}]</>}
        <span className="close" onClick={onCloseClick}><img src={closeIcon} />Cancel</span>
      </div>
      {
        segmentInfo && segmentInfo.name &&
        <div className="gPSubTle title">
          {segmentInfo.name} ( {segmentInfo.data.count} guests )
        </div>
      }
      <div className="gBCtnt">
        { renderContent(segments, segmentInfo) }
      </div>
    </div>
  );
}

export default SegmentData;
