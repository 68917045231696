import { useSelector, useDispatch } from "react-redux";
import ReactDOM from 'react-dom';
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Interweave } from 'interweave';
import copy from 'copy-to-clipboard';
import linkifyStr from 'linkify-string';
import Picker from '@emoji-mart/react';
import emojiData from '@emoji-mart/data';
// import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import ToolTipWrapper from '../../../../../Misc/ToolTipWrapper';
import ImagePreview from '../../../../../Misc/ImagePreview';
import useOnClickOutside from '../../../../../Misc/OutsideClickHook';
import emData from '../../../../../../helpers/emoji.json'

import { editDataAction } from '../../../../../../redux/actions/editDataActions';
import { receiveSnack, removeSnack } from '../../../../../../redux/actions/snacksActions';

import { formatDateChange, formatMessageDate, isEmojiOnlyMessage, isBMImageUrl, LANGUAGE_LIST_KEYS } from '../../../../../../helpers';

import './style.css';

import whatsappIcon from '../../../../../../img/icons/whatsapp.png';
import expediaIcon from '../../../../../../img/icons/expedia.png';
import smsIcon from '../../../../../../img/icons/sms.svg';
import emailIcon from '../../../../../../img/icons/email.svg';
import googleIcon from '../../../../../../img/icons/google-logo.png';
import IconDelivered from '../../../../../../img/icons/iconDelivered.png';

const SHOW_MORE_LESS_THRESHOLD = 10;

const modalRoot = document.getElementById('modal-root');
function Modal(props) {
  const [el, setEl] = useState(document.createElement('div'));
  useEffect(() => {
    // this.el = document.createElement('div');
    // setEl(document.createElement('div'));
    modalRoot.appendChild(el);
    return function cleanup() {
      modalRoot.removeChild(el);
    }
  }, []);
  return ReactDOM.createPortal(
    props.children,
    el,
  );
}

function MessageReceived(props) {
  // const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  let urlParams = useParams();

  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const imagePreviewModalRef = useRef();

  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
  const emojiSelectorRef = useRef();
  useOnClickOutside(emojiSelectorRef, useCallback(() => setEmojiSelectorOpen(false)));


  useEffect(() => {
    let newlineCount = 0;
    if (props.message.medium === 'email') {
      if (props.message.msg) newlineCount = props.message.msg.split('\n').length;
      // if (!props.message.msg && props.message.msg_text_html) newlineCount = props.message.msg_text_html.split('\n').length;
    }
    if (newlineCount > SHOW_MORE_LESS_THRESHOLD) setIsMoreOpen(false);
    if (props.message.medium === 'email') {
      if (!props.message.msg && props.message.msg_text_html) setIsMoreOpen(true);
    }
  }, []);

  const onShowMoreClick = (e) => {
    setIsMoreOpen(true);
  };
  const onShowLessClick = (e) => {
    setIsMoreOpen(false);
  };

  const onTranslateMessageClick = (e) => {
    // props.dispatch(translateMessageAction({
    //   user: props.userprops.user,
    //   params: {
    //     message_id: props.message._id,
    //     guest_id: props.message.guest_id || undefined,
    //     outside_guest_id: props.message.outside_guest_id || undefined,
    //     guest_type: props.guestType,
    //     text: props.message.msg,
    //     to_lang: props.locationprops.langSettings.conversation_lang, // 'fr',
    //   },
    // }));
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Translate - Received Message`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };


  const onCopyToClipboard = (e) => {
    copy(props.message.msg);
    const id = new Date().getTime();
    dispatch(receiveSnack({
      snack: {
        id,
        msg: 'Copied.',
      },
    }));
    // remove the snack
    setTimeout(() => {
      dispatch(removeSnack({
        id,
      }));
    }, 2000);
    // gtag('event', 'gt_click', {
    //   'event_category': `Messaging - ${resultTypeToLabel[props.guestType] || props.guestType}`,
    //   'event_label': `Copy - Sent Message`,
    //   // 'value': 1,
    //   user_id: props.userprops.user.uid,
    // });
  };

  const onShowImagePreviewClick = (url) => {
    setImagePreviewUrl(url);
    setShowImagePreviewModal(true);
  };
  const onImagePreviewModalCloseClick = (e) => {
    setImagePreviewUrl('')
    setShowImagePreviewModal(false);
  };

  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    if (emoji) {
      let params = {
        location__id: urlParams.locationidentifier,
        location_id: props.message.location_id,
        guest_id: props.message.guest_id || undefined,
        outside_guest_id: props.message.outside_guest_id || undefined,
        type: props.guestType && props.guestType[props.guestType.length-1] === 's' ? props.guestType.substring(0, props.guestType.length-1) : props.guestType,
        message_id: props.message._id,
      };
      params.reaction = emoji;
      dispatch(editDataAction({
        op: 'send_message_reaction',
        params,
      }));
    }
  }
  const onReactionClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onReactionClick');
    }
    setEmojiSelectorOpen(true);
    // let params = {
    //   location__id: urlParams.locationidentifier,
    //   location_id: props.message.location_id,
    //   guest_id: props.message.guest_id || undefined,
    //   outside_guest_id: props.message.outside_guest_id || undefined,
    //   type: props.guestType && props.guestType[props.guestType.length-1] === 's' ? props.guestType.substring(0, props.guestType.length-1) : props.guestType,
    //   message_id: props.message._id,
    // };
    // params.reaction = '👍';
    // dispatch(editDataAction({
    //   op: 'send_message_reaction',
    //   params,
    // }));
  };


  const renderBMMessage = (message) => {
    if (message.msg && isBMImageUrl(message.msg)) {
      return <img src={message.msg} />;
    }
    return message.msg;
  };

  const renderImagePreviewModal = () => {
    return (
      <Modal>
        <div id="cModelId" className="cModal imgPrv">
          <div className="ctnt" ref={imagePreviewModalRef}>
            <ImagePreview
              image={imagePreviewUrl}
              onConfirmClick={onImagePreviewModalCloseClick}
              hideConfirm={{}}
              onCancelClick={onImagePreviewModalCloseClick}
              inProgress={false}
              uploadError={''}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderContent = () => {
    const { message, dateChange, dateChangePosition, isLast, avataarName } = props;
    if (!message) return null;

    const isEmojiOnly = message.emoji_only_processed ? message.emoji_only : isEmojiOnlyMessage(message.msg);
    let isMenuSingle = isEmojiOnly;
    // if (props.locationprops.langSettings.conversation_lang === message.lang) {
    //   isMenuSingle = true;
    // } // nkcheck
    const isImage = message.bm_message && isBMImageUrl(message.msg);
    const hasIncomingMedia = message.media && message.media.length;
    let newlineCount = 0;
    if (message.medium === 'email') {
      if (message.msg) newlineCount = message.msg.split('\n').length;
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('newlineCount: ', newlineCount);
    }

    let msgTextHtml = '';
    try {
      if (!message.msg && message.msg_text_html) {
        msgTextHtml = message.msg_text_html;
        if (msgTextHtml.indexOf('<body') > -1) {
          msgTextHtml = msgTextHtml.split('<body')[1];
          msgTextHtml = msgTextHtml.substring(msgTextHtml.indexOf('>')+1);
        }
        if (msgTextHtml.indexOf('</body>') > -1) {
          msgTextHtml = msgTextHtml.split('</body>')[0];
        }
      }
    } catch (e) {

    }

    const hasReaction = (message.reaction_sent && message.reaction_sent.emoji) || (message.reaction_received && message.reaction_received.emoji);

    return (
      <>
        {
          dateChange && dateChangePosition === 'bottom' &&
          <div className="dtCh">
            <div className="line" /><p>{formatDateChange(dateChange)}</p><div className="line" />
          </div>
        }
        <div className={'rcMB ' + (isLast ? 'latest' : '') + (isMenuSingle ? 'menuSingleOption ' : '')} key={message._id}>
          <div className="avtB">
            {avataarName || <i className="fa fa-user" /> }
          </div>
          <div className="msB">
            <div className={'msg' + (hasReaction ? ' reaction' : '')}>
              {
                message.context_message_id && message.context_message_data &&
                <div className="ctxMsg" onClick={() => props.onCtxMsgClick(message.context_message_id)}>
                  <p>{message.context_message_data.msg}</p>
                </div>
              }
              <div className="msgInner">
              <div className={'inner'}>
                {
                  message.medium === 'email'
                  ?
                    <p className={'orig email' + (isMoreOpen ? '' : ' showLess')} id={`msg__${message._id}`}>
                      {message.msg_subject && <span className="subject">Sub: {message.msg_subject}</span> }
                      {
                        message.msg &&
                        <Interweave content={linkifyStr(message.msg, { nl2br: true })} />
                      }
                      {
                        !message.msg && message.msg_text_html && msgTextHtml &&
                        <Interweave content={msgTextHtml} />
                      }
                    </p>
                  :
                    <p className="orig" id={`msg__${message._id}`}>
                      {
                        message.bm_message
                        ? renderBMMessage(message)
                        : message.msg && <Interweave content={message.msg} />
                      }
                    </p>
                }
                {/*
                  message.lang !== 'en' &&
                  <>
                    <p className="by">Translated by Google to English</p>
                    <p className="translated" id={`msg__tr_${message._id}`}>{message.msg__en}</p>
                  </>
                */}
                {
                  Object.keys(message).map((el) => {
                    if (el.indexOf('msg__') === 0) {
                      let lang = el.split('msg__')[1];
                      return (
                        <>
                          <p className="by">Translated by Google from {LANGUAGE_LIST_KEYS[message.lang]} to {LANGUAGE_LIST_KEYS[lang]} </p>
                          <p className="translated" id={`msg__tr_${message._id}`}>{message[el]}</p>
                        </>
                      )
                    }
                    return null;
                  })
                }
                {
                  message.media && Array.isArray(message.media) && message.media.length
                  ?
                    message.media.map((m, idx) => {
                      if (!m.content_type || m.content_type.indexOf('image') > -1) {
                        return (
                          <div className="imgEntry">
                            <img key={`e_ms_img_${idx}`} className="incomingMedia" src={m.url} onClick={() => onShowImagePreviewClick(m.url)} />
                            {
                              m.caption ? <p>{m.caption}</p> : null
                            }
                          </div>
                        );
                      }
                      if (m.content_type.indexOf('video') > -1) {
                        return (
                          <div className="imgEntry">
                            <video width="320" height="240" controls key={`e_ms_img_${idx}`} className="incomingMedia">
                              <source src={m.url} type={m.content_type} />
                            </video>
                            {
                              m.caption ? <p>{m.caption}</p> : null
                            }
                          </div>
                        );
                      }
                      if (m.content_type.indexOf('audio') > -1) {
                        return (
                          <div className="imgEntry">
                            <audio controls key={`e_ms_img_${idx}`} className="incomingMedia">
                              <source src={m.url} type={m.content_type} />
                            </audio>
                            {
                              m.caption ? <p>{m.caption}</p> : null
                            }
                          </div>
                        );
                      }
                      if (m.content_type.indexOf('application') > -1) {
                        return (
                          <div className="imgEntry">
                            <a href={m.url} target="_blank">{m.filename}</a>
                            {
                              m.caption ? <p>{m.caption}</p> : null
                            }
                          </div>
                        );
                      }
                      if (m.content_type.indexOf('location') > -1) {
                        return (
                          <div className="imgEntry">
                            Location
                            <a href={`https://maps.google.com/?q=${m.latitude},${m.longitude}`} target="_blank">{m.latitude}, {m.longitude}</a>
                            {
                              m.name ? <p>{m.name}</p> : null
                            }
                          </div>
                        );
                      }
                      
                      return null;
                    })
                  : null
                }
                {/*
                  message.media && message.media.length
                  ?
                    message.media.map((m) => {
                      return (
                        <SimpleReactLightbox>
                        <SRLWrapper
                          options={{
                            thumbnails: {showThumbnails:false},
                            buttons: {
                              showAutoplayButton: false,
                              showDownloadButton: false,
                              showFullscreenButton: false,
                              showThumbnailsButton: false,
                              showNextButton: false,
                              showPrevButton: false,
                            },
                          }}
                        >
                          <img className="incomingMedia" src={m.url} />
                        </SRLWrapper>
                        </SimpleReactLightbox>
                      );
                    })
                  : null
                */}
                {
                  newlineCount > SHOW_MORE_LESS_THRESHOLD &&
                  <>
                    {
                      !isMoreOpen
                      ? <p className="showMoreLess" onClick={onShowMoreClick}>Show More</p>
                      : <p className="showMoreLess" onClick={onShowLessClick}>Show Less</p>
                    }
                  </>
                }
              </div>
              
              {
                (!isImage && !hasIncomingMedia) &&
                <div className="dd">
                  <button className="dbtn"><i className="fa fa-caret-down" /></button>
                  <div className="ddc">
                    {/*
                      !isEmojiOnly && props.locationprops.langSettings.conversation_lang !== message.lang &&
                      !message[`msg__${props.locationprops.langSettings.conversation_lang}`] &&
                      <p onClick={onTranslateMessageClick}>Translate to {LANGUAGE_LIST_KEYS[props.locationprops.langSettings.conversation_lang]}</p>
                    // nkcheck */}
                    <p onClick={onCopyToClipboard}>Copy</p>
                  </div>
                </div>
              }
              </div>
              {
                ((message.reaction_sent && message.reaction_sent.emoji) || (message.reaction_received && message.reaction_received.emoji)) &&
                  <div className="reaction">
                    {
                      message.reaction_sent && message.reaction_sent.emoji && <span>{message.reaction_sent.emoji}</span>
                    }
                    {
                      message.reaction_received && message.reaction_received.emoji && <span>{message.reaction_received.emoji}</span>
                    }
                  </div>
              }
              
              {
                message.medium === 'whatsapp' &&
                <div className={'ddemoji ' + (emojiSelectorOpen ? 'open' : '')} ref={emojiSelectorRef}>
                  <div className="nwreaction" onClick={onReactionClick}><i className="fa-solid fa-face-smile"></i>
                  <div className="ddc">
                  {
                    emojiSelectorOpen &&
                    <div className="emRw">
                      <div className={'emRw-item' + (message.reaction_sent && message.reaction_sent.emoji === '👍' ? ' active' : '')} onClick={() => onEmojiClick('👍')}>👍</div>
                      <div className={'emRw-item' + (message.reaction_sent && message.reaction_sent.emoji === '😀' ? ' active' : '')} onClick={() => onEmojiClick('😀')}>😀</div>
                      <div className={'emRw-item' + (message.reaction_sent && message.reaction_sent.emoji === '🙏' ? ' active' : '')} onClick={() => onEmojiClick('🙏')}>🙏</div>
                      <div className={'emRw-item' + (message.reaction_sent && message.reaction_sent.emoji === '❤️' ? ' active' : '')} onClick={() => onEmojiClick('❤️')}>❤️</div>
                      <div className={'emRw-item' + (message.reaction_sent && message.reaction_sent.emoji === '🙌' ? ' active' : '')} onClick={() => onEmojiClick('🙌')}>🙌</div>
                      </div>
                    // <div className="emojiBox">
                    //   <Picker data={emData}
                    //     categories={['frequent', 'people']}
                    //     maxFrequentRows={1}
                    //     perLine={6}
                    //     onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
                    //   />
                    // </div>
                  }
                  </div>
                  </div>
                </div>
              }
            </div>
            <div className="mstB">
              <div className="dateMessage">
                {formatMessageDate(message.date_created)}
                {
                  message.medium && message.medium === 'whatsapp' && <img src={whatsappIcon} title="WhatsApp" />
                }
                {
                  message.medium && message.medium === 'expedia' && <img src={expediaIcon} title="Expedia" />
                }
                {
                  message.medium && message.medium === 'email' && <span><i className="fa-solid fa-envelope"></i></span>
                }
                {/*
                  message.medium && message.medium === 'bm' && <img src={googleIcon} className="gbm" title="Google Business Message" />
                */}
                {
                  !message.medium && !message.from_webapp && <img src={smsIcon} title="SMS" />
                }
                {
                  message.from_webapp &&

                    <div className="mdm"><i className="fa-solid fa-mobile-screen-button"></i><span>Digital Guide</span></div>
                }
              </div>
            </div>
          </div>
          <div className="leftOverBox">
          </div>
        </div>
        {
          props.dateChange && props.dateChangePosition === 'top' &&
          <div className="dtCh">
            <div className="line" /><p>{formatDateChange(props.dateChange)}</p><div className="line" />
          </div>
        }
        { showImagePreviewModal && renderImagePreviewModal() }
      </>
    );
  };

  return renderContent();
}

export default MessageReceived;
